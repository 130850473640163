import {useState, useEffect, Fragment} from 'react';
import css from './Logbook.scss';
import cn from 'classnames';
import {mealCodes, sourceOptions} from './BgConstants';
import {bgFilterOptions} from './BGFilterOptions';
import Button from '@livongo/ui/Button';
import {useDispatch, useSelector} from 'react-redux';
import Select from '@livongo/ui/Select';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import useDays from '@livongo/hooks/useDays';

const BGHeader = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const dispatch = useDispatch();
    const filters = useSelector(state => state.logBookFilters);
    const [filter, setFilter] = useState({...filters});

    useEffect(() => {
        setFilter(filters);
    }, [filters]);

    const GetWeekLabels = () => {
        const week = [];

        const {allDays} = useDays({
            width: 'abbreviated',
        });

        for (let i = 0; i < 7; i++) {
            week.push(
                <Fragment key={`week-days${i}`}>
                    <label>
                        <input
                            id={`week-days${i}`}
                            type="checkbox"
                            value={`${i}`}
                            checked={filter.weekDay[i]}
                            onChange={() =>
                                setFilter(prevState => {
                                    const copyWeek = {...prevState.weekDay};

                                    copyWeek[i] = !copyWeek[i];

                                    return {...prevState, weekDay: copyWeek};
                                })
                            }
                        />
                        <span>{allDays[i]}&nbsp;</span>
                    </label>
                </Fragment>
            );
        }

        return week;
    };

    const exportData = () => {
        window.print();

        return false;
    };

    return (
        <div className="horizontal-scroll panel filter-panel">
            <div className="panel-heading">
                <h3 className="panel-title">{t('filters.text')}</h3>
            </div>

            <div className="panel-body">
                <div className="row filter-row">
                    <div className="col-xs-12 col-md-3">
                        <Select
                            label={<label>{t('filters.mealTags.label')}</label>}
                            items={[
                                {label: t('filters.default'), value: ''},
                                ...mealCodes.map(meal => {
                                    return {
                                        label: t(`mealCodes.${meal.dataKey}`),
                                        value: meal.name,
                                    };
                                }),
                            ]}
                            itemClassName={css.select_input}
                            onChange={e =>
                                setFilter(prevState => {
                                    return {
                                        ...prevState,
                                        mealCode: mealCodes.filter(
                                            currentMeal =>
                                                currentMeal.name === e.value
                                        )[0],
                                    };
                                })
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-2">
                        <Select
                            label={<label>{t('filters.bgRange.label')}</label>}
                            items={[
                                {label: t('filters.default'), value: ''},
                                ...bgFilterOptions.map(filterOption => {
                                    return {
                                        label: t(filterOption.label),
                                        value: filterOption.label,
                                    };
                                }),
                            ]}
                            itemClassName={css.select_input}
                            onChange={e =>
                                setFilter(prevState => {
                                    return {
                                        ...prevState,
                                        bgValue: bgFilterOptions.filter(
                                            range => range.label === e.value
                                        )[0],
                                    };
                                })
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-2">
                        <Select
                            label={
                                <label>
                                    {t('filters.sourceOptions.label')}
                                </label>
                            }
                            items={[
                                {label: t('filters.default'), value: ''},
                                ...sourceOptions.map(source => {
                                    return {
                                        label: source.name,
                                        value: source.dataKey,
                                    };
                                }),
                            ]}
                            itemClassName={css.select_input}
                            onChange={e => {
                                setFilter(prevState => {
                                    return {
                                        ...prevState,
                                        source: sourceOptions.filter(
                                            sourceItem =>
                                                sourceItem.dataKey === e.value
                                        )[0],
                                    };
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-5">
                        <div className="form-group">
                            <label>{t('filters.daysOfWeek.label')}</label>
                            <br />
                            <div className={css['week-days']}>
                                {GetWeekLabels()}
                            </div>
                        </div>

                        <div className="form-group">
                            <label>{t('filters.criteria.label')}</label>
                            <br />
                            <div className={css['other-filters']}>
                                <label>
                                    <input
                                        id="inludeOOR"
                                        type="checkbox"
                                        value="0"
                                        defaultChecked={filters.inludeOOR}
                                        onChange={() =>
                                            setFilter(prevState => {
                                                return {
                                                    ...prevState,
                                                    inludeOOR: !prevState.inludeOOR,
                                                };
                                            })
                                        }
                                    />
                                    <span>
                                        {t('filters.criteria.outsideRange')}
                                    </span>
                                </label>
                                <label>
                                    <input
                                        id="includeManual"
                                        type="checkbox"
                                        value="0"
                                        defaultChecked={filters.includeManual}
                                        onChange={() =>
                                            setFilter(prevState => {
                                                return {
                                                    ...prevState,
                                                    includeManual: !prevState.includeManual,
                                                };
                                            })
                                        }
                                    />
                                    <span>
                                        {t('filters.criteria.selfReportedBG')}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-2">
                        <div className="form-group">
                            <Select
                                defaultValue="30"
                                label={<label>{t('filters.days.label')}</label>}
                                items={['7', '14', '30', '90'].map(day => {
                                    return {
                                        label: day,
                                        value: day,
                                    };
                                })}
                                itemClassName={css.select_input}
                                onChange={e => {
                                    setFilter(prevState => {
                                        return {
                                            ...prevState,
                                            days: parseInt(e.value, 10),
                                        };
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-5 col-xs-12">
                        <div className="row">
                            <div className="col-xs-6">
                                <label>&nbsp;</label>
                                <Button
                                    color="primary"
                                    className={css.button}
                                    onClick={() =>
                                        dispatch({
                                            type: 'FILTER_CHANGE',
                                            payload: filter,
                                        })
                                    }
                                >
                                    {t('filters.buttons.search')}
                                </Button>
                            </div>
                            <div className="col-xs-6">
                                <label>&nbsp;</label>
                                <Button
                                    color="primary"
                                    className={css.button}
                                    onClick={() =>
                                        exportData(
                                            'meal-tag-view meal-time-view meal-list-view'
                                        )
                                    }
                                >
                                    {t('filters.buttons.export')}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div
                            className={cn(
                                'col-md-6',
                                'col-offset-md-6',
                                'col-xs-12',
                                css['legend-content']
                            )}
                        >
                            {t('legend.label')}:
                            <span>
                                {' '}
                                <span
                                    className={cn(
                                        css['in-range-legend'],
                                        css['legend-bg']
                                    )}
                                >
                                    &nbsp;
                                </span>{' '}
                                {t('legend.inRange')}{' '}
                            </span>
                            <span>
                                {' '}
                                <span
                                    className={cn(
                                        css['below-range-legend'],
                                        css['legend-bg']
                                    )}
                                >
                                    &nbsp;
                                </span>{' '}
                                {t('legend.belowRange')}{' '}
                            </span>
                            <span>
                                {' '}
                                <span
                                    className={cn(
                                        css['above-range-legend'],
                                        css['legend-bg']
                                    )}
                                >
                                    &nbsp;
                                </span>{' '}
                                {t('legend.aboveRange')}{' '}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BGHeader;
