import {
    useState,
    useCallback,
    // useEffect
} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@livongo/ui/Modal';
import AdvancedSelect from '@livongo/ui/AdvancedSelect';
import Button from '@livongo/ui/Button';
import DatePicker from '@livongo/ui/DatePicker';
import Select from '@livongo/ui/Select';
import Form from '@livongo/ui/Form';
import TextInput from '@livongo/ui/TextInput';
import cn from 'classnames';
import Arg from '@livongo/arg';
import MedicationResource from '../../resources/res_medication';
import css from './modal.scss';
import dateCss from '../medication-reconciliation.scss';
import format from 'date-fns/format';
import setHours from 'date-fns/setHours';
import getHours from 'date-fns/getHours';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const AddMedicationModal = ({show, setShow, getMedication, reviewId}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const defaultState = {
        dose: '',
        directions: '',
        routeCode: '',
        reportedAdherenceId: '',
    };
    const [suggestions, setSuggestions] = useState([]);
    const [startDate, setStartDate] = useState();
    const [selectedDrug, setSelectedDrug] = useState({...defaultState});
    const medicationOptions = useSelector(state => state.medicationOptions);
    const medicationReview = useSelector(state => state.medicationReview);
    const dispatch = useDispatch();

    const onValueChange = useCallback(value => {
        const inputValue = value.trim().toLowerCase();

        // Medication's names are at least 3 characters long, avoid unneeded api calls
        if (inputValue.length >= 3) {
            MedicationResource.getDrugNames(inputValue).then(data => {
                let foundDrugs = data.data;

                foundDrugs = foundDrugs.map((suggestion, index) => {
                    return {
                        label: `${suggestion.drugName} ${suggestion.gpiDrugName}`,
                        value: suggestion.id,
                    };
                });
                setSuggestions(foundDrugs);
            });
        } else {
            setSuggestions([]);
        }
    }, []);

    const changeDrugInput = e => {
        setSelectedDrug(prevState => {
            return {...prevState, [e.name]: e.value};
        });
    };

    const onClear = () => {
        setSuggestions([]);
        // only keep dose, directions, and reportedAdherenceId
        setSelectedDrug(prevState => {
            const {dose, directions, reportedAdherenceId} = {...prevState};

            return {dose, directions, reportedAdherenceId};
        });
    };

    const onSuggestSelect = suggestion => {
        MedicationResource.getDrugInfo(suggestion).then(data =>
            setSelectedDrug(prevState => {
                return {
                    ...prevState,
                    ...data.data,
                    routeCode: data.data.routeCodes[0],
                };
            })
        );
        setSuggestions([]);
    };

    // add aditional properties to active drug object
    const getAllDrugInfo = drug => {
        const activeDrug = {...drug};

        activeDrug.isNew = true;
        activeDrug.startDate = startDate
            ? format(
                  setHours(new Date(startDate), getHours(new Date())),
                  "yyyy-MM-dd'T'hh:mm:ss"
              )
            : null;
        activeDrug.reportedAdherenceId = activeDrug.reportedAdherenceId
            ? parseInt(activeDrug.reportedAdherenceId, 10)
            : '';
        activeDrug.gpiDrugName = activeDrug.gpi12Name
            ? activeDrug.gpi12Name
            : '';
        activeDrug.sourceId = 1;
        const classId = medicationOptions.medClassOptions.filter(medClass => {
            return activeDrug.medClass.indexOf(medClass.medClassCode) >= 0;
        });

        // activeDrug.medClassId = classId.length
        //     ? parseInt(classId[0].medClassId, 10)
        //     : '';
        activeDrug.medClassId = classId.map(id => parseInt(id.medClassId, 10));
        activeDrug.medSourceId = 1;

        for (const key in medicationOptions.medRoutes) {
            if (medicationOptions.medRoutes[key] === activeDrug.routeCode) {
                activeDrug.medRouteId = parseInt(key, 10);
            }
        }

        return activeDrug;
    };

    const resetState = () => {
        setSelectedDrug({...defaultState});
        setSuggestions([]);
        setShow(false);
    };

    const addActiveDrug = () => {
        for (const drug in selectedDrug) {
            if (!selectedDrug[drug]) return;
        }
        if (!startDate) return;

        const activeDrug = getAllDrugInfo(selectedDrug);
        const userToken = Arg('authToken');

        // Setting the active drug into an active drug with POST request
        MedicationResource.makeMedicationActive(
            medicationReview.reviewId,
            userToken,
            activeDrug
        )
            .then(() => {
                // Getting the latest list of medications with newly added active medication
                getMedication('', reviewId);
                dispatch({
                    type: 'REVIEWED_TODAY_DISABLE',
                    payload: false,
                });
                resetState();
            })
            .catch(err => {
                console.log('ERROR: ', err); // eslint-disable-line no-console
            });
    };

    return show ? (
        <Modal
            containerClassName={css.long}
            title="Add Medication"
            onHidden={resetState}
        >
            <div
                className={cn('modal-header', css['no-padding'])}
                style={{padding: 0}}
            />
            <Form>
                <div
                    className={cn('modal-body', css['no-padding'])}
                    style={{padding: 0}}
                >
                    <div className="form-group">
                        <div
                            className={cn(css['form-modal-item'])}
                            style={{padding: '0 7.5px', paddingTop: '5px'}}
                        >
                            <AdvancedSelect
                                input={{
                                    placeholder: t(
                                        'reconciliation.newMedicationModal.drugName'
                                    ),
                                    label: <label>{t('headers.name')}:</label>,
                                    ariaLabel: 'Select a drug name',
                                    required: true,
                                }}
                                onClear={onClear}
                                onItemSelect={onSuggestSelect}
                                onValueChange={onValueChange}
                                items={suggestions}
                                classNames={{root: css['no-padding']}}
                                inputItemClassName={css['no-padding']}
                            />
                        </div>

                        <div
                            className={cn(
                                css['form-modal-item'],
                                !selectedDrug.medClass
                                    ? css['hide-medclass']
                                    : ''
                            )}
                        >
                            <label>
                                {t('reconciliation.newMedicationModal.class')}:{' '}
                            </label>
                            &nbsp;
                            <span>
                                {selectedDrug.medClass &&
                                    selectedDrug.medClass.join(',')}
                            </span>
                        </div>

                        <div
                            className={cn(
                                css.rowInputs,
                                css['form-modal-item']
                            )}
                        >
                            <div className={css.rowInput}>
                                <TextInput
                                    label={<label>{t('headers.dose')}:</label>}
                                    className={dateCss.noMargin}
                                    itemClassName={dateCss.select_input}
                                    placeholder={t('headers.dose')}
                                    required
                                    onChange={e =>
                                        changeDrugInput({
                                            name: 'dose',
                                            value: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className={css.rowInput}>
                                <Select
                                    label={
                                        <label>{t('headers.routes')}:</label>
                                    }
                                    className={dateCss.noMargin}
                                    itemClassName={dateCss.select_input}
                                    id="route_select"
                                    required
                                    onChange={e =>
                                        changeDrugInput({
                                            name: 'routeCode',
                                            value: e.target.value,
                                        })
                                    }
                                    defaultValue={
                                        selectedDrug.routeCodes &&
                                        selectedDrug.routeCodes[0]
                                            ? selectedDrug.routeCodes[0]
                                            : null
                                    }
                                    items={
                                        selectedDrug.routeCodes
                                            ? selectedDrug.routeCodes.map(
                                                  route => {
                                                      return {
                                                          label: route,
                                                          value: route,
                                                      };
                                                  }
                                              )
                                            : []
                                    }
                                />
                            </div>
                        </div>

                        <div className={css['form-modal-item']}>
                            <TextInput
                                label={
                                    <label>{t('headers.directions')}:</label>
                                }
                                className={dateCss.noMargin}
                                itemClassName={dateCss.select_input}
                                placeholder={t('headers.directions')}
                                required
                                onChange={e =>
                                    changeDrugInput({
                                        name: 'directions',
                                        value: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div
                            className={`${css.rowInputs} ${css['form-modal-item']}`}
                        >
                            <div className={cn(css.rowInput, css['no-height'])}>
                                <div style={{position: 'relative', top: '5px'}}>
                                    <DatePicker
                                        id="datePickerId"
                                        className={dateCss.datePicker}
                                        inputItemClassName={dateCss.datePadding}
                                        required
                                        label={
                                            <label>
                                                {t('headers.startDate')}
                                            </label>
                                        }
                                        placeholder={t('headers.startDate')}
                                        ariaLabel="The date"
                                        navigationType="dropdown"
                                        disablePast={false}
                                        onChange={date => setStartDate(date)}
                                    />
                                </div>
                            </div>
                            <div className={cn(css.rowInput)}>
                                <Select
                                    label={
                                        <label>{t('headers.howOften')}</label>
                                    }
                                    className={dateCss.noMargin}
                                    itemClassName={dateCss.select_input}
                                    name="oftenItems"
                                    id="often"
                                    items={
                                        medicationOptions.reportedAdherenceOptions
                                            ? medicationOptions.reportedAdherenceOptions.map(
                                                  option => {
                                                      return {
                                                          label:
                                                              option.reportedAdherenceName,
                                                          value: `${option.reportedAdherenceId}`,
                                                      };
                                                  }
                                              )
                                            : []
                                    }
                                    onChange={e =>
                                        changeDrugInput({
                                            name: 'reportedAdherenceId',
                                            value: parseInt(e.value, 10),
                                        })
                                    }
                                    placeholder={t('headers.howOften')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        type="button"
                        className={css.closeButton}
                        onClick={() => resetState()}
                    >
                        {t('reconciliation.newMedicationModal.close')}
                    </Button>
                    <Button
                        type="select"
                        className={css.activeButton}
                        onClick={addActiveDrug}
                    >
                        {t('reconciliation.newMedicationModal.active')}
                    </Button>
                </div>
            </Form>
        </Modal>
    ) : (
        ''
    );
};

AddMedicationModal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    getMedication: PropTypes.func,
    reviewId: PropTypes.any,
};

export default AddMedicationModal;
