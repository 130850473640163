import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'scrollingelement';
import 'transitionEnd';
import 'index.scss';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import Loader from '@livongo/ui/Loader';
import APIUtils from '@livongo/utils/api';
import createStore from 'store';
import Root from 'app/Root';
import Arg from '@livongo/arg';
import SentryUtils from '@livongo/utils/sentry';
import i18n from 'i18n';

const DEBUG = process.env.DEBUG === 'true';
const isDev = process.env.NODE_ENV === 'development';

document.cookie = `access_token=${Arg(
    'authToken'
)};Domain=livongo.com;Secure;SameSite=None`;

if (isDev) {
    const axe = require('react-axe');
    const ReactDOM = require('react-dom');

    axe(React, ReactDOM, 1000);
}

render(<Loader />, document.querySelector('.root'));

APIUtils.init({
    baseUrl: process.env.API_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Arg('authToken')}`,
    },
});

SentryUtils.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
});

function renderRoot(initialState = {}) {
    render(
        <Provider store={createStore(initialState)}>
            <Root />
        </Provider>,
        document.querySelector('.root')
    );
}

let lang = Arg('locale');

if (lang !== 'en-US') {
    // default to english
    lang = 'en';
}

i18n({isDev, debug: DEBUG, lang});

renderRoot();
