export default function weightDataReducer(state = {}, {type, payload}) {
    switch (type) {
        case 'WEIGHT_DATA': {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
}
