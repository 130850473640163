/* eslint-disable camelcase */
import {useState, useEffect} from 'react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import css from './medication-adherence.scss';
import SFResource from './SFResource';
import {MedService} from '../coaching/MedService';
import MedicationAdherenceTable from './MedicationAdherenceTable';
import MedicationAdherenceModal from './MedicationAdherenceModal';
import {useSelector, useDispatch} from 'react-redux';
import Arg from '@livongo/arg';
import cn from 'classnames';
import MedicationAdherenceHeader from './MedicationAdherenceHeader';
import medicationAdherenceData from './reducers/medication-adherence-reducer';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const MedicationAdherence = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const dispatch = useDispatch();
    const medAdherenceData = useSelector(
        state => state.medicationAdherenceData
    );
    const [adherenceModal, setAdherenceModal] = useState(false);
    const [openPanel, setOpenPanel] = useState(true);

    const getUserToken = () => {
        const {authToken: token, sessionId} = Arg.all();

        dispatch({
            type: 'AUTH',
            payload: {
                token,
                sessionId,
            },
        });
        adherenceSummary(token);
        // get current coach login info
        getAdherenceCoachId(sessionId);
    };

    function getAdherenceCoachId(sessionId) {
        SFResource.getCoachId(sessionId)
            .then(response => {
                dispatch({
                    type: 'ADHERENCE_SUMMARY',
                    payload: {
                        currentCoachLogin: `${get(
                            response.data,
                            'FirstName'
                        )} ${get(response.data, 'LastName')}`,
                    },
                });
            })
            .catch(error => {
                dispatch({
                    type: 'ERROR',
                    payload:
                        'Coach login is not available. Please sign in again.',
                });
            });
    }

    function adherenceSummary(userToken) {
        MedService.getAdherenceSummary(userToken)
            .then(list => {
                const adherencePassReviewList = list[0].pastReviewsList;
                const listUpdate = {
                    adherenceSummary: list[0],
                    adherencePassReviewList,
                    lastReview:
                        adherencePassReviewList.length > 0
                            ? adherencePassReviewList[0]
                            : null,
                    nonAdherenceFlag: list[0].nonAdherenceFlag,
                    barriers: list[0].barriers,
                    dsContext: list[0].dsContext,
                    isLastReviewEditable: list[0].isLastReviewEditable,
                    availableBarriersList: list[0].availableBarriersList,
                };

                dispatch({
                    type: 'ADHERENCE_SUMMARY',
                    payload: listUpdate,
                });
            })
            .catch(err => {
                console.log(err); // eslint-disable-line no-console
                dispatch({
                    type: 'ERROR',
                    payload: 'Session is expired. Please refresh the browser.',
                });
            });
    }

    const createAdherenceReview = newAdherenceCopy => {
        getUserToken();
        // const newAdherenceCopy = {...newAdherence};
        // newAdherenceCopy.barriers_list = medAdherenceData.checkedBerriers;
        // newAdherenceCopy.nonAdherenceFlag =
        //     medAdherenceData.nonAdherenceFlag.toString() === 'true';
        newAdherenceCopy.coach_login = medAdherenceData.currentCoachLogin;
        dispatch({
            type: 'NEW_ADHERENCE',
            payload: newAdherenceCopy,
        });

        MedService.createAdherenceReview(
            medAdherenceData.auth.token,
            newAdherenceCopy
        )
            .then(
                resp => {
                    if (!isUndefined(resp[0].reviewId)) {
                        dispatch({
                            type: 'ADHERENCE_SUMMARY',
                            payload: {
                                reviewId: resp[0].reviewId,
                                checkedBerriers: [],
                                newAdherence: {
                                    coach_login: '',
                                    nonAdherenceFlag: false,
                                    barriers_list: [],
                                    coach_notes: '',
                                },
                            },
                        });
                        adherenceSummary(medAdherenceData.auth.token);
                    }
                    setAdherenceModal(false);
                },
                err => {
                    console.log(err); // eslint-disable-line no-console
                    dispatch({
                        type: 'ERROR',
                        payload:
                            err.message.indexOf('coach_login') === -1
                                ? 'Session is expired. Please refresh the browser.'
                                : err.message,
                    });
                }
            )
            .catch(err => console.log('error creating adherence review', err)); // eslint-disable-line no-console
    };

    const updateAdherenceReview = newAdherenceCopy => {
        console.log('updated from modal', newAdherenceCopy); // eslint-disable-line no-console
        newAdherenceCopy.coach_login = medAdherenceData.currentCoachLogin;
        if (
            newAdherenceCopy.nonAdherenceFlag === 'true' ||
            newAdherenceCopy.nonAdherenceFlag === true
        )
            newAdherenceCopy.nonAdherenceFlag = true;
        else newAdherenceCopy.nonAdherenceFlag = false;

        MedService.updateAdherenceReview(
            medAdherenceData.auth.token,
            newAdherenceCopy
        )
            .then(
                resp => {
                    if (!isUndefined(resp[0].reviewId)) {
                        dispatch({
                            type: 'ADHERENCE_SUMMARY',
                            payload: {
                                reviewId: resp[0].reviewId,
                                checkedBerriers: [],
                                newAdherence: {
                                    coach_login: '',
                                    nonAdherenceFlag: false,
                                    barriers_list: [],
                                    coach_notes: '',
                                },
                            },
                        });
                        adherenceSummary(medAdherenceData.auth.token);
                    }
                    setAdherenceModal(false);
                },
                err => {
                    console.log(err); // eslint-disable-line no-console
                    // $scope.addAdberenceReview.$setPristine();
                    // $scope.errorMessage = err.message.indexOf("coach_login") === -1 ? "Session is expired. Please refresh the browser." : err.message;
                    // console.log(err); // eslint-disable-line no-console
                    // $scope.noNewAdherenceAdded = true;
                    // $('#addAdberenceReview').trigger("reset");
                }
            )
            .catch(err => console.log('error updating adherence review', err)); // eslint-disable-line no-console
    };

    function parseBarriers(barriersList) {
        const list = [];
        let barrierArr = [];

        if (barriersList.includes(',')) barrierArr = barriersList.split(',');
        else barrierArr.push(barriersList);
        if (barrierArr.length > 0) {
            barrierArr.forEach(item => {
                medAdherenceData.barriersMapping.forEach(barrier => {
                    if (
                        item.indexOf(barrier.name) !== -1 &&
                        list.indexOf(barrier.id) === -1
                    ) {
                        list.push(barrier.id);
                    }
                });
            });
        }

        return list;
    }

    const openAddAdherence = () => {
        getUserToken();
        let checkedBerriers = [];
        let updatedNewAdherence = {...medicationAdherenceData.newAdherence};

        updatedNewAdherence.coach_login = medAdherenceData.currentCoachLogin;
        setAdherenceModal(true);
        if (medAdherenceData.isLastReviewEditable) {
            updatedNewAdherence = medAdherenceData.lastReview;
            if (updatedNewAdherence.barriers_list.length > 0) {
                checkedBerriers = updatedNewAdherence.barriers_list.filter(
                    (item, pos) => {
                        return (
                            updatedNewAdherence.barriers_list.indexOf(item) ===
                            pos
                        );
                    }
                );
                // dispatch({
                //     type: 'CHECKED_BERRIERS',
                //     payload: updatedCheckedBerriers,
                // });
            }
        } else {
            updatedNewAdherence.coach_login =
                medAdherenceData.currentCoachLogin;
            updatedNewAdherence.coach_notes = isEmpty(
                medAdherenceData.adherencePassReviewList[0]
            )
                ? ''
                : medAdherenceData.adherencePassReviewList[0].coach_notes;
            updatedNewAdherence.nonAdherenceFlag =
                medAdherenceData.nonAdherenceFlag;
            updatedNewAdherence.barriers_list = parseBarriers(
                medAdherenceData.adherenceSummary.barriers
            );
            if (medAdherenceData.barriers.length > 0)
                checkedBerriers = parseBarriers(
                    medAdherenceData.adherenceSummary.barriers
                );
        }
        console.log('updated new adherence:', updatedNewAdherence); // eslint-disable-line no-console
        dispatch({
            type: 'ADHERENCE_SUMMARY',
            payload: {
                checkedBerriers,
                newAdherence: updatedNewAdherence,
            },
        });
    };

    const togglePanel = () => {
        setOpenPanel(!openPanel);
    };

    useEffect(() => {
        getUserToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* // <!-- Add Medication Modal --> */}
            {adherenceModal ? (
                <MedicationAdherenceModal
                    adherenceModal={adherenceModal}
                    setAdherenceModal={setAdherenceModal}
                    createAdherenceReview={createAdherenceReview}
                    updateAdherenceReview={updateAdherenceReview}
                />
            ) : (
                ''
            )}
            <div className={css['medication-adherence']}>
                <div className={cn(css['adherence-row'], css['section-title'])}>
                    <div
                        className={cn(
                            'col-xs-6',
                            css['adherence-title'],
                            'collapsed'
                        )}
                        onClick={() => togglePanel()}
                    >
                        <span
                            className="glyphicon glyphicon-play"
                            style={{
                                transform: openPanel ? 'rotate(90deg)' : '',
                            }}
                        />{' '}
                        {t('adherence.title')}
                    </div>
                    <div
                        className={cn(
                            'collapse',
                            'in',
                            'col-xs-6',
                            css['last-reconciled-by']
                        )}
                        id="adherence_header"
                    >
                        <span style={{fontSize: '16px'}}>
                            {isEmpty(medAdherenceData.adherencePassReviewList)
                                ? ''
                                : `Last Reviewed by ${
                                      medAdherenceData
                                          .adherencePassReviewList[0]
                                          .coach_login
                                  }
                        on ${
                            medAdherenceData.adherencePassReviewList[0].created_time.split(
                                ' '
                            )[0]
                        }
                        at ${
                            medAdherenceData.adherencePassReviewList[0].created_time.split(
                                ' '
                            )[1]
                        }
                        ${
                            medAdherenceData.adherencePassReviewList[0].created_time.split(
                                ' '
                            )[2]
                        }`}
                        </span>
                        <button
                            type="button"
                            className="btn btn-primary"
                            style={{background: '#209e91'}}
                            onClick={() => openAddAdherence()}
                            disabled={Arg('readOnly')}
                        >
                            {medAdherenceData.isLastReviewEditable
                                ? t('adherence.updateReview')
                                : t('adherence.newReview')}
                        </button>
                    </div>
                    <div
                        className={cn('row', css['adherence-row'])}
                        id="adherence_profile"
                        style={{display: openPanel ? '' : 'none'}}
                    >
                        <div className={cn('col-xs-12', css['profile-row'])}>
                            <div
                                id="medication-active-panel"
                                className="panel panel-default"
                            >
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <h2 className="panel-title center-vertical">
                                                {t('adherence.profile')}
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                {medAdherenceData.error ? (
                                    <div
                                        className="alert bg-danger closeable"
                                        role="alert"
                                    >
                                        {medAdherenceData.error}
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="panel-body">
                                    <div
                                        className={cn(
                                            'horizontal-scroll',
                                            'panel',
                                            css['adherence-list-view']
                                        )}
                                    >
                                        <MedicationAdherenceHeader />
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                {t('adherence.coachInsight')}
                                                <b />
                                            </h3>
                                        </div>

                                        <div className="panel-body">
                                            <div
                                                className={
                                                    css['medication-table-body']
                                                }
                                                id="table-list-view"
                                            >
                                                <MedicationAdherenceTable />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MedicationAdherence;
