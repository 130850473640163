import {memo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import useTransLoader from 'i18n/use-trans-loader';
import PropTypes from 'prop-types';
import cssDailySummary from './DailySummary.scss';
import css from './MacroNutrient.scss';

const MacroNutrient = ({data}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {nutrientCode, percent, min, max, weight} = data;

    return (
        <div className={css.root}>
            <span>
                <div>
                    <span className={cssDailySummary.heading}>
                        {t(`form.macroNutrient.${nutrientCode}`)}
                    </span>
                    <span>
                        : {percent}%
                        {nutrientCode === 'alcohol' && `, ${weight} g`}
                    </span>
                </div>
            </span>
            <span>
                <span>
                    {min}-{max}
                    {nutrientCode === 'alcohol' ? ' g' : '%'}
                </span>
                <span className={css.dailyGoal}>{t('form.dailyGoal')}</span>
            </span>
        </div>
    );
};

MacroNutrient.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(MacroNutrient);
