import FoodlogHeader from './FoodlogHeader';
import FoodlogSkeleton from './FoodlogSkeleton';

const Foodlog = props => {
    return (
        <div>
            <FoodlogHeader />
            <FoodlogSkeleton />
        </div>
    );
};

export default Foodlog;
