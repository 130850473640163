import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfWeek from 'date-fns/startOfWeek';

export const initialFirstDayOfWeek = subDays(
    startOfWeek(new Date(), {weekStartsOn: 0}),
    7
);
export default function foodLog(
    state = {initialFirstDayOfWeek},
    {type, payload}
) {
    switch (type) {
        case 'FIRST_DAY': {
            return {
                ...state,
            };
        }

        case 'PREV': {
            return {
                initialFirstDayOfWeek: subDays(state.initialFirstDayOfWeek, 7),
            };
        }

        case 'NEXT': {
            return {
                initialFirstDayOfWeek: addDays(state.initialFirstDayOfWeek, 7),
            };
        }

        case 'LOGS': {
            return {
                ...state,
                logs: [...payload],
            };
        }

        default:
            return state;
    }
}
