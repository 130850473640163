import PropTypes from 'prop-types';
import css from '../medicationAdherence/medication-adherence.scss';
import cn from 'classnames';
import format from 'date-fns/format';

const MedOptModal = ({medOptData}) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title center-vertical">
                    Provide Based Care
                </h3>
            </div>
            <div className="panel-body">
                <div className="medication-adherence-medication-table-body">
                    <h3
                        className="panel-title center-vertical"
                        style={{
                            paddingBottom: '10px',
                        }}
                    >
                        History
                    </h3>
                    <table
                        className={cn(
                            'table',
                            'table-striped',
                            css['table-list-view']
                        )}
                        style={{
                            fontsize: '12px',
                            lineheight: '1.42857',
                            color: '#333',
                        }}
                    >
                        <thead className={css['medication-table-header']}>
                            <tr>
                                <th className={css['med-opt-table-text']}>
                                    Date
                                </th>
                                <th className={css['med-opt-table-text']}>
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {medOptData.map(status => {
                                return (
                                    <tr
                                        key={status.date}
                                        className={css['medication-table-row']}
                                    >
                                        <td
                                            className={
                                                css['med-opt-table-data']
                                            }
                                        >
                                            {format(
                                                new Date(status.date),
                                                'MM/dd/yyyy'
                                            )}
                                        </td>
                                        <td
                                            className={
                                                css['med-opt-table-data']
                                            }
                                        >
                                            {status.event}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

MedOptModal.propTypes = {
    medOptData: PropTypes.array,
};

export default MedOptModal;
