import Arg from '@livongo/arg';

const defaultState = {
    expMed: true,
    patientID: '',
    lastReccBy: '',
    lastReccDate: '',
    inactiveReasonOtherId: 11,
    importedMedicationToShow: 5,
    drugNamesToShow: 20,
    hideMenu: Arg('pid'),
    showSearchForm: false,
    dateFormat: 'MM/dd/yyyy',
    inactiveDateOpened: false,
    medReviewed: 'initialReview',
    disableButtonActive: false,
    userProxyToken: '',
    reviewId: '',
    disabledFields: {
        tableField: true,
        reconciledBy: false,
    },
};

export default function medicationReview(
    state = {...defaultState},
    {type, payload}
) {
    switch (type) {
        case 'MEDICATION_REVIEW': {
            return {
                ...state,
                ...payload,
            };
        }

        case 'TABLE_DISABLE': {
            const stateCopy = {...state};

            stateCopy.disabledFields.tableField = payload;

            return stateCopy;
        }

        case 'REVIEWED_TODAY_DISABLE': {
            const stateCopy = {...state};

            stateCopy.disabledFields.reconciledBy = payload;

            return stateCopy;
        }

        default:
            return state;
    }
}
