import Arg from '@livongo/arg';
import Types from './types';
import ProtoUtils from '../../common/proto-utils';
import StringUtils from '@livongo/utils/string';

const {
    Food: {FoodLogClient, GetFoodLogHistoryRequest},
} = ProtoUtils;

const mockLogs = () => {
    return [
        {
            imageUrl:
                'https://cdn-a.william-reed.com/var/wrbm_gb_food_pharma/storage/images/publications/food-beverage-nutrition/foodnavigator.com/article/2017/12/06/why-sugar-and-why-so-much-who-investigates-the-food-industry-s-sweet-tooth/7624387-1-eng-GB/Why-sugar-and-why-so-much-WHO-investigates-the-food-industry-s-sweet-tooth_wrbm_large.jpg',
            uploadTime: {
                timeStamp: '2020-07-08T07:27:44',
                tzOffest: -420,
            },
            note:
                'Bowl of oatmeal with berries. Bla bla bla bla bla bla bla fdskjhfdsj fjkdhfdkjh jfkhfdjkhfdjh jkfhdskj fhdjkfhkjdshfkdf dfsljfdkj kfdljfk jfdkjfjdj dsjkdjk kjdkoeiwrief hfieurios oidsds',
            mealTag: 'LUNCH',
            deletedByUser: true,
        },
        {
            uploadTime: {
                timeStamp: '2020-07-08T07:27:44',
                tzOffest: -420,
            },
            note:
                'Bowl of oatmeal with berries. Bla bla bla bla bla bla bla fdskjhfdsj fjkdhfdkjh jfkhfdjkhfdjh jkfhdskj fhdjkfhkjdshfkdf dfsljfdkj kfdljfk jfdkjfjdj dsjkdjk kjdkoeiwrief hfieurios oidsds',
            mealTag: 'DINNER',
        },
        {
            imageUrl:
                'http://www.thecanadaguide.com/wp-content/uploads/top-photo-food.jpg',
            uploadTime: {
                timeStamp: '2020-07-08T07:27:44',
                tzOffest: -420,
            },
            note:
                'hey hey dffsd fhjsdb hfjdfdjh fjhfjkdhfjk hfkjfhdkjsfh kjshdfjhsdkj hfjkdhf jksdh kjfhdkfjhfjk hfj hfkjhfjhdfjhsdf ueiur uhfufiureiuroir9 987r89uyf8 9 hfiusfhfhf fhsifidfiuhffd hhfiufhdhf uhfui',
            mealTag: 'SNACK',
        },
        {
            imageUrl:
                'http://www.thecanadaguide.com/wp-content/uploads/top-photo-food.jpg',
            uploadTime: {
                timeStamp: '2020-07-08T07:27:44',
                tzOffest: -420,
            },
            note: 'hey hey 2',
            mealTag: 'BREAKFAST',
        },
        {
            uploadTime: {
                timeStamp: '2020-07-08T07:27:44',
                tzOffest: -420,
            },
            note: 'no picture log',
            mealTag: 'LUNCH',
        },
    ];
};

function getLogTime(data) {
    const metadata = data.hasImageMeta() ? data.getImageMeta() : null;

    // Food logs created before the attribute 'createTime' was added to the API don't have this attribute
    if (data.hasCreateTime()) {
        return data.getCreateTime();
    }
    // `captureTime` is the timestamp attached to the photo itself
    if (metadata && metadata.hasCaptureTime()) {
        return metadata.getCaptureTime();
    }

    // `uploadTime` isn't just the the time the reading was saved in the database, it's also the time a food log was updated
    return data.getUploadTime();
}

const getFoodLogHistory = startDate => {
    return new Promise((resolve, reject) => {
        const request = new GetFoodLogHistoryRequest();
        const start = Types.getAbsoluteTimestamp(startDate);
        // Get absolute time stamp from current time.
        const end = Types.getAbsoluteTimestamp();

        request.setName('user/liv/me/foodlog');
        request.setStartTime(start);
        request.setEndTime(end);

        const metadata = {
            Authorization: `Bearer ${Arg('authToken')}`,
        };

        FoodLogClient.getFoodLogHistoryAsArray(request, metadata).then(
            resp => {
                const entries = resp.getFoodLogList().map(item => {
                    return {
                        name: item.getName(),
                        note: StringUtils.unicodeToChar(item.getNote()),
                        createTime: Types.getNormalizedTime(getLogTime(item)),
                        mealTag: Types.getMealTag(item.getMealTag()),
                        imageUrl: item.getImageUrl(),
                        deletedByUser: item.hasDeleteTime(),
                        nixFood: item.getNixFood(),
                    };
                });

                resolve(entries);
            },
            err => {
                reject(err);
            }
        );
    });
};

const FoodService = {
    mockLogs,
    getFoodLogHistory,
};

export default FoodService;
