/* eslint-disable camelcase */
const barriersMapping = [
    {id: 1, name: 'Access'},
    {id: 2, name: 'Complexity'},
    {id: 3, name: 'Costs'},
    {id: 4, name: 'Education gaps/beliefs'},
    {id: 5, name: 'Hypo potential'},
    {id: 6, name: 'Side effects'},
    {id: 7, name: 'Time'},
    {id: 8, name: 'Member not interested in taking statin'},
    {id: 9, name: 'Member discontinued statin'},
    {id: 20, name: 'Other'},
];

const adherenceForm = {
    coach_login: '',
    nonAdherenceFlag: true,
    barriers_list: [],
    coach_notes: '',
};

const nonadherenceFlags = [
    {txt: 'Yes', checked: true},
    {txt: 'No', checked: false},
];

const newAdherence = {
    coach_login: '',
    nonAdherenceFlag: false,
    barriers_list: [],
    coach_notes: '',
};

const initState = {
    currentCoachLogin: '',
    errorMessage: '',
    adherencereviewId: 0,
    reviewId: 0,
    userToken: '',
    activeAdherenceMedicationList: [],
    adherenceSummary: {},
    adherencePassReviewList: [],
    nonAdherenceFlag: false,
    barriers: '',
    dsNote: '',
    isLastReviewEditable: false,
    availableBarriersList: [],
    isDisabled: false,
    lastReview: {},
    checkedBerriers: [],
    newAdherence,
    nonadherenceFlags,
    adherenceForm,
    barriersMapping,
};

export default function medicationAdherenceData(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case 'ADHERENCE_SUMMARY': {
            return {
                ...state,
                ...payload,
            };
        }

        case 'AUTH': {
            return {
                ...state,
                auth: {...payload},
            };
        }

        case 'NEW_ADHERENCE': {
            return {
                ...state,
                newAdherence: payload,
            };
        }

        case 'CHECKED_BERRIERS': {
            return {
                ...state,
                adherenceSummary: {
                    ...state.adherenceSummary,
                    checkedBerriers: [...payload],
                },
            };
        }

        case 'ERROR': {
            return {
                ...state,
                errorMessage: payload,
            };
        }

        default:
            return state;
    }
}
