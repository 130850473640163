import HsrHeader from './header/HsrHeader';
import HsrFooter from './footer/HsrFooter';
const HealthSummaryReport = props => {
    return (
        <div>
            <HsrHeader />
            <h1>Health Summary Report</h1>
            <HsrFooter />
        </div>
    );
};

export default HealthSummaryReport;
