import PropTypes from 'prop-types';
import {useRouteMatch} from 'react-router-dom';
import useTransLoader from 'i18n/use-trans-loader';
import cssDailySummary from './DailySummary.scss';

const MicroNutrient = ({data}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {code, value, unit, max, targetValue} = data;
    let target = targetValue;

    if (!target) target = max;

    if (!target || target <= 0) target = null;

    return (
        <div className={cssDailySummary.mixpanel}>
            <span className={cssDailySummary.heading}>{t(`form.${code}`)}</span>
            <span>: {`${value.toLocaleString()} `}</span>
            <span>{unit}</span>
        </div>
    );
};

MicroNutrient.propTypes = {
    data: PropTypes.object.isRequired,
};

export default MicroNutrient;
