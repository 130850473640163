import {useState} from 'react';
import Button from '@livongo/ui/Button';
import TextInput from '@livongo/ui/TextInput';
import Tabs, {Tab, TabPanel, TabContent, TabNavigation} from '@livongo/ui/Tabs';
import css from './Demo.scss';

const Demo = props => {
    const [authToken, setAuthToken] = useState(null);
    const [pid, setPid] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [patientId, setPatientId] = useState(null);
    const [conditions, setConditions] = useState(null);
    const [queryUrl, setQueryUrl] = useState(null);

    const setQueries = () => {
        setQueryUrl(
            `?authToken=${authToken}&sessionId=${sessionId}&pid=${pid}&patientId=${patientId}&conditions=${conditions}`
        );
    };

    const inputs = [
        {name: 'Auth Tokens', setter: setAuthToken},
        {name: 'PID', setter: setPid},
        {name: 'Session Id', setter: setSessionId},
        {name: 'Patient Id', setter: setPatientId},
        {name: 'Conditions', setter: setConditions},
    ];

    const routes = [
        '/coaching',
        '/foodlog',
        '/logbook',
        '/medication-reconciliation',
    ];

    return (
        <div>
            <div className={css.userInput}>
                <Button color="primary" onClick={() => setQueries()}>
                    Set queries
                </Button>
                <div className={css.textInputs}>
                    {inputs.map((input, i) => {
                        return (
                            <TextInput
                                key={i}
                                label={<label />}
                                onChange={e => input.setter(e.target.value)}
                                placeholder={input.name}
                            />
                        );
                    })}
                </div>
            </div>

            <Tabs>
                <TabNavigation>
                    <Tab>Home</Tab>
                    <Tab>Coaching</Tab>
                    <Tab>Foodlog</Tab>
                    <Tab>Logbook</Tab>
                    <Tab>Medication</Tab>
                </TabNavigation>
                <TabContent>
                    <TabPanel />
                    {routes.map((route, i) => {
                        return (
                            <TabPanel key={i}>
                                <iframe
                                    width="100%"
                                    height="750px"
                                    src={`${route}${queryUrl || ''}`}
                                />
                            </TabPanel>
                        );
                    })}
                </TabContent>
            </Tabs>
        </div>
    );
};

export default Demo;
