import DeviceCgmDexcomApi from '@livongo/device-cgm-dexcom-api/lib/livongo/protobuf/grpc/external/device/cgm/dexcom/api/report_grpc_web_pb';
import Arg from '@livongo/arg';
import map from 'lodash/map';

const DexcomReportPromiseClient = new DeviceCgmDexcomApi.DexcomReportServicePromiseClient(
    process.env.gRPC_URL
);

export const DexcomReportService = () => {
    return new Promise((resolve, reject) => {
        const request = new DeviceCgmDexcomApi.DexcomReportRequest();

        request.setName('user/liv/me/device/cgm/dexcom/report');
        const metadata = {
            Authorization: `Bearer ${Arg('authToken')}`,
        };

        DexcomReportPromiseClient.getDexcomReportPdf(request, metadata).then(
            resp => {
                const entries = map(resp, () => {
                    return {
                        location: resp.getLocation(),
                    };
                });

                resolve(entries);
            },
            err => {
                reject(err);
            }
        );
    });
};
