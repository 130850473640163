import css from './medication-adherence.scss';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const MedicationAdherenceHeader = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const medAdherenceData = useSelector(
        state => state.medicationAdherenceData
    );

    return (
        <div className={cn('col-xs-12', 'panel-title', css['panel-profile'])}>
            <div className="col-xs-3">
                <label className={css['profile-head']}>
                    {medAdherenceData.nonAdherenceFlag ? (
                        <span
                            className={cn(
                                css['high-adherence-flag'],
                                css['adherence-flag']
                            )}
                        >
                            &nbsp;
                        </span>
                    ) : (
                        ''
                    )}{' '}
                    {t('adherence.flagged')}{' '}
                </label>
                <span className={css['profile-head-info']}>
                    {medAdherenceData.nonAdherenceFlag ? 'Yes' : 'No'}
                </span>
            </div>
            <div className="col-xs-4">
                <label className={css['profile-head']}>
                    {t('adherence.barriers')}
                </label>
                <span
                    className={cn(
                        css['profile-head-info'],
                        css['ds-note'],
                        css['ds-barrier']
                    )}
                >
                    {medAdherenceData.barriers}
                </span>
            </div>
            <div className="col-xs-5">
                <label className={css['profile-head']}>
                    DS {t('adherence.insights')}
                </label>
                <span
                    className={cn(
                        css['profile-head-info'],
                        css['ds-note'],
                        css['ds-barrier']
                    )}
                >
                    {' '}
                    {medAdherenceData.dsContext}
                </span>
            </div>
        </div>
    );
};

export default MedicationAdherenceHeader;
