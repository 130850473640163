import css from './medication-adherence.scss';
import {useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const MedicationAdherenceTable = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const medAdherenceData = useSelector(
        state => state.medicationAdherenceData
    );

    return (
        <table className={cn('table', 'table-striped', css['table-list-view'])}>
            <thead className={css['medication-table-header']}>
                <tr>
                    <th className={css['date-table']}>{t('adherence.date')}</th>
                    <th className={css['date-table']}>
                        {t('adherence.coach')}
                    </th>
                    <th className={css['insight-table']}>
                        {t('adherence.insights')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {isEmpty(medAdherenceData.adherencePassReviewList) ? (
                    <div className={css['adherence-no-data']}>
                        {t('adherence.noData')}
                    </div>
                ) : (
                    medAdherenceData.adherencePassReviewList.map(adl => {
                        return (
                            <tr
                                key={adl.created_time}
                                className={css['medication-table-row']}
                            >
                                <td className={css['date-table']}>
                                    {adl.created_time}
                                </td>
                                <td className={css['data-table']}>
                                    <div>
                                        <span
                                            className={css['bg-value-number']}
                                        >
                                            {adl.coach_login}
                                        </span>
                                    </div>
                                </td>
                                <td className={css['adherence-table']}>
                                    {adl.coach_notes}
                                </td>
                            </tr>
                        );
                    })
                )}
            </tbody>
        </table>
    );
};

export default MedicationAdherenceTable;
