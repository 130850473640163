import APIUtils from '@livongo/utils/api';

function getUserToken(pid, tokenScope) {
    const scope =
        tokenScope === 'write'
            ? 'WriteUserTmpHack ReadSystemInfo ReadUser'
            : 'ReadSystemInfo ReadUser';
    const dataToSend = {
        name: pid,
        scope,
    };

    return APIUtils.post('/v1/admin/auth/user', dataToSend);
}

const AdminUserResource = {getUserToken};

export default AdminUserResource;
