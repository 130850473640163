import APIUtils from '@livongo/utils/api';
import {config} from '../common/headers';

function getPersonalInfo(token) {
    return new Promise((resolve, reject) => {
        APIUtils.get(
            '/v1/users/me/info/personal?inclIntlPhones=true',
            null,
            config(token)
        )
            .then(data => resolve(data.data))
            .catch(err => reject(err));
    });
}

function getHealthProfileSchema(token) {
    return new Promise((resolve, reject) => {
        APIUtils.get('/v1/users/me/info/health', null, config(token))
            .then(data => resolve(data.data))
            .catch(err => reject(err));
    });
}

function getAccountInfo(token) {
    return new Promise((resolve, reject) => {
        APIUtils.get('/v1/accounts/me/info', null, config(token))
            .then(data => resolve(data.data))
            .catch(err => reject(err));
    });
}

function getAccountPref(token) {
    return new Promise((resolve, reject) => {
        APIUtils.get('/v1/users/me/prefs/apps/1/1/lang', null, config(token))
            .then(data => resolve(data.data))
            .catch(err => reject(err));
    });
}

export const UserResource = {
    getPersonalInfo,
    getHealthProfileSchema,
    getAccountInfo,
    getAccountPref,
};
