import {useCallback, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import useMounted from '@livongo/utilities/hooks/useMounted';
import Loader from '@teladoc/pulse/ui/Loader';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import useApi from '../common/use-api';
import FoodFocusService from './utils/FoodFocusService';
import useTransLoader from 'i18n/use-trans-loader';
import FoodUtils from './utils/food-utils';
import css from './DailySummary.scss';
import MacroNutrient from './MacroNutrient';
import MicroNutrient from './MicroNutrient';

const PANELS = {
    CALORIE: 'calorie',
    SODIUM: 'sodium',
    POTASSIUM: 'potassium',
    BREAKDOWN: 'breakdown',
};

const DailySummary = summaryDate => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {isMounted} = useMounted();
    const [data, setData] = useState(null);

    const getTrackingPanelOrder = numOfTrackedPanel => {
        return [
            PANELS.CALORIE,
            PANELS.BREAKDOWN,
            PANELS.SODIUM,
            PANELS.POTASSIUM,
        ];
    };

    const onDataLoaded = useCallback(
        summaryData => {
            if (isMounted) {
                if (summaryData) {
                    const calorieSummary = summaryData.getCalorieSummary?.();
                    const breakDownsList = calorieSummary?.getBreakdownsList?.();
                    const calorieTarget = calorieSummary
                        ?.getTargetValue?.()
                        ?.getValue();

                    const microNutrients = [];

                    // add Calorie values to array
                    let microObj = FoodUtils.convertMicroNutrient(
                        calorieSummary?.getCalorieVal?.(),
                        calorieTarget
                    );

                    if (microObj) microNutrients.push(microObj);

                    // add Sodium/Potassium values to array
                    summaryData.getMicroNutrientsList?.().forEach(item => {
                        if (item) {
                            microObj = FoodUtils.convertMicroNutrient(item);

                            if (microObj) microNutrients.push(microObj);
                        }
                    });

                    const calorieBreakDowns =
                        breakDownsList &&
                        FoodUtils.getMacroNutrients(breakDownsList);

                    const totalCalorie = calorieBreakDowns
                        ? FoodUtils.getTotalCalories(calorieBreakDowns)
                        : 0;

                    setData({
                        breakDowns: calorieBreakDowns || [],
                        total: calorieBreakDowns?.length || 3,
                        microNutrients: microNutrients || [],
                        totalCalorie,
                    });
                }
            }
        },
        [isMounted]
    );

    const {isLoading} = useApi({
        api: FoodFocusService.getFoodNutritionSummaries,
        params: summaryDate.summaryDate,
        onLoad: onDataLoaded,
    });

    if (isLoading) {
        return <Loader />;
    }

    const panelOrderArray = getTrackingPanelOrder(
        data?.microNutrients.length || 0
    );

    return (
        <div className={css.root}>
            {panelOrderArray.map((name, key) => {
                if (name === PANELS.BREAKDOWN) {
                    return (
                        <div key={key} className={css.calorieBreakdown}>
                            {data?.breakDowns.length > 0 &&
                            data?.totalCalorie > 0 ? (
                                <div>
                                    <div className={css.heading}>
                                        {t('form.breakdownTitle')}
                                    </div>
                                    <div className={css.foodInsight}>
                                        <div>
                                            {data?.breakDowns.map(
                                                (macro, i) => (
                                                    <div key={i}>
                                                        <MacroNutrient
                                                            data={macro}
                                                        />
                                                        <HorizontalRule
                                                            className={css.hr}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            <span />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={css.noData}>
                                    <div>
                                        {data?.breakDowns.length === 0
                                            ? t('form.noDataMessage')
                                            : t('form.notEnoughDataMessage')}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                } else {
                    if (data?.microNutrients.length > 0) {
                        const micro = data?.microNutrients.find(
                            item => item.code === name
                        );

                        return micro ? (
                            <div key={key}>
                                <MicroNutrient data={micro} />
                            </div>
                        ) : (
                            ''
                        );
                    } else return <div key={key} className={css.microPanel} />;
                }
            })}
        </div>
    );
};

export default DailySummary;
