import isEmpty from 'lodash/isEmpty';
import {useEffect, useState} from 'react';
import {DexcomReportService} from './DexcomReportService';

const Cgm = props => {
    const [ContentPDF, setContentPDF] = useState('');
    const [APIerror, setAPIerror] = useState(false);
    const cgmStyling = {height: '100vh', width: '100%'};
    const embedStyling = {width: '100%', height: '100%'};

    const errorStyling = {
        padding: '100px 50px',
        textAlign: 'center',
        fontFamily: 'Arial',
        fontSize: '12pt',
    };

    const getDexcomReport = () => {
        DexcomReportService().then(
            data => {
                if (!isEmpty(data[0])) {
                    setContentPDF(data[0].location);
                }
            },
            err => {
                console.log(err); // eslint-disable-line no-console
                setAPIerror(true);
            }
        );
    };

    useEffect(() => {
        getDexcomReport();
    }, []);

    return (
        <div style={cgmStyling}>
            {!APIerror ? (
                <embed
                    style={embedStyling}
                    src={ContentPDF}
                    type="application/pdf"
                />
            ) : (
                <div style={errorStyling}>
                    Unable to authorize member. This member has revoked CGM
                    consent.
                </div>
            )}
        </div>
    );
};

export default Cgm;
