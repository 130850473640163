import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

export const bgRangeClass = input => {
    const eatCode = get(input, 'tags.eat_code') || -1;
    const bgValue = get(input, 'value');

    // no more calculation in case the value is missing
    if (isUndefined(bgValue)) return '';

    // before eating in range is [80, 130]
    // the rest should be in [80, 180]
    let upperLimit = 180;
    const lowerLimit = 80;

    // before meals codes are: 6, 7, 8
    if (eatCode >= 6 && eatCode <= 8) {
        upperLimit = 130;
    }

    // determin BG range
    if (bgValue < lowerLimit) {
        return 'bg-below-range';
    } else if (bgValue > upperLimit) {
        return 'bg-above-range';
    }

    return 'bg-in-range';
};
