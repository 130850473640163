import {Suspense, useMemo, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import App from './App';
import Coaching from '../coaching/Coaching';
import Logbook from '../logbook/Logbook';
import Foodlog from '../foodlog/Foodlog';
import MentalHealthDashboard from '../mentalHealthDashboard/MentalHealthDashboard';
import MedicationReview from '../medicationReconciliation/MedicationReview';
import Medication from '../medication/Medication';
import VideoConferences from '../video/VideoConferences';
import Demo from '../demo/Demo';
import Cgm from '../cgm/Cgm';
import Rebranding from '../rebranding/Rebranding';
import DateLocaleContext from '@livongo/ui/Context/DateLocale';
import {getDateLocale} from 'common/dates';
import format from 'date-fns/format';
import {getLanguage} from 'i18n';
import Loader from '@livongo/ui/Loader';
import HealthSummaryReport from '../healthsummaryreport/HealthSummaryReport';

const Root = props => {
    let lang = window.navigator.language.substr(0, 2);

    if (lang === 'en' || lang === 'es') {
        lang = getDateLocale(lang);
    } else {
        lang = getDateLocale(getLanguage());
    }
    const [DateLocale, setDateLocale] = useState(lang);

    return (
        <DateLocaleContext.Provider
            value={useMemo(
                () => ({
                    DateLocale,
                    // we must pass the onChange to the context
                    // in case @livongo/ui for example wants to change the date locale
                    onChange: setDateLocale,
                    // use this if the format needs to be translated
                    // aka: format(new Date(), 'MMMM') produces "January"
                    // this must be localized, use format from DateLocaleContext
                    // aka: format(new Date(), 'yyyy') produces "2021"
                    // and does NOT need to be localized, use regular date-fns import
                    formatLocale: (date, formatStr = 'PP') =>
                        format(date, formatStr, {
                            locale: DateLocale,
                        }),
                }),
                [DateLocale]
            )}
        >
            <Router>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route exact path="/" component={App} />
                        <Route exact path="/coaching" component={Coaching} />
                        <Route exact path="/logbook" component={Logbook} />
                        <Route exact path="/foodlog" component={Foodlog} />
                        <Route
                            exact
                            path="/mentalHealthDashboard"
                            component={MentalHealthDashboard}
                        />
                        <Route
                            exact
                            path="/video"
                            component={VideoConferences}
                        />
                        <Route
                            exact
                            path="/medication-reconciliation"
                            component={MedicationReview}
                        />
                        <Route
                            exact
                            path="/medication"
                            component={Medication}
                        />
                        <Route
                            exact
                            path="/healthsummaryreport"
                            component={HealthSummaryReport}
                        />
                        <Route exact path="/cgm" component={Cgm} />
                        <Route exact path="/demo" component={Demo} />
                        <Route
                            exact
                            path="/rebranding"
                            component={Rebranding}
                        />
                    </Switch>
                </Suspense>
            </Router>
        </DateLocaleContext.Provider>
    );
};

export default Root;
