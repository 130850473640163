import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

const locales = {
    en,
    es,
};

/**
 * Function to return date-fns entire locale object.
 *
 * @param {String} lang - They key name of the locale to use.
 * @example
 * getDateLocale('es'); // returns import of 'date-fns/locale/es'
 * @returns {Object} - Locale from date-fns https://date-fns.org/v2.19.0/docs/Locale
 */
export const getDateLocale = lang => {
    const locale = locales[lang];

    if (!locale) {
        // eslint-disable-next-line no-console
        console.error(`DateLocale with ${lang} key name does not exist.`);
    }

    return locale;
};

export const toUTC = value => {
    const date = new Date(value);

    return date.getTime() + date.getTimezoneOffset() * 60 * 1000;
};
