import get from 'lodash/get';

// define meal types values
export const mealTypes = {
    breakfast: {
        before: 6,
        after: 9,
    },
    lunch: {
        before: 7,
        after: 10,
    },
    dinner: {
        before: 8,
        after: 11,
    },
    other: 12,
    noMeal: 13,
    noTag: -1,
};

export const feelingCodes = [
    {
        value: 9,
        name: 'I feel fine',
        dataKey: 'feelFine',
    },
    {
        value: 10,
        name: "I don't feel well",
        dataKey: 'dontFeelFine',
    },
    {
        value: 11,
        name: 'Light headed',
        dataKey: 'lightHeaded',
    },
    {
        value: 12,
        name: 'Stressed out',
        dataKey: 'stressedOut',
    },
    {
        value: 13,
        name: 'After exercise',
        dataKey: 'afterExercise',
    },
    {
        value: 14,
        name: 'Ate more',
        dataKey: 'ateMore',
    },
    {
        value: 15,
        name: 'Increased meds',
        dataKey: 'increasedMeds',
    },
    {
        value: 16,
        name: 'Missed meds',
        dataKey: 'missedMeds',
    },
    {
        value: 17,
        name: 'Other',
        dataKey: 'other',
    },
];

export const mealCodes = [
    {
        value: get(mealTypes, 'breakfast.before'),
        name: 'Before breakfast',
        dataKey: 'beforeBreakfast',
    },
    {
        value: get(mealTypes, 'breakfast.after'),
        name: 'After breakfast',
        dataKey: 'afterBreakfast',
    },
    {
        value: get(mealTypes, 'lunch.before'),
        name: 'Before lunch',
        dataKey: 'beforeLunch',
    },
    {
        value: get(mealTypes, 'lunch.after'),
        name: 'After lunch',
        dataKey: 'afterLunch',
    },
    {
        value: get(mealTypes, 'dinner.before'),
        name: 'Before dinner',
        dataKey: 'beforeDinner',
    },
    {
        value: get(mealTypes, 'dinner.after'),
        name: 'After dinner',
        dataKey: 'afterDinner',
    },
    {
        value: get(mealTypes, 'other'),
        name: 'Snack/Other',
        dataKey: 'snackOther',
    },
    {
        value: get(mealTypes, 'noMeal'),
        name: 'No Meal',
        dataKey: 'noMeal',
    },
    {
        value: get(mealTypes, 'noTag'),
        name: 'No Tag',
        dataKey: 'noTag',
    },
];

export const getValue = item => {
    if (get(item, 'tags.OOR_low')) return '< 20';

    if (get(item, 'tags.OOR_high')) return '> 600';

    return get(item, 'value') || '';
};

export const sourceOptions = [
    {
        name: 'Web',
        dataKey: 'web',
        key: 1,
        value: [7],
    },
    {
        name: 'MobileApp',
        dataKey: 'mobileApp',
        key: 3,
        value: [2],
    },
    {
        name: 'MobileApp Bluetooth',
        dataKey: 'mobileAppBluetooth',
        key: 4,
        value: [3],
    },
    {
        name: 'Teladoc Provided Device - BG1000',
        dataKey: 'bG1000',
        key: 5,
        value: [13],
    },
    {
        name: 'MobileApp - OCR',
        dataKey: 'mobileAppOCR',
        key: 6,
        value: [14],
    },
    {
        name: 'Teladoc Provided Device',
        dataKey: 'inTouch',
        key: 7,
        value: [1],
    },
    {
        name: 'Simulator',
        dataKey: 'simulator',
        key: 8,
        value: [11],
    },
];
