import Arg from '@livongo/arg';

// This header is used for bearer tokens mostly from sessionId or token returned from user admin api in logbook
// ex: config("authToken") or config("sessionId") or take in argument (logbook)
export const config = bearerToken => {
    const fromUrl = bearerToken === 'authToken' || bearerToken === 'sessionId';

    return {
        headers: {
            Authorization: `Bearer  ${
                fromUrl ? Arg(bearerToken) : bearerToken
            }`,
            'Content-Type': 'application/json',
        },
    };
};
