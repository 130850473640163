import PropTypes from 'prop-types';
import css from './FoodlogCard.scss';
import noImage from './img/ic-no-image.png';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const FoodlogCard = ({info: {imageUrl, deletedByUser}}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);

    return (
        <div className={css['food-log-container']}>
            {deletedByUser ? (
                <div className={css['log-deleted']}>
                    <span>
                        {t('removed.header')} <br />
                        {t('removed.subText')}
                    </span>
                </div>
            ) : (
                ''
            )}

            <div
                className={css['food-log-image']}
                style={
                    imageUrl
                        ? {
                              backgroundImage: `url('${imageUrl}')`,
                              backgroundSize: 'cover',
                          }
                        : {'': ''}
                }
            >
                {!imageUrl ? (
                    <div className={css['no-image']}>
                        <img src={noImage} />
                        <br />
                        {t('unavailable.img')}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

FoodlogCard.propTypes = {
    info: PropTypes.shape({
        note: PropTypes.string,
        imageUrl: PropTypes.string,
        createTime: PropTypes.object,
        deletedByUser: PropTypes.bool,
        nixFood: PropTypes.object,
    }),
};

export default FoodlogCard;
