import {WeightService} from './WeightService';
import find from 'lodash/find';
import get from 'lodash/get';
import Arg from '@livongo/arg';

export const getWeightInfo = async (interval, dispatch, userToken) => {
    const conditions = (Arg('conditions') || '').split(';');
    const weightConditions = [
        'Prediabetes',
        'Weight_Management',
        'Chronic_Kidney_Disease',
        'Heart_Failure',
    ];
    const displayWeight =
        conditions.filter(condition => weightConditions.includes(condition))
            .length > 0;

    // Display weight component if any of these conditions are listed in the query
    if (displayWeight) {
        dispatch({
            type: 'DISPLAY_WEIGHT',
            payload: true,
        });
        dispatch({
            type: 'SET_SECTION',
            payload: {
                selected: 'weight',
                value: true,
            },
        });

        if (userToken) {
            await WeightService.getWeightStats(interval, false, userToken)
                .then(data => {
                    const tempWeightStats = {};
                    const last30 = find(data.stats, {duration: 30});

                    tempWeightStats.overall = data.overallStats;
                    tempWeightStats.last30 = get(last30, 'change');
                    tempWeightStats.currentWeight = get(
                        data,
                        'overallStats.currentWeightReading'
                    );
                    dispatch({
                        type: 'WEIGHT_DATA',
                        payload: tempWeightStats,
                    });
                })
                .catch(err => {
                    console.log(err); // eslint-disable-line no-console
                });

            await WeightService.getWeightTarget(userToken)
                .then(data => {
                    dispatch({
                        type: 'NO_WEIGHT_DATA',
                        payload: false,
                    });
                    dispatch({
                        type: 'SET_SECTION',
                        payload: {
                            selected: 'weight',
                            value: true,
                        },
                    });
                    const tempWeightStats = {};

                    tempWeightStats.target =
                        get(data, 'selfEnteredTarget.target') ||
                        get(data, 'suggestedTarget');

                    if (typeof tempWeightStats.target.weight === 'number')
                        tempWeightStats.target.weight = tempWeightStats.target.weight.toFixed(
                            2
                        );

                    dispatch({
                        type: 'WEIGHT_DATA',
                        payload: tempWeightStats,
                    });
                    WeightService.buildWeightGraphs(
                        interval,
                        get(tempWeightStats.target, 'weight'),
                        userToken
                    )
                        .then(readings => {
                            dispatch({
                                type: 'WEIGHT_DATA',
                                payload: {weightReadings: readings},
                            });
                        })
                        .catch(err => {
                            dispatch({
                                type: 'NO_WEIGHT_DATA',
                                payload: true,
                            });
                            dispatch({
                                type: 'SET_SECTION',
                                payload: {
                                    selected: 'weight',
                                    value: false,
                                },
                            });
                        });
                })
                .catch(err => {
                    dispatch({
                        type: 'NO_WEIGHT_DATA',
                        payload: true,
                    });
                });
        }
    } else {
        dispatch({
            type: 'DISPLAY_WEIGHT',
            payload: false,
        });
    }
};
