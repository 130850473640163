import {WeightResource} from '../res_weight';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import get from 'lodash/get';
import subDays from 'date-fns/subDays';
import format from 'date-fns/format';
import {intlFormat} from 'date-fns';

const mockAPI = false;

function mockApiResponse(days) {
    const readings = [];
    const max = 250;
    const minNum = 180;

    for (let index = 0; index < days; index++) {
        const value = Math.floor(Math.random() * (max - minNum + 1)) + minNum;

        readings.push({
            id: index,
            weight: {
                weight: value,
                weightUnit: index % 5 ? 'LB' : 'KG',
            },
            normalizedWeightLb: value,
            measurementTime: format(
                subDays(new Date(), index),
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            calibrationOffset: 0,
            isIrregular: false,
            provider: 'BODYTRACE',
            sourceIdentifier: '',
            sourceManufacturer: 'BodyTrace',
        });
    }

    return {
        weightReadings: readings,
    };
}

const removeTzFromTimestamp = (timestamp = '') => {
    const seperator = 'T';
    const [date, time] = timestamp.split(seperator);

    return [date, time.split(/[-+]/)[0]].join(seperator);
};

const normalizeTime = time => {
    return intlFormat(new Date(removeTzFromTimestamp(time)), {
        hour: 'numeric',
        minute: 'numeric',
    });
};

const normalizeDate = day => {
    return intlFormat(new Date(day), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
};

function normalizeWeightReadingsForGraph(readings) {
    const normalizedReadings = [];

    readings.forEach(reading => {
        normalizedReadings.push({
            x: new Date(reading.measurementTime).getTime(),
            y: get(reading, 'normalizedWeightLb'),
            normalizedTime: normalizeTime(reading.measurementTime),
            normalizedDate: normalizeDate(reading.measurementTime),
        });
    });

    return sortBy(normalizedReadings, ['x']);
}

function buildWeightGraphData(target, weights) {
    const data = normalizeWeightReadingsForGraph(weights);

    return data;
}

const buildWeightGraphs = (daysNumber, target, token) => {
    return Promise.all([
        WeightResource.getWeightReadings(daysNumber, token),
        WeightResource.getWeightReadings(10, token),
    ])
        .then(data => {
            const readings = map(data, item => {
                return filter(get(item, 'weightReadings'), reading => {
                    return !reading.isIrregular;
                });
            });

            const chartData = buildWeightGraphData(
                target,
                mockAPI
                    ? mockApiResponse(daysNumber).weightReadings
                    : readings[0]
            );

            return {
                last90: readings[0],
                last10: readings[1],
                chartData,
            };
        })
        .catch(err => console.log('error getting weight readings', err)); // eslint-disable-line no-console
};

const getWeightStats = (daysNumber, mock, token) => {
    return WeightResource.getWeightStats(`${daysNumber},30`, mock, token);
};

const getWeightTarget = token => {
    return WeightResource.getWeightTarget(token);
};

const drawGoalLine = (
    {
        chart,
        goal,
        goalTitle,
        lineXOffset = 0,
        titleXOffset = 8,
        titleCss = {color: '#666f7f', fontWeight: 400},
        labelCss = {color: '#424242', fontWeight: 500},
        lineSettings = {
            'stroke-width': 2,
            stroke: '#36d663',
        },
        rightAlignLabel = false,
    },
    weightReadings
) => {
    const yAxis = chart.yAxis[0];
    let currentMax = goal;

    weightReadings.forEach(weight => {
        currentMax = Math.max(weight.y, currentMax);
    });

    const tickMax =
        chart.yAxis[0].paddedTicks[chart.yAxis[0].paddedTicks.length - 1];

    // Keep the goal line since it hides when y axis gets low, the max y axis value should be greater than or equal to goal
    if (goal > tickMax || currentMax > tickMax) {
        yAxis.update({
            max: currentMax,
        });
    }
    const plottedGoal = yAxis.toPixels(goal);
    const svgGroup = chart.renderer
        .g()
        .attr({
            zIndex: 4,
        })
        .add();
    const labelX = chart.plotLeft + chart.plotWidth + 10;

    // clear out old custom stuff before redrawing so that it doesn't double draw
    if (chart.goalLine) {
        chart.goalLine.destroy();
    }

    if (chart.goalTitle) {
        chart.goalTitle.destroy();
    }

    if (chart.goalLabel) {
        chart.goalLabel.destroy();
    }

    // draw goal line
    chart.goalLine = chart.renderer
        .path([
            'M',
            chart.plotLeft - lineXOffset,
            plottedGoal,
            'L',
            chart.plotLeft + chart.plotWidth,
            plottedGoal,
        ])
        .attr(lineSettings)
        .add(svgGroup);

    // draw goal title
    chart.goalTitle = chart.renderer
        .label(goalTitle, chart.plotLeft + titleXOffset, plottedGoal + 6)
        .css(titleCss)
        .add(svgGroup);

    // draw goal label
    chart.goalLabel = chart.renderer
        .label(goal, labelX, plottedGoal - 13)
        .css(labelCss)
        .add(svgGroup);

    if (rightAlignLabel) {
        chart.goalTitle.attr({
            x: labelX,
        });

        chart.goalLabel.attr({
            x: labelX + chart.goalTitle.width - chart.goalLabel.width,
        });
    }
};

export const WeightService = {
    getWeightStats,
    getWeightTarget,
    buildWeightGraphs,
    drawGoalLine,
};
