// medicationOptions
// reportedAdherenceOptions: reportedAdherence,
// medClassOptions: medClasses,
export default function medicationOptions(state = {}, {type, payload}) {
    switch (type) {
        case 'MEDICATION_OPTIONS': {
            console.log(payload); // eslint-disable-line no-console

            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
}
