import Arg from '@livongo/arg';

const VideoConferences = props => {
    const authToken = Arg.all();
    const dataObject = {clinicalPortalToken: authToken.authToken};
    // const src = `${process.env.CONFERENCE_VIDEO_URL}?authToken=${authToken.authToken}`;
    // const url = 'https://web-ui-bhdts.mystrength.livongo.com/conference';
    const openFrame = () => {
        const iframe = document.createElement('iframe');

        iframe.src = process.env.CONFERENCE_VIDEO_URL;
        iframe.id = 'iframe_open';
        iframe.style.position = 'absolute';
        iframe.style.zIndex = '999';
        iframe.style.height = '100%';
        iframe.style.width = '100%';
        iframe.style.top = '0';
        iframe.allow = 'camera; microphone; fullscreen';

        document.body.prepend(iframe);

        iframe.onload = () => {
            iframe.contentWindow.postMessage(
                dataObject,
                process.env.CONFERENCE_VIDEO_URL
            );
        };
    };

    return <div onLoad={openFrame()} />;
};

export default VideoConferences;
