import {combineReducers} from 'redux';
import foodlogReducer from '../foodlog/foodlog-reducers/foodlog-reducer';
import medicationsLists from '../medicationReconciliation/medcationReconciliationReducers/medications-list-reducer';
import medicationOptions from '../medicationReconciliation/medcationReconciliationReducers/medication-options-reducer';
import userAuth from '../demo/DemoReducer';
import logBookAuth from '../logbook/reducers/logbookAuth';
import logBookFilters from '../logbook/reducers/logbookFilters';
import bgsReducer from '../logbook/reducers/bgsReducer';
import weightDataReducer from '../logbook/reducers/weightDataReducer';
import logBookControls from '../logbook/reducers/controls';
import medicationAdherenceData from '../medicationAdherence/reducers/medication-adherence-reducer';
import medicationReview from '../medicationReconciliation/medcationReconciliationReducers/medication-review';

export default combineReducers({
    foodlogReducer,
    medicationsLists,
    medicationOptions,
    userAuth,
    logBookAuth,
    logBookFilters,
    bgsReducer,
    weightDataReducer,
    logBookControls,
    medicationAdherenceData,
    medicationReview,
});
