import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import format from 'date-fns/format';
import round from 'lodash/round';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import css from '../Logbook.scss';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import cn from 'classnames';
import {HtnResource} from '../res_htn';
import forEach from 'lodash/forEach';

const Htn = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const [bpReadings, setBpReadings] = useState([]);
    const [bp30DayStats, setBp30DayStats] = useState([]);
    const [bp90DayStats, setBp90DayStats] = useState([]);
    const userToken = useSelector(state => state.logBookAuth);
    const {sections} = useSelector(state => state.logBookControls);

    const formatBpReadings = readings => {
        const formattedRecords = [];

        forEach(readings, (reading, key) => {
            formattedRecords.push({
                bpDate: format(
                    new Date(get(reading, 'phoneConnectionTime')),
                    'EEE, MMM d'
                ),
                bpTime: format(
                    new Date(get(reading, 'phoneConnectionTime')),
                    'hh:mm a'
                ),
                bpSystolic: get(reading, 'systolic'),
                bpDiastolic: get(reading, 'diastolic'),
                heartRate: get(reading, 'heartRate'),
            });
        });

        return formattedRecords;
    };

    useEffect(() => {
        if (userToken.token) {
            HtnResource.getHtnReadings(90, userToken.token)
                .then(data => {
                    setBpReadings(formatBpReadings(data.readings));
                    setBp90DayStats(get(data, 'ninetyDayStats'));
                })
                .catch(
                    err => console.log("can't get 90 days stats", err) // eslint-disable-line no-console
                );
            HtnResource.getHtnReadings(30, userToken.token)
                .then(data => {
                    setBp30DayStats(get(data, 'monthlyStats'));
                })
                .catch(
                    err => console.log("can't get 30 days stats", err) // eslint-disable-line no-console
                );
        }
    }, [userToken.token]);

    // Checks if exists, because lodash's get will mark as false if value is zero, and we'll use the values even if they're zero
    const exists = (obj, property) => {
        return !isUndefined(get(obj, property)) && !isNull(get(obj, property));
    };

    const getSystolicStats = value => {
        const systolicStats = get(value, 'systolicStats');
        const avgSys = exists(systolicStats, 'avg')
            ? round(get(systolicStats, 'avg'), 0).toFixed(0)
            : null;

        return isNull(avgSys) ? '-' : avgSys;
    };

    const getDiastolicStats = value => {
        const diastolicStats = get(value, 'diastolicStats');
        const avgDia = exists(diastolicStats, 'avg')
            ? round(get(diastolicStats, 'avg'), 0).toFixed(0)
            : null;

        return isNull(avgDia) ? '-' : avgDia;
    };

    const getBpViewRows = () => {
        if (bpReadings.length === 0) {
            return (
                <tr className={css['logbook-table-row']}>
                    <td colSpan="9" className={css['no-data-row']}>
                        {t('noData')}
                    </td>
                </tr>
            );
        }

        return bpReadings.map((bp, index) => {
            return (
                <tr
                    key={`${index}-${bp.value}`}
                    className={css['logbook-table-row']}
                >
                    <td className={css['date-table']}>{bp.bpDate}</td>
                    <td className={css['data-table']}>{bp.bpTime}</td>
                    <td className={css['data-table']}>
                        {bp.bpSystolic}/{bp.bpDiastolic}
                    </td>
                    <td className={css['data-table']}>{bp.heartRate}</td>
                </tr>
            );
        });
    };

    return (
        <div
            className="row"
            style={{
                display: sections.htn ? '' : 'none',
            }}
        >
            <div
                className={cn(
                    css['horizontal-scroll'],
                    'panel',
                    css['meal-tag-view']
                )}
            >
                <div className="col-xs-12">
                    <div className={css['Logbook-section-content']}>
                        <p>
                            <b>{t('htn.headers.summary')}</b>
                        </p>

                        <div className={css['panel-body']}>
                            <div className={css['logbook-table-body']}>
                                <table
                                    className={cn(
                                        css.table,
                                        css['table-striped'],
                                        css['table-list-view']
                                    )}
                                >
                                    <thead
                                        className={css['logbook-table-header']}
                                    >
                                        <tr>
                                            <th className={css['date-table']} />
                                            <th className={css['date-table']}>
                                                {t('tableHeaders.htn.30days')}
                                            </th>
                                            <th className={css['date-table']}>
                                                {t('tableHeaders.htn.90days')}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t('htn.headers.avgBbIns')}
                                            </td>
                                            <td className={css['data-table']}>
                                                {getSystolicStats(
                                                    get(
                                                        bp30DayStats[0],
                                                        'overallStats'
                                                    )
                                                )}
                                                /
                                                {getDiastolicStats(
                                                    get(
                                                        bp30DayStats[0],
                                                        'overallStats'
                                                    )
                                                ) || '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {getSystolicStats(
                                                    get(
                                                        bp90DayStats[0],
                                                        'overallStats'
                                                    )
                                                )}
                                                /
                                                {getDiastolicStats(
                                                    get(
                                                        bp90DayStats[0],
                                                        'overallStats'
                                                    )
                                                ) || '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t(
                                                    'tableHeaders.htn.totalCheck'
                                                )}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    bp30DayStats[0],
                                                    'totalReadings'
                                                ) || '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    bp90DayStats[0],
                                                    'totalReadings'
                                                ) || '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t(
                                                    'tableHeaders.htn.above130mmHg'
                                                )}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    bp30DayStats[0],
                                                    'countsBPOutRange'
                                                ) || '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    bp90DayStats[0],
                                                    'countsBPOutRange'
                                                ) || '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t(
                                                    'tableHeaders.htn.below130mmHg'
                                                )}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    bp30DayStats[0],
                                                    'countsBPInRange'
                                                ) || '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    bp90DayStats[0],
                                                    'countsBPInRange'
                                                ) || '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <p>
                            <b>{t('htn.headers.bpReadings')}</b>
                        </p>

                        <div className={css['panel-body']}>
                            <table
                                className={cn(
                                    css.table,
                                    css['table-striped'],
                                    css['table-list-view']
                                )}
                            >
                                <thead className={css['logbook-table-header']}>
                                    <tr>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.htn.date')}
                                        </th>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.htn.time')}
                                        </th>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.htn.bp')}
                                        </th>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.htn.hr')}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>{getBpViewRows()}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Htn;
