import get from 'lodash/get';
import css from './Logbook.scss';
import cn from 'classnames';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import Arg from '@livongo/arg';
import {useState, useEffect} from 'react';
import isUndefined from 'lodash/isUndefined';

const SummaryView = prop => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const [localeCode, setLocaleCode] = useState(Arg('memberlocale'));
    const sum30Days = !isUndefined(prop.bgStatistics.sum30Days)
        ? prop.bgStatistics.sum30Days
        : null;

    const sum90Days = !isUndefined(prop.bgStatistics.sum90Days)
        ? prop.bgStatistics.sum90Days
        : null;

    useEffect(() => {
        if (
            localeCode === 'en-CA' ||
            localeCode === 'fr-CA' ||
            localeCode === 'es-US'
        ) {
            setLocaleCode('en-US');
        } else if (localeCode === 'en-ES') {
            setLocaleCode('es-ES');
        }
    }, [localeCode]);

    return (
        <div
            className="row"
            // style={{
            //     display: sections.htn ? '' : 'none',
            // }}
        >
            <div
                className={cn(
                    css['horizontal-scroll'],
                    'panel',
                    css['meal-tag-view']
                )}
            >
                <div className="col-xs-12">
                    <div className={css['Logbook-section-content']}>
                        <p>
                            <b>{t('summaryView.header')}</b>
                        </p>

                        <div className={css['panel-body']}>
                            <div className={css['logbook-table-body']}>
                                <table
                                    className={cn(
                                        css.table,
                                        css['table-striped'],
                                        css['table-list-view']
                                    )}
                                >
                                    <thead
                                        className={css['logbook-table-header']}
                                    >
                                        <tr>
                                            <th className={css['date-table']} />
                                            <th className={css['date-table']}>
                                                {t('summaryView.30Days')}
                                            </th>
                                            <th className={css['date-table']}>
                                                {t('summaryView.90Days')}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t('common.checks')}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    sum30Days,
                                                    'summary.totalChecks'
                                                )
                                                    ? get(
                                                          sum30Days,
                                                          'summary.totalChecks'
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    sum90Days,
                                                    'summary.totalChecks'
                                                )
                                                    ? get(
                                                          sum90Days,
                                                          'summary.totalChecks'
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t(
                                                    'summaryView.percentInRange'
                                                )}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(sum30Days, 'avg.value')
                                                    ? Math.round(
                                                          get(
                                                              sum30Days,
                                                              'avg.value'
                                                          )
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(sum90Days, 'avg.value')
                                                    ? Math.round(
                                                          get(
                                                              sum90Days,
                                                              'avg.value'
                                                          )
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t('summaryView.lowCount')}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    sum30Days,
                                                    'summary.veryLowCount'
                                                )
                                                    ? get(
                                                          sum30Days,
                                                          'summary.veryLowCount'
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    sum90Days,
                                                    'summary.veryLowCount'
                                                )
                                                    ? get(
                                                          sum90Days,
                                                          'summary.veryLowCount'
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t('summaryView.highCount')}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    sum30Days,
                                                    'summary.veryHighCount'
                                                )
                                                    ? get(
                                                          sum30Days,
                                                          'summary.veryHighCount'
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(
                                                    sum90Days,
                                                    'summary.veryHighCount'
                                                )
                                                    ? get(
                                                          sum90Days,
                                                          'summary.veryHighCount'
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t('summaryView.lowest')}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(sum30Days, 'low.value')
                                                    ? get(
                                                          sum30Days,
                                                          'low.value'
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(sum90Days, 'low.value')
                                                    ? get(
                                                          sum90Days,
                                                          'low.value'
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr
                                            className={css['logbook-table-row']}
                                        >
                                            <td className={css['date-table']}>
                                                {t('summaryView.highest')}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(sum30Days, 'high.value')
                                                    ? get(
                                                          sum30Days,
                                                          'high.value'
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={css['data-table']}>
                                                {get(sum90Days, 'high.value')
                                                    ? get(
                                                          sum90Days,
                                                          'high.value'
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryView;
