import {useState} from 'react';
import css from './FoodlogCarousel.scss';
import FoodlogCard from './FoodlogCard';
import RightArrow from '@livongo/icons/AngleRight';
import LeftArrow from '@livongo/icons/AngleLeft';

const FoodlogCarousel = ({slides}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    // note occasionally comes out as undefined
    const verifySlideData = slide => {
        const infoCopy = {...slide};

        if (!infoCopy.note) infoCopy.note = '';
        if (!infoCopy.imageUrl) infoCopy.imageUrl = '';

        return infoCopy;
    };

    const handleSlideChange = direction => {
        if (direction === 'next') {
            if (currentSlide < slides.length - 1) {
                setCurrentSlide(currentSlide + 1);
            }
        } else {
            if (currentSlide > 0) {
                setCurrentSlide(currentSlide - 1);
            }
        }
    };

    const getBullets = () => {
        return slides.map((slide, index) => {
            return (
                <div
                    key={index}
                    className={`${css['carousel-bullet']} ${
                        index === currentSlide ? css.active : ''
                    }`}
                    onClick={() => setCurrentSlide(index)}
                />
            );
        });
    };

    const getArrows = () => {
        return (
            <div className={css.arrows}>
                {currentSlide > 0 ? (
                    <div
                        className={css['left-arrow']}
                        onClick={() => handleSlideChange('prev')}
                    >
                        <LeftArrow />
                    </div>
                ) : (
                    ''
                )}
                {currentSlide < slides.length - 1 ? (
                    <div
                        className={css['right-arrow']}
                        onClick={() => handleSlideChange('next')}
                    >
                        <RightArrow />
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const getCarouselContent = () => {
        return (
            <div>
                {slides.length > 1 ? getArrows() : ''}
                <FoodlogCard info={verifySlideData(slides[currentSlide])} />
                {slides.length > 1 ? (
                    <div className={css['carousel-bullets']}>
                        {getBullets()}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    return getCarouselContent();
};

export default FoodlogCarousel;
