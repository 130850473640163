import get from 'lodash/get';
const mealTags = ['INVALID', 'BREAKFAST', 'LUNCH', 'DINNER', 'SNACK'];

function getAbsoluteTimestamp(date = new Date()) {
    const AbsoluteTimestamp = get(
        proto, // eslint-disable-line no-undef
        'com.livongo.protobuf.common.types.AbsoluteTimestamp'
    );
    const Timestamp = get(proto, 'google.protobuf.Timestamp'); // eslint-disable-line no-undef
    const absTimestamp = new AbsoluteTimestamp();
    const timestamp = new Timestamp();

    timestamp.setSeconds(String(Math.floor(date / 1000)));
    absTimestamp.setTimeStamp(timestamp);

    return absTimestamp;
}

// From stackoverflow https://stackoverflow.com/questions/37398871/javascript-get-current-date-using-utc-offset
function getDateWithUTCOffset(day, inputTzOffset) {
    const now = new Date(day); // get the current time
    const currentTzOffset = -now.getTimezoneOffset() / 60; // in hours, i.e. -4 in NY
    const deltaTzOffset = inputTzOffset - currentTzOffset; // timezone diff
    const nowTimestamp = now.getTime(); // get the number of milliseconds since unix epoch
    const deltaTzOffsetMilli = deltaTzOffset * 1000 * 60 * 60; // convert hours to milliseconds (tzOffsetMilli*1000*60*60)
    const outputDate = new Date(nowTimestamp + deltaTzOffsetMilli); // your new Date object with the timezone offset applied.

    return outputDate.getTime();
}

function getNormalizedTime(time) {
    return {
        timeStamp: time.getTimeStamp().getSeconds(),
        tzOffset: time.getTzOffset(),
        timeStampNoTimeZone: getDateWithUTCOffset(
            time.getTimeStamp().toDate(),
            time.getTzOffset() / 60
        ),
    };
}

function getMealTag(mealTag) {
    return mealTags[mealTag];
}

const Types = {
    getAbsoluteTimestamp,
    getNormalizedTime,
    getMealTag,
};

export default Types;
