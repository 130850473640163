import find from 'lodash/find';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import DOMUtils from '@livongo/utils/dom';
import StorageUtils from '@livongo/utils/storage';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import languages from './languages';

const BUILD_TAG_KEY = 'cp-build-tag';
const BUILD_TAG = process.env.BUILD_TAG;
const LANG_COOKIE = 'cp-lang';

export const defaultLang = find(languages, 'default').code;

export const getLanguage = () => {
    return StorageUtils.get({key: LANG_COOKIE}) || defaultLang;
};

export const setLanguage = language => {
    DOMUtils.el('html').lang = language;
};

export default function init({isDev, debug, lang}) {
    const userBuildTag = StorageUtils.get({key: BUILD_TAG_KEY});
    const appNamespace = 'app';
    const commonNamespace = 'common';
    const backends = [HttpApi];
    const backendOptions = [
        {
            loadPath: `${
                isDev ? '/' : process.env.ASSET_PATH
            }locales/{{lng}}/{{ns}}.json`,
        },
    ];

    if (!isDev) {
        // don't use local storage in dev so that adding new translations reloads the latest data and doesn't throw errors for unfound keys
        backends.unshift(LocalStorageBackend);
        backendOptions.unshift({
            prefix: 'livongo-cp-i18n-',
            store: window.sessionStorage,
        });

        // a deployment created a new build ID, lets clear out session storage to ensure we re-load the latest translations
        if (BUILD_TAG !== userBuildTag) {
            StorageUtils.clear('session');
            StorageUtils.set({key: BUILD_TAG_KEY, value: BUILD_TAG});
        }
    }

    setLanguage(lang);

    return i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            debug,
            backend: {
                backends,
                backendOptions,
            },
            ns: [appNamespace, commonNamespace],
            defaultNS: appNamespace,
            fallbackNS: commonNamespace,
            lng: lang,
            fallbackLng: defaultLang,
            interpolation: {
                escapeValue: false,
            },
        });
}
