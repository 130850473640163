import PropTypes from 'prop-types';
import React, {useState, createRef, useEffect} from 'react';
import isUndefined from 'lodash/isUndefined';
import css from './medication-reconciliation.scss';
import ActiveMedications from './ActiveMedications';
import InactiveMedications from './InactiveMedications';
import AddMedicationModal from './Modals/AddMedicationModal';
import MedicationForm from './MedicationForm';
import {useSelector} from 'react-redux';
import Panel from '@livongo/ui/Panel';
import cn from 'classnames';
import MedicationResource from '../resources/res_medication';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import MedOptModal from './MedOptModal';

const MedicationReconciliation = ({
    getMedication,
    reviewedToday,
    newMedicationReview,
    medOptData,
}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const medicationReview = useSelector(state => state.medicationReview);
    const medicationOptions = useSelector(state => state.medicationOptions);
    const {activeMedications, importedMedications} = useSelector(
        state => state.medicationsLists
    );
    const [activeList, setActiveList] = useState(activeMedications);
    const [importedList, setImportedList] = useState(importedMedications);
    const [show, setShow] = useState(false);
    const [openPanel, setOpenPanel] = useState(true);
    const panel = createRef();

    const togglePanel = () => {
        panel.current.toggle();
        setOpenPanel(!openPanel);
    };

    let initialReviewId = '';

    if (!isUndefined(medicationReview.initialReviewId))
        initialReviewId = medicationReview.initialReviewId;

    const activateImportedMed = med => {
        const medCopy = {...med};

        if (
            !medCopy.dose ||
            !medCopy.reportedAdherenceId ||
            !medCopy.directions ||
            !medCopy.startDate
        )
            return;
        medCopy.medSourceId = 2;

        for (const route in medicationOptions.medRoutes) {
            if (medicationOptions.medRoutes[route] === medCopy.medRoute) {
                medCopy.medRouteId = parseInt(route, 10);
                break;
            }
        }

        medicationOptions.medClassOptions.forEach(code => {
            if (medCopy.medClass === code.medClassCode)
                medCopy.medClassId = [parseInt(code.medClassId, 10)];
        });

        MedicationResource.makeMedicationActive(
            medicationReview.reviewId,
            null,
            medCopy
        ).then(() => {
            getMedication('', medicationReview.reviewId);
        });
    };

    useEffect(() => {
        setImportedList(importedMedications);
        setActiveList(activeMedications);
    }, [activeMedications, importedMedications]);

    return (
        <>
            <AddMedicationModal
                show={show}
                setShow={setShow}
                getMedication={getMedication}
                reviewId={medicationReview.reviewId}
            />
            <div className={css['medication-reconciliation']}>
                <div className={`row ${css['section-title']}`}>
                    <div
                        className={cn(
                            'col-xs-6',
                            css['section-title'],
                            'collapsed',
                            css['review-title']
                        )}
                        onClick={() => togglePanel()}
                    >
                        <span
                            className="glyphicon glyphicon-play"
                            style={{
                                transform: openPanel ? 'rotate(90deg)' : '',
                            }}
                        />{' '}
                        {t('reconciliation.medReview')}
                    </div>
                    <div className={`col-xs-6 ${css['last-reconciled-by']}`}>
                        <span className={css.reviewedBy}>
                            {medicationReview.lastReccBy &&
                                medicationReview.lastReccDate &&
                                `${t('reconciliation.lastReviewed')}
                            ${medicationReview.lastReccBy}
                            ${t('reconciliation.on')}
                            ${medicationReview.lastReccDate}`}
                        </span>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={newMedicationReview}
                            style={{
                                background: 'rgb(32, 158, 145)',
                                display:
                                    initialReviewId ===
                                    medicationReview.reviewId
                                        ? ''
                                        : 'none',
                            }}
                        >
                            {t('reconciliation.newReview')}
                        </button>
                        <button
                            id="medication-reviewed-today"
                            type="button"
                            className={cn(
                                'btn',
                                'btn-primary',
                                'active-inactive'
                            )}
                            disabled={
                                medicationReview.disabledFields.reconciledBy
                            }
                            onClick={() => reviewedToday(activeList)}
                            style={{
                                background: 'rgb(32, 158, 145)',
                                display:
                                    initialReviewId ===
                                    medicationReview.reviewId
                                        ? 'none'
                                        : '',
                            }}
                        >
                            {t('reconciliation.reviewedToday')}
                        </button>
                    </div>
                </div>
                <Panel
                    ref={panel}
                    className="Panel-container"
                    contentClassName="Panel-content"
                    isOpen
                >
                    {medOptData.length > 0 && (
                        <MedOptModal medOptData={medOptData} />
                    )}
                    <div
                        id="medication-imported-panel"
                        className="panel panel-default"
                        style={{fontSize: '12px'}}
                    >
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-xs-6">
                                    <h3 className="panel-title center-vertical">
                                        {t(
                                            'reconciliation.importedMedications.title'
                                        )}
                                    </h3>
                                </div>
                                <div className="col-xs-6 text-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        id="new_med_button"
                                        onClick={() => setShow(true)}
                                        style={{
                                            background: 'rgb(32, 158, 145)',
                                        }}
                                        disabled={
                                            medicationReview.disabledFields
                                                .tableField
                                        }
                                    >
                                        <i className="glyphicon glyphicon-plus" />
                                        {t(
                                            'reconciliation.importedMedications.newMed'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className={cn(
                                'panel-body',
                                'overlay',
                                'row',
                                css.tableHeader
                            )}
                        >
                            <div className={cn(css.flex, 'col-md-2')}>
                                <div className="col-md-10">
                                    {t(
                                        'reconciliation.importedMedications.name'
                                    )}
                                </div>
                                <div className="col-md-2">
                                    {t('headers.category')}
                                </div>
                            </div>
                            <div className={cn('col-md-6', css.middleHeader)}>
                                <div className="col-md-4">
                                    {t('headers.dose')}
                                </div>
                                <div className="col-md-2">
                                    {t('headers.routes')}
                                </div>
                                <div className="col-md-4">
                                    {t('headers.directions')}
                                </div>
                            </div>
                            <div className={cn('col-md-4', css.endHeader)}>
                                <div className="col-md-6">
                                    {t('headers.startDate')}
                                </div>
                                <div className="col-md-6">
                                    {t('headers.howOften')}
                                </div>
                            </div>
                        </div>
                        {importedMedications.map((med, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <MedicationForm
                                        med={med}
                                        index={index}
                                        medicationList={importedList}
                                        medicationOptions={medicationOptions}
                                        handleChange={setImportedList}
                                        btnFunc={() => activateImportedMed(med)}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <ActiveMedications
                        getMedication={getMedication}
                        activeMedications={activeList}
                        setActiveMedications={setActiveList}
                    />

                    <InactiveMedications />
                </Panel>
            </div>
        </>
    );
};

MedicationReconciliation.propTypes = {
    getMedication: PropTypes.func,
    reviewedToday: PropTypes.func,
    newMedicationReview: PropTypes.func,
    medOptData: PropTypes.array,
};

export default MedicationReconciliation;
