import APIUtils from '@livongo/utils/api';
import {config} from '../common/headers';

function getCoachId(sessionId) {
    return new Promise((resolve, reject) => {
        APIUtils.get(
            `${process.env.PROXY_URL}services/apexrest/CdeInfo/request/`,
            null,
            config('sessionId')
        )
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

function getCoachInfo(userId, sessionId) {
    return APIUtils.get(
        `${process.env.PROXY_URL}services/data/v43.0/query?q=SELECT%20FirstName%2CLastName%20FROM%20USER%20WHERE%20Id%3D'${userId}'`,
        null,
        config('sessionId')
    );
}

const SFResource = {
    getCoachId,
    getCoachInfo,
};

export default SFResource;
