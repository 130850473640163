const initialBGS = {
    listView: [],
    tagView: [],
    timeView: [],
    summaryView: [],
};

export default function bgsReducer(state = {...initialBGS}, {type, payload}) {
    switch (type) {
        case 'LIST_VIEW': {
            return {
                ...state,
                listView: payload,
            };
        }

        case 'TAG_VIEW': {
            return {
                ...state,
                tagView: payload,
            };
        }

        case 'TAG_SUMMARY': {
            return {
                ...state,
                tagSummary: payload,
            };
        }

        case 'TIME_VIEW': {
            return {
                ...state,
                timeView: payload,
            };
        }

        case 'TIME_SUMMARY': {
            return {
                ...state,
                timeSummary: payload,
            };
        }

        case 'SUMMARY_VIEW': {
            return {
                ...state,
                summaryView: payload,
            };
        }

        default:
            return state;
    }
}
