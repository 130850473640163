import React, {useState, useEffect, Fragment} from 'react';
import css from './medication-reconciliation.scss';
import InactiveModal from './Modals/InactiveModal';
import MedicationForm from './MedicationForm';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const ActiveMedications = ({
    getMedication,
    activeMedications,
    setActiveMedications,
}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const medications = useSelector(state => state.medicationsLists);
    const medicationOptions = useSelector(state => state.medicationOptions);
    const [selectedMed, setSelectedMed] = useState({});
    const [activeList, setActiveList] = useState([]);
    const [classMap, setClassMap] = useState({});
    const [show, setShow] = useState(false);

    const renderModal = med => {
        // Since the medication will already have start date and adherence option selected (which can't be set to empty)
        // We'll only check for dose and directions, since they can be set to empty
        if (med.dose && med.directions) {
            setSelectedMed(med);
            setShow(true);
        }
    };

    const getClassDisplay = id => {
        if (classMap[id]) {
            classMap[id] = false;

            return medicationOptions.medClassOptions[id - 1].medClassCode;
        }
    };

    // Separates medication lists by class and separates them by using the separator
    const getActiveMedicationsByClass = medClasses => {
        // DM = 1, HTN = 2, HL = 3, BH = 4, CKD = 5, HF = 6
        const sortedClasses = [
            medClasses[1],
            medClasses[2],
            medClasses[3],
            medClasses[4],
            medClasses[5],
            medClasses[6],
        ];
        // initialDrugClass used to find first group of drugs by class to them determine when to add separator
        let initialDrugClass = false;

        return sortedClasses.map((medClass, index) => {
            if (initialDrugClass && medClass.length) {
                return (
                    <Fragment key={`${index}-${medClass}`}>
                        <div className={cn(css['medication-separator'])} />
                        {medClass}
                    </Fragment>
                );
            }
            initialDrugClass = true;

            return medClass;
        });
    };

    const getActiveMedicationList = () => {
        if (!medicationOptions.medClassOptions || !activeMedications) {
            return '';
        }

        const medicationClass = {};

        medicationOptions.medClassOptions.forEach(option => {
            medicationClass[parseInt(option.medClassId, 10)] = [];
        });
        activeMedications.forEach((med, index) => {
            // Verifying medication's class id array has ids that belongs to classMap
            const medClassFound = med.medClassId.filter(
                id => id >= 1 && id <= 6
            );

            if (medClassFound.length) {
                medClassFound.forEach(id => {
                    medicationClass[id].push(
                        <React.Fragment key={`${med.id}active-${index}`}>
                            <MedicationForm
                                med={med}
                                index={index}
                                medId={id}
                                getClassDisplay={getClassDisplay}
                                medicationList={activeMedications}
                                medicationOptions={medicationOptions}
                                handleChange={setActiveMedications}
                                btnFunc={renderModal}
                            />
                        </React.Fragment>
                    );
                });
            }
        });

        setActiveList(getActiveMedicationsByClass(medicationClass));
    };

    useEffect(() => {
        // medication class string will be displayed only at first of each class, as they'll be grouped together by class
        const newClassMap = {};

        medicationOptions.medClassOptions?.forEach(option => {
            newClassMap[parseInt(option.medClassId, 10)] = true;
        });
        setClassMap(newClassMap);
        // after updating class id map, regenerate the active medication list
        getActiveMedicationList();
    }, [medications, medicationOptions.medClassOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            id="medication-active-panel"
            className="panel panel-default"
            style={{fontSize: '12px'}}
        >
            <div className="panel-heading">
                <div className="row">
                    <div className="col-xs-6">
                        <h3 className="panel-title center-vertical">
                            {t('reconciliation.activeMedications.title')}
                        </h3>
                    </div>
                    {/* <!--<div className="col-xs-6 last-reconciled-by">-->
                <!--<button type="button" className="btn btn-primary" ng-click="saveMedication()">Save Active Medications</button>-->
                <!--</div>--> */}
                </div>
            </div>
            <div
                className={cn('panel-body', 'overlay', 'row', css.tableHeader)}
            >
                <div className="col-md-2">
                    <div className="col-md-9">{t('headers.name')}</div>
                    <div className="col-md-3">{t('headers.category')}</div>
                </div>
                <div className={cn('col-md-6', css.middleHeader)}>
                    <div className="col-md-4">{t('headers.dose')}</div>
                    <div className="col-md-2">{t('headers.routes')}</div>
                    <div className="col-md-4">{t('headers.directions')}</div>
                </div>
                <div className={cn('col-md-4', css.endHeader)}>
                    <div className="col-md-6">{t('headers.startDate')}</div>
                    <div className="col-md-6">{t('headers.howOften')}</div>
                </div>
            </div>
            {activeList}
            <InactiveModal
                show={show}
                setShow={setShow}
                selectedMed={selectedMed}
                getMedication={getMedication}
            />
        </div>
    );
};

ActiveMedications.propTypes = {
    activeMedications: PropTypes.array,
    setActiveMedications: PropTypes.func,
    getMedication: PropTypes.func,
};

export default ActiveMedications;
