import {config} from '../common/headers';
import APIUtils from '@livongo/utils/api';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

const labA1C = authToken => {
    return new Promise((resolve, reject) => {
        APIUtils.get(
            `${process.env.gRPC_URL}/service-athome-labs/users/me/labOrders/results?testType=HbA1c`,
            null,
            config(authToken)
        )
            .then(data => {
                const readings = data ? get(data, 'data') : [];

                if (!isEmpty(readings)) {
                    forEach(readings, (reading, key) => {
                        readings[key].vendorName = 'Lab-reported';
                    });
                }
                resolve({
                    readings,
                });
            })
            .catch(err => console.log(err));
    });
};

const selfA1C = authToken => {
    return new Promise((resolve, reject) => {
        let selfReading = {};

        APIUtils.get('/v1/users/me/info/health', null, config(authToken))
            .then(data => {
                const selfData = find(data.data, {id: 'a1c'})
                    ? find(data.data, {id: 'a1c'})
                    : '';

                if (selfData.value && selfData.value > 0) {
                    selfReading = {
                        testType: 'A1c',
                        timestamp: selfData.valueTime
                            ? selfData.valueTime
                            : selfData.modified,
                        vendorName: 'Self-reported',
                        quantitativeValue: selfData.value
                            ? `${selfData.value}%`
                            : '',
                    };
                }
                resolve({
                    selfReading,
                });
            })
            .catch(err => console.log(err));
    });
};

const labResults = authToken => {
    return new Promise((resolve, reject) => {
        APIUtils.get(
            `${process.env.gRPC_URL}/service-athome-labs/users/me/results`,
            null,
            config(authToken)
        )
            .then(data => {
                const readings = data ? get(data, 'data') : [];

                if (!isEmpty(readings)) {
                    forEach(readings, (reading, key) => {
                        readings[key].vendorName = 'Lab-reported';
                    });
                }
                resolve({
                    readings,
                });
            })
            .catch(err => console.log(err));
    });
};

const filterLabResults = readings => {
    const filterResults = [];

    if (readings.length > 0) {
        readings.map((item, index) =>
            forEach(item, (value, key) => {
                if (
                    !(
                        key === 'orderId' ||
                        key === 'resultTimestamp' ||
                        key === 'creatinine'
                    )
                ) {
                    const filterData = {};

                    filterData.testType =
                        key === 'HbA1c' ? 'A1c' : key === 'UACR' ? 'uACR' : key;
                    filterData.timestamp = item.resultTimestamp;
                    filterData.vendorName = 'Lab-reported';
                    filterData.quantitativeValue =
                        value.value +
                        (key !== 'HbA1c' ? ' ' : '') +
                        (key === 'eGFR' ? 'mL/min' : value.unit);
                    filterResults.push(filterData);
                }
            })
        );
    }

    return filterResults;
};

export const A1CLabResults = {
    labA1C,
    selfA1C,
    labResults,
    filterLabResults,
};
