import {useState, useEffect} from 'react';
import forEach from 'lodash/forEach';
import cn from 'classnames';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import {UserResource} from '../res_user';
import {A1CLabResults} from '../../coaching/A1CLabResults';
import format from 'date-fns/format';
import css from '../Logbook.scss';
import find from 'lodash/find';

const LabResults = prop => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const [a1cReport, setA1cReport] = useState([]);
    const authToken = prop.authToken;

    useEffect(() => {
        if (authToken) {
            let filterResults = [];

            Promise.all([
                A1CLabResults.labResults(authToken),
                UserResource.getHealthProfileSchema(authToken),
            ])
                .then(([items, selfReport]) => {
                    if (items?.readings) {
                        forEach(items.readings, (reading, key) => {
                            filterResults = [
                                ...filterResults,
                                ...A1CLabResults.filterLabResults(reading),
                            ];
                        });
                    }

                    if (selfReport) {
                        const a1c = find(selfReport, {id: 'a1c'})
                            ? find(selfReport, {id: 'a1c'})
                            : '';

                        if (a1c && a1c.value > 0) {
                            const selfData = {
                                testType: 'A1c',
                                timestamp: a1c.valueTime
                                    ? a1c.valueTime
                                    : a1c.modified,
                                vendorName: 'Self-reported',
                                quantitativeValue: a1c.value
                                    ? `${a1c.value}%`
                                    : '',
                            };

                            if (selfData) filterResults.push(selfData);
                        }
                    }

                    if (filterResults.length > 0) setA1cReport(filterResults);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

    const labViewRows = () => {
        if (a1cReport.length > 0) {
            a1cReport.sort(
                (a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp)
            );
        }
        if (a1cReport.length === 0) {
            return (
                <tr className={css['logbook-table-row']}>
                    <td colSpan="9" className={css['no-data-row']}>
                        {t('noData')}
                    </td>
                </tr>
            );
        } else {
            return a1cReport.map(item => {
                return (
                    <tr
                        key={`${item.quantitativeValue}-${item.timestamp}`}
                        className={css['logbook-table-row']}
                    >
                        <td>{item.testType}</td>
                        <td>{item.quantitativeValue}</td>
                        <td>{item.vendorName}</td>
                        <td>
                            {format(new Date(item.timestamp), 'MMM d, yyyy')}
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <div className="row">
            <div
                className={cn(
                    css['horizontal-scroll'],
                    'panel',
                    css['meal-tag-view']
                )}
            >
                <div className="col-xs-12">
                    <div className={css['Logbook-section-content']}>
                        <p>
                            <b>{t('listView.header')}</b>
                        </p>
                        <div className={css['panel-body']}>
                            <table
                                className={cn(
                                    css.table,
                                    css['table-striped'],
                                    css['table-list-view']
                                )}
                            >
                                <thead className={css['logbook-table-header']}>
                                    <tr>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.lab.type')}
                                        </th>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.lab.value')}
                                        </th>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.lab.vendor')}
                                        </th>
                                        <th className={css['date-table']}>
                                            {t('tableHeaders.lab.date')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{labViewRows()}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LabResults;
