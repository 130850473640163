import {useState, useEffect} from 'react';
import css from './Coaching.scss';
import Table from '@livongo/ui/Table';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import parse from 'date-fns/parse';
import {MedService} from './MedService';
/* disabling lab results for now - as per the ticket req - https://jira.teladoc.net/browse/CP-1785 */
/* import {LabResultsService} from './LabResultService'; */
import cn from 'classnames';
import APIUtils from '@livongo/utils/api';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import {A1CLabResults} from './A1CLabResults';
import {intlFormat} from 'date-fns';
import format from 'date-fns/format';

const Coaching = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {authToken, pid, sessionId} = Arg.all();
    const [data, setData] = useState({
        coachingCampaign: t('initialState.no'),
        dateEligible: 'N/A',
        reason: 'N/A',
        coachingActivity: false,
        hasA1CLabResult: false,
        hasLDLLabResult: false,
        hasLabResult: false,
        flagCoachingType: '',
        medCoachingDateEligible: 'N/A',
        medCoachingReason: t('initialState.medCoachingReason'),
        medSignalForCoaching: t('initialState.no'),
        previousPage: 0,
        pageSize: 5,
        flagType: t('initialState.no'),
        createdUtc: 'N/A',
    });

    const code = Arg('memberlocale');
    const [localeCode, setLocaleCode] = useState(Arg('memberlocale'));
    const notIncludedLabResultsCode = [
        'en-ES',
        'es-ES',
        'ca-ES',
        'fr-CA',
        'en-CA',
    ];
    const showLabResults = !notIncludedLabResultsCode.includes(code);
    const [coachingRecords, setCoachingRecords] = useState([]);
    const [labResults, setLabResults] = useState({currentPage: 0});
    const errorDisplay = err => {
        console.log(err); // eslint-disable-line no-console
    };

    const getSegmentationInfo = () => {
        if (
            localeCode === 'en-CA' ||
            localeCode === 'fr-CA' ||
            localeCode === 'es-US'
        ) {
            setLocaleCode('en-US');
        } else if (localeCode === 'en-ES') {
            setLocaleCode('es-ES');
        }

        let reason = data.reason;

        APIUtils.get(`/v1/users/${pid}/coaching/segmentation`)
            .then(({data: segmentationData}) => {
                if (get(segmentationData, 'hasChkNoneAndA1cHigh')) {
                    reason = t('reasonList.group1');
                } else if (get(segmentationData, 'hasChkLowAndBgHigh')) {
                    reason = t('reasonList.group2');
                } else if (get(segmentationData, 'hasChkMedAndBgHigh')) {
                    reason = t('reasonList.group3');
                } else if (get(segmentationData, 'hasDbDiagRecent')) {
                    reason = t('reasonList.group4');
                }
                const coachingCampaign =
                    get(segmentationData, 'score') > 0
                        ? t('initialState.yes')
                        : t('initialState.no');
                const dateEligible =
                    isEqual(coachingCampaign, 'No') || !segmentationData
                        ? 'N/A'
                        : intlFormat(
                              parse(
                                  get(segmentationData, 'dateUtc'),
                                  'yyyy-MM-dd',
                                  new Date()
                              ),
                              {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                              },
                              {
                                  locale: localeCode,
                              }
                          );

                reason = isEqual(coachingCampaign, 'No') ? 'N/A' : reason;
                setData(prevState => {
                    return {
                        ...prevState,
                        coachingCampaign,
                        dateEligible,
                        reason,
                    };
                });
            })
            .catch(err => errorDisplay(err));
    };

    const getCoachingActivityInfo = () => {
        if (
            localeCode === 'en-CA' ||
            localeCode === 'fr-CA' ||
            localeCode === 'es-US'
        ) {
            setLocaleCode('en-US');
        } else if (localeCode === 'en-ES') {
            setLocaleCode('es-ES');
        }

        const config = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${sessionId}`,
                'Content-Type': 'application/json',
            },
        };
        const url = `${
            `${process.env.PROXY_URL}services` +
            '/data/v43.0/query?q=SELECT%20Case_type__c%2C%20Status%2C%20Coaching_Session_date__c%2C%20AssingedTo__r.Name%20FROM%20Case%20WHERE%20PID__c%20%3D%20%27'
        }${pid}%27%20AND%20%28Case_type__c%20%3D%20%27Closed%20Comprehensive%20Coaching%20Case%20Session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Follow-up%20Coaching%20Case%20Session%27%20OR%20Case_type__c%20%3D%20%27Comprehensive%20Coaching%20Case%20Session%27%20OR%20Case_type__c%20%3D%20%27Follow-up%20Coaching%20Case%20Session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Comprehensive%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Comprehensive%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Follow-Up%20Coaching%20Session%27%20OR%20Case_type__c%20%3D%20%27Follow-up%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Welcome%20Call%27%20OR%20Case_type__c%20%3D%20%27Closed%20Welcome%20Call%27%20OR%20Case_type__c%20%3D%20%27Closed%20Follow-up%20DM%20and%20HTN%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Follow-up%20DM%20and%20HTN%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Comprehensive%20DM%20And%20HTN%20Coaching%20Session%27%20OR%20Case_type__c%20%3D%20%27Comprehensive%20DM%20and%20HTN%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Follow-up%20nutrition%20session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Follow-up%20nutrition%20session%27%20OR%20Case_type__c%20%3D%20%27Comprehensive%20nutrition%20session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Comprehensive%20nutrition%20session%27%20OR%20Case_type__c%20%3D%20%27Outbound%20Coach%20Call%27%20OR%20Case_type__c%20%3D%20%27Closed%20Outbound%20Coach%20Call%27%20OR%20Case_type__c%20%3D%20%27Comprehensive%20HTN%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Follow-up%20HTN%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Comprehensive%20HTN%20coaching%20session%27%20OR%20Case_type__c%20%3D%20%27Closed%20Follow-up%20HTN%20coaching%20session%27%29%20ORDER%20BY%20Coaching_Session_date__c`;

        APIUtils.get(url, null, config)
            .then(response => {
                const records = get(response, 'data.records');
                const formattedRecords = [];

                forEach(records, (record, key) => {
                    formattedRecords.unshift({
                        caseType: get(record, 'Case_type__c'),
                        cdeName: get(record, 'AssingedTo__r.Name'),
                        status: get(record, 'Status'),
                        date: intlFormat(
                            new Date(get(record, 'Coaching_Session_date__c')),
                            {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            },
                            {
                                locale: localeCode,
                            }
                        ),
                    });
                });
                setCoachingRecords(formattedRecords);
            })
            .catch(err => errorDisplay(err));
    };

    const getCoachingSignals = () => {
        MedService.getCoachingSignals(authToken)
            .then(list => {
                if (!isEmpty(keys(list[0]))) {
                    const flagCoachingType = list[0].flagCoachingType;
                    const medSignalForCoaching =
                        flagCoachingType === 2
                            ? t('initialState.yes')
                            : t('initialState.no');

                    setData(prevState => {
                        return {
                            ...prevState,
                            flagCoachingType,
                            medSignalForCoaching,
                        };
                    });
                }
            })
            .catch(err => errorDisplay(err));
    };

    const getStatinSignal = () => {
        MedService.getStatinSignal(authToken)
            .then(list => {
                if (!isEmpty(list[0])) {
                    const flagType =
                        list[0].flagType === 'MED_ADJUSTMENT' &&
                        !isUndefined(list[0].flagType)
                            ? t('initialState.yes')
                            : t('initialState.no');
                    const createdUtc = list[0].createdUtc;

                    setData(prevState => {
                        return {...prevState, flagType, createdUtc};
                    });
                }
            })
            .catch(err => errorDisplay(err));
    };

    const getLabResults = (
        currentPage = 0,
        // LN test
        nextPage2 = 2
        // nextPage2 = labResults.nextPage
    ) => {
        /* disabling lab results for now - as per the ticket req - https://jira.teladoc.net/browse/CP-1785 */
        /* LabResultsService.getLabResults(authToken, nextPage2, data.pageSize)
            .then(records => {
                if (!isEmpty(records.labResults)) {
                    const hasLDLLabResult = true;
                    const previousPage = nextPage2
                        ? Math.abs(nextPage2 - data.pageSize)
                        : -1;
                    const LDLResults = records.labResults;
                    const nextPage = records.nextPageToken;

                    setLabResults({
                        ...labResults,
                        hasLDLLabResult,
                        previousPage,
                        LDLResults,
                        nextPage,
                        currentPage,
                    });
                }
            })
            .catch(err => errorDisplay(err)); */
    };

    const changePage = direction => {
        if (direction === 'next') {
            getLabResults(labResults.currentPage + 1, labResults.nextPage);
        } else {
            getLabResults(
                labResults.currentPage - 1,
                labResults.previousPage.toString()
            );
        }
    };

    const getLabResultLinks = () => {
        const links = [];

        if (labResults.nextPage) {
            links.push(
                <span
                    className={cn(css['fake-link'], css['float-right'])}
                    onClick={() => changePage('next')}
                >
                    {t('labNavigation.next')}&nbsp;&gt;&gt;
                </span>
            );
        }
        if (labResults.currentPage > 0 && labResults.nextPage) {
            links.push(
                <span
                    className={cn(
                        css['fake-link'],
                        css['float-right'],
                        css['page-devide']
                    )}
                >
                    {' '}
                    |{' '}
                </span>
            );
        }
        if (labResults.currentPage > 0) {
            links.push(
                <span
                    className={cn(css['fake-link'], css['float-right'])}
                    onClick={() => changePage('previous')}
                >
                    &lt;&lt;&nbsp;{t('labNavigation.prev')}
                </span>
            );
        }

        return links;
    };

    const getA1C = async () => {
        let results = [];

        if (authToken) {
            await A1CLabResults.selfA1C(authToken)
                .then(e => {
                    if (!isEmpty(e.selfReading)) {
                        results.push(e.selfReading);
                    }
                })
                .catch(err => console.log(err));

            await A1CLabResults.labResults(authToken)
                .then(items => {
                    if (items?.readings) {
                        forEach(items.readings, (reading, key) => {
                            results = [
                                ...results,
                                ...A1CLabResults.filterLabResults(reading),
                            ];
                        });
                    }
                })
                .catch(err => console.log(err));

            if (results.length > 0) {
                const hasA1CLabResult = true;

                results.sort(
                    (a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp)
                );
                setLabResults({
                    ...labResults,
                    hasA1CLabResult,
                    results,
                });
            }
        }
    };

    const getLabResultsComponent = () => {
        if (!labResults.hasA1CLabResult && !labResults.hasLDLLabResult) {
            return (
                <div className="panel-body">
                    <p>{t('noData')}</p>
                </div>
            );
        } else {
            return (
                <table className="table">
                    <thead>
                        <tr className={css['table-text']}>
                            <th>{t('tableHeaders.lab.type')}</th>
                            <th>{t('tableHeaders.lab.value')}</th>
                            <th>{t('tableHeaders.lab.vendor')}</th>
                            <th>{t('tableHeaders.lab.date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {labResults.hasLDLLabResult ? (
                            labResults.LDLResults.map(item => {
                                return (
                                    <tr
                                        key={`${item.ldlValue}-${item.collectedDate}`}
                                        className={css['table-text']}
                                    >
                                        <td>LDL-C</td>
                                        <td>{item.ldlValue} (mg/dL)</td>
                                        <td />
                                        <td>{item.collectedDate}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr />
                        )}
                        {labResults.hasA1CLabResult ? (
                            labResults.results.map(item => {
                                return (
                                    <tr
                                        key={`${item.quantitativeValue}-${item.timestamp}`}
                                        className={css['table-text']}
                                    >
                                        <td>{item.testType}</td>
                                        <td>{item.quantitativeValue}</td>
                                        <td>{item.vendorName}</td>
                                        <td>
                                            {format(
                                                new Date(item.timestamp),
                                                'MMM d, yyyy'
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr />
                        )}
                    </tbody>
                </table>
            );
        }
    };

    useEffect(() => {
        if (!isUndefined(pid) && !isEmpty(pid)) {
            getSegmentationInfo();
            getCoachingActivityInfo();
        }
        getCoachingSignals();
        getStatinSignal();
        if (showLabResults) {
            getA1C();
        }
        getLabResults();
    }, [localeCode, showLabResults]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`container-fluid main-container ${css.coaching}`}>
            <h1>{t('headers.coaching')}</h1>
            <div className="column-group">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h2>{t('headers.coaching')}</h2>
                    </div>
                    <Table
                        className={css.table}
                        columns={[
                            {
                                Header: t('tableHeaders.coaching.signal'),
                                accessor: 'signalCoaching',
                                className: css.head_32,
                            },
                            {
                                Header: t('tableHeaders.coaching.dateEligible'),
                                accessor: 'dateEligible',
                                className: css.head,
                            },
                            {
                                Header: t('tableHeaders.coaching.reason'),
                                accessor: 'reason',
                                className: css.head,
                            },
                            {
                                Header: t(
                                    'tableHeaders.coaching.additionalInfo'
                                ),
                                accessor: 'additionalInfo',
                                className: css.head,
                            },
                        ]}
                        data={
                            showLabResults
                                ? [
                                      {
                                          signalCoaching: data.coachingCampaign,
                                          dateEligible: data.dateEligible,
                                          reason: data.reason,
                                          additionalInfo: 'N/A',
                                      },
                                      {
                                          signalCoaching:
                                              data.medSignalForCoaching,
                                          dateEligible:
                                              data.medCoachingDateEligible,
                                          reason: data.medCoachingReason,
                                          additionalInfo: 'N/A',
                                      },
                                      {
                                          signalCoaching: data.flagType,
                                          dateEligible: data.createdUtc,
                                          reason: t(
                                              'initialState.statinEligible'
                                          ),
                                          additionalInfo: t(
                                              'initialState.additionalInfo'
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          signalCoaching: data.coachingCampaign,
                                          dateEligible: data.dateEligible,
                                          reason: data.reason,
                                          additionalInfo: 'N/A',
                                      },
                                  ]
                        }
                    />
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h2>{t('headers.activity')}</h2>
                    </div>
                    {coachingRecords.length > 0 ? (
                        <Table
                            className={css.table}
                            noDataAvailableMessage="None"
                            columns={[
                                {
                                    Header: t('tableHeaders.activity.caseType'),
                                    accessor: 'caseType',
                                    className: css.head_50,
                                },
                                {
                                    Header: t('tableHeaders.activity.cde'),
                                    accessor: 'cdeName',
                                    className: css.head_25,
                                },
                                {
                                    Header: t('tableHeaders.activity.status'),
                                    accessor: 'status',
                                    className: css.head,
                                },
                                {
                                    Header: t('tableHeaders.activity.date'),
                                    accessor: 'date',
                                    className: css.head,
                                },
                            ]}
                            data={coachingRecords}
                        />
                    ) : (
                        <div className="panel-body">
                            <p>{t('noData')}</p>
                        </div>
                    )}
                </div>

                {showLabResults && (
                    <div className="panel panel-default">
                        <div className={cn(css.links, 'panel-heading')}>
                            <h2>{t('headers.lab')}</h2>
                            <div>{getLabResultLinks()}</div>
                        </div>
                        {getLabResultsComponent()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Coaching;
