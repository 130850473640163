import css from './medication.scss';
import {useRouteMatch} from 'react-router-dom';
import htmlStringReactParser from 'html-react-parser';
import useTransLoader from '../i18n/use-trans-loader';

const Medication = () => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);

    const tableHeaderNames = {
        bloodPressureAndCategories: ['category', 'systolic', 'diastolic'],
        medications: [
            'medicationClass',
            'medications',
            'action',
            'sideEffects',
        ],
        ckdManagement: ['units', 'recommendedManagement'],
        ckdStages: ['stage', 'description', 'eGFR'],
        insulin: [
            'classification',
            'genericName',
            'brandName',
            'onset',
            'peak',
            'duration',
            'color',
        ],
    };

    const tableHeaderPaths = {
        bloodPressureAndCategories: 'tableHeaders.bloodPressureAndCategories',
        medications: 'tableHeaders.medications',
        ckdManagement: 'tableHeaders.ckdManagement',
        ckdStages: 'tableHeaders.ckdStages',
        insulin: 'tableHeaders.insulin',
    };

    const getTableHeaderByTableName = tableName => {
        const headerNames =
            tableName in tableHeaderNames ? tableHeaderNames[tableName] : [];
        const headerPath =
            tableName in tableHeaderPaths ? tableHeaderPaths[tableName] : '';
        const tableHeaderKeys = [];

        headerNames.map(headerName =>
            tableHeaderKeys.push({key: t(`${headerPath}.${headerName}`)})
        );

        return (
            <tr className={css['table-text']}>
                {tableHeaderKeys.map(header => {
                    return <th key={header.key}>{header.key}</th>;
                })}
            </tr>
        );
    };

    // TODO: Figure out translations for strings with units
    const getTableHeaderByKeys = tableHeaderKeys => {
        return (
            <tr className={css['table-text']}>
                {tableHeaderKeys.map(headerKey => {
                    return (
                        <th key={headerKey}>
                            {htmlStringReactParser(headerKey)}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const CKDManagementTable = () => {
        const tableHeaderKeys = [
            'eGFR(mL/min/1.73 m<sup>2</sup>)',
            'Recommended management',
        ];

        const getTableBody = () => {
            const formattedTableData = [];
            const tableDataPath = 'tableData.ckdManagement';
            const tableDataKeys = [
                'allPatients',
                'fortyToSixty',
                'thirtyToFortiFour',
                'lessThanThirty',
            ];

            tableDataKeys.map(key =>
                formattedTableData.push({
                    units: t(`${tableDataPath}.${key}.units`),
                    recommendedManagement: t(
                        `${tableDataPath}.${key}.recommendedManagement`
                    ),
                })
            );

            return formattedTableData.length > 0 ? (
                formattedTableData.map(record => {
                    return (
                        <tr
                            key={`${record.units}-${record.recommendedManagement}`}
                        >
                            <td>{record.units}</td>
                            <td>{record.recommendedManagement}</td>
                        </tr>
                    );
                })
            ) : (
                <tr />
            );
        };

        return (
            <div>
                <table>
                    <thead>{getTableHeaderByKeys(tableHeaderKeys)}</thead>
                    <tbody>{getTableBody()}</tbody>
                </table>
            </div>
        );
    };

    const CKDStagesTable = () => {
        const tableHeaderKeys = [
            'Stage',
            'Description',
            'eGFR(mL/min/1.73 m<sup>2</sup>)',
        ];

        const getTableBody = () => {
            const formattedTableData = [];
            const tableDataPath = 'tableData.ckdStages';
            const tableDataKeys = ['one', 'two', 'three', 'four', 'five'];

            tableDataKeys.map(key =>
                formattedTableData.push({
                    stage: t(`${tableDataPath}.${key}.stage`),
                    description: t(`${tableDataPath}.${key}.description`),
                    eGFR: t(`${tableDataPath}.${key}.eGFR`),
                })
            );

            return formattedTableData.length > 0 ? (
                formattedTableData.map(record => {
                    return (
                        <tr
                            key={`${record.stage}-${record.description}-${record.eGFR}`}
                        >
                            <td>{record.stage}</td>
                            <td>{record.description}</td>
                            <td>{record.eGFR}</td>
                        </tr>
                    );
                })
            ) : (
                <tr />
            );
        };

        return (
            <div>
                <table>
                    <thead>{getTableHeaderByKeys(tableHeaderKeys)}</thead>
                    <tbody>
                        {getTableBody()}
                        <tr className={css['text-bold']}>
                            <td colSpan="3">{t('tableData.ckdStages.last')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const BloodPressureAndCategoriesTable = () => {
        const getTableBody = () => {
            const formattedTableData = [];
            const tableDataPath = 'tableData.bloodPressureAndCategories';
            const tableDataKeys = [
                'normal',
                'elevated',
                'highBloodPressure',
                'stage1',
                'stage2',
            ];

            tableDataKeys.map(key =>
                formattedTableData.push({
                    category: t(`${tableDataPath}.${key}.category`),
                    systolic: t(`${tableDataPath}.${key}.systolic`),
                    diastolic: t(`${tableDataPath}.${key}.diastolic`),
                })
            );

            return formattedTableData.length > 0 ? (
                formattedTableData.map(record => {
                    return (
                        <tr
                            key={`${record.category}-${record.systolic}-${record.diastolic}`}
                        >
                            <td>{record.category}</td>
                            <td>{record.systolic}</td>
                            <td>{record.diastolic}</td>
                        </tr>
                    );
                })
            ) : (
                <tr />
            );
        };

        return (
            <div>
                <table>
                    <thead>
                        {getTableHeaderByTableName(
                            'bloodPressureAndCategories'
                        )}
                    </thead>
                    <tbody>{getTableBody()}</tbody>
                </table>
            </div>
        );
    };

    const MedicationsTable = () => {
        const getTableBody = () => {
            const formattedTableData = [];
            const tableDataPath = 'tableData.medications';
            const tableDataKeys = [
                'thiazideDiuretic',
                'aceInhibitors',
                'arbs',
                'betaBlockers',
                'vasodilators',
                'calciumChannelBlockers',
                'aldosteroneAntagonists',
                'alpha2Agonists',
                'alphaBlockers',
            ];

            tableDataKeys.map(key =>
                formattedTableData.push({
                    medicationClass: t(
                        `${tableDataPath}.${key}.medicationClass`
                    ),
                    medications: t(`${tableDataPath}.${key}.medications`),
                    action: t(`${tableDataPath}.${key}.action`),
                    sideEffects: t(`${tableDataPath}.${key}.sideEffects`),
                })
            );

            return formattedTableData.length > 0 ? (
                formattedTableData.map(record => {
                    return (
                        <tr
                            key={`${record.medicationClass}-${record.medications}-${record.action}-${record.sideEffects}`}
                            className={css['table-text']}
                        >
                            <td>{record.medicationClass}</td>
                            <td>{record.medications}</td>
                            <td>{record.action}</td>
                            <td>{record.sideEffects}</td>
                        </tr>
                    );
                })
            ) : (
                <tr />
            );
        };

        return (
            <div>
                <table>
                    <thead>{getTableHeaderByTableName('medications')}</thead>
                    <tbody>{getTableBody()}</tbody>
                </table>
            </div>
        );
    };

    const InsulinTable = () => {
        return (
            <table className={css['insulin-table']}>
                <tbody>
                    <tr>
                        <td>Classification</td>
                        <td>Generic Name</td>
                        <td>Brand Name</td>
                        <td>Onset</td>
                        <td>Peak</td>
                        <td>Duration</td>
                        <td>Color</td>
                    </tr>
                    <tr className={css['line-dark-blue']}>
                        <td>Rapid-Acting</td>
                        <td>
                            aspart
                            <br />
                            glulisine
                            <br />
                            lispro
                        </td>
                        <td>
                            Novolog
                            <br />
                            Apidra
                            <br />
                            Humalog
                        </td>
                        <td>5-15 minutes</td>
                        <td>1-2 hours</td>
                        <td>4-6 hours</td>
                        <td>Clear</td>
                    </tr>
                    <tr className={css['line-green']}>
                        <td>Short-Acting</td>
                        <td>
                            regular,
                            <br />
                            human
                        </td>
                        <td>
                            Humulin R
                            <br />
                            Novolin R
                            <br />
                            Relion/Novolin R
                        </td>
                        <td>30-60 minutes</td>
                        <td>2-4 hours</td>
                        <td>6-10 hours</td>
                        <td>Clear</td>
                    </tr>
                    <tr className={css['line-yellow']}>
                        <td>Intermediate-Acting</td>
                        <td>
                            isophane,
                            <br />
                            human
                            <br />
                            (NPH)
                        </td>
                        <td>
                            Humulin N
                            <br />
                            Novolin N
                            <br />
                            Relion/Novolin N
                        </td>
                        <td>1-2 hours</td>
                        <td>4-8 hours</td>
                        <td>10-18 hours</td>
                        <td>Cloudy</td>
                    </tr>
                    <tr className={css['line-red']}>
                        <td>Long-Acting</td>
                        <td>
                            detemir
                            <br />
                            glargine
                        </td>
                        <td>
                            Levemir
                            <br />
                            Lantus
                        </td>
                        <td>1-2 hours</td>
                        <td>No peak</td>
                        <td>Up to 24 hours</td>
                        <td>Clear</td>
                    </tr>
                    <tr className={css['line-red']}>
                        <td>Long-Acting</td>
                        <td>glargine</td>
                        <td>Toujeo</td>
                        <td>6 hours</td>
                        <td>No peak</td>
                        <td>24-36 hours</td>
                        <td> </td>
                    </tr>
                    <tr className={css['line-purple']}>
                        <td>Premixed Insulins**</td>
                        <td>
                            Novolin 70/30
                            <br />
                            Humulin 70/30
                        </td>
                        <td> </td>
                        <td>30-60 minutes</td>
                        <td>2-10 hours</td>
                        <td>10-18 hours</td>
                        <td> </td>
                    </tr>
                    <tr className={css['line-purple']}>
                        <td>Premixed Insulins**</td>
                        <td>
                            Humalog 75/25
                            <br />
                            Novolog 70/30
                            <br />
                            Humalog 50/50
                        </td>
                        <td> </td>
                        <td>10-30 minutes</td>
                        <td>1-6 hours</td>
                        <td>10-14 hours</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td colSpan="7">
                            <div className="text-right">
                                **Premixed insulin is more variable in peak and
                                duration of action
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const ReferenceContent = () => {
        const SourcesSubContent = () => {
            return (
                <div className={css['sub-section2']}>
                    <h5>{t('others.sources.name')}</h5>
                    <ol>
                        <li>
                            {t(
                                'others.sources.listItems.journalClinicalAppliedResearchEducation'
                            )}
                        </li>
                        <ol type="i">
                            <li>
                                &nbsp;
                                <a
                                    rel="noreferrer"
                                    href={t(
                                        'others.sources.listItems.standardsOfMedicalCareinDiabetesLink'
                                    )}
                                    target="_blank"
                                >
                                    {t(
                                        'others.sources.listItems.standardsOfMedicalCareinDiabetes'
                                    )}
                                </a>
                            </li>
                        </ol>
                        <li>
                            {t(
                                'others.sources.listItems.americanDiabetesAssociationS88S98'
                            )}
                        </li>
                    </ol>
                </div>
            );
        };

        const HypertensionSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.hypertension')}</h4>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.screeningAndDiagnosis.name')}</h5>
                        <ul>
                            <li>
                                {t(
                                    'others.screeningAndDiagnosis.listItems.one'
                                )}
                            </li>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.goals.name')}</h5>
                        <ul>
                            <li>{t('others.goals.listItems.one')}</li>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.bloodPressureAndCategories.name')}</h5>
                        <BloodPressureAndCategoriesTable />
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.treatment.name')}</h5>
                        <ul>
                            <li>{t('others.treatment.listItems.one')}</li>
                            <li>{t('others.treatment.listItems.two')}</li>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.medications.name')}</h5>
                        <MedicationsTable />
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.sources.name')}</h5>
                        <ol>
                            <li>
                                {t(
                                    'others.sources.listItems.americanHeartAssociation'
                                )}
                                &nbsp;
                                <a
                                    rel="noreferrer"
                                    href={t(
                                        'others.sources.listItems.2017hypertensionClinicalGuidelinesLink'
                                    )}
                                    target="_blank"
                                >
                                    {t(
                                        'others.sources.listItems.2017hypertensionClinicalGuidelines'
                                    )}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            );
        };

        const LipidsSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.lipids')}</h4>
                    <div className={css['sub-section2']}>
                        <h5>{t('others.screening.name')}</h5>
                        <ul>
                            <li>{t('others.screening.listItems.one')}</li>
                            <li>{t('others.screening.listItems.two')}</li>
                            <li>{t('others.screening.listItems.three')}</li>
                            <li>{t('others.screening.listItems.four')}</li>
                            <li>{t('others.screening.listItems.five')}</li>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.medications.name')}</h5>
                        <ul>
                            <li>{t('others.medications.statins.name')}</li>
                            <ul>
                                <li>
                                    {t(
                                        'others.medications.statins.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.medications.statins.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.medications.statins.listItems.three'
                                    )}
                                </li>
                            </ul>
                            <li>{t('others.medications.fenofibrate.name')}</li>
                            <ul>
                                <li>
                                    {t(
                                        'others.medications.fenofibrate.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.medications.fenofibrate.listItems.two'
                                    )}
                                </li>
                            </ul>
                            <li>{t('others.medications.niacin.name')}</li>
                            <ul>
                                <li>
                                    {t(
                                        'others.medications.niacin.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.medications.niacin.listItems.two'
                                    )}
                                </li>
                            </ul>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.sources.name')}</h5>
                        <ol>
                            <li>
                                {t(
                                    'others.sources.listItems.journalClinicalAppliedResearchEducation'
                                )}
                            </li>
                            <ol type="i">
                                <li>
                                    &nbsp;
                                    <a
                                        rel="noreferrer"
                                        href={t(
                                            'others.sources.listItems.standardsOfMedicalCareinDiabetesLink'
                                        )}
                                        target="_blank"
                                    >
                                        {t(
                                            'others.sources.listItems.standardsOfMedicalCareinDiabetes'
                                        )}
                                    </a>
                                </li>
                            </ol>
                            <li>
                                {t(
                                    'others.sources.listItems.americanDiabetesAssociationS75S87'
                                )}
                            </li>
                        </ol>
                    </div>
                </div>
            );
        };

        const KidneyHealthSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.kidneyHealth')}</h4>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.subtopicScreening.name')}</h5>
                        <ul>
                            <li>
                                {t('others.subtopicScreening.listItems.one')}
                            </li>
                            <li>
                                {t('others.subtopicScreening.listItems.two')}
                            </li>
                            <li>
                                {t('others.subtopicScreening.listItems.three')}
                            </li>
                            <li>
                                {t('others.subtopicScreening.listItems.four')}
                            </li>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <p>
                            {t(
                                'others.ckdManagementRecommendedScreeningFrequency'
                            )}
                        </p>
                        <p>{t('others.ckdManagement')}</p>
                    </div>

                    <div className={css['sub-section2']}>
                        <h6>{t('tableTitles.ckdManagement')}</h6>
                        <CKDManagementTable />
                    </div>

                    <div className={css['sub-section2']}>
                        <p>{t('others.bloodCreatinineLevel')}</p>
                    </div>

                    <div className={css['sub-section2']}>
                        <h6>{t('tableTitles.ckdStages')}</h6>
                        <CKDStagesTable />
                    </div>

                    <SourcesSubContent />
                </div>
            );
        };

        const EyeHealthSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.eyeHealth')}</h4>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.screening.name')}</h5>
                        <ul>
                            <li>{t('others.screening.eyeHealth.one')}</li>
                            <li>{t('others.screening.eyeHealth.two')}</li>
                            <li>{t('others.screening.eyeHealth.three')}</li>
                            <li>{t('others.screening.eyeHealth.four')}</li>
                        </ul>
                    </div>

                    <SourcesSubContent />
                </div>
            );
        };

        const NerveHealthSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.nerveHealth')}</h4>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.peripheralNeuropathyScreening')}</h5>
                        <ul>
                            <li>{t('others.screening.nerveHealth.one')}</li>
                            <li>{t('others.screening.nerveHealth.two')}</li>
                        </ul>
                    </div>

                    <SourcesSubContent />
                </div>
            );
        };

        const PsychosocialHealthSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.psychosocialHealth')}</h4>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.screening.name')}</h5>
                        <ul>
                            <li>
                                {t('others.screening.psychosocialHealth.one')}
                            </li>
                            <li>
                                {t('others.screening.psychosocialHealth.two')}
                            </li>
                            <li>
                                {t('others.screening.psychosocialHealth.three')}
                            </li>
                            <li>
                                {t('others.screening.psychosocialHealth.four')}
                            </li>
                            <li>
                                {t('others.screening.psychosocialHealth.five')}
                            </li>
                        </ul>
                    </div>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.sources.name')}</h5>
                        <ol>
                            <li>
                                {t(
                                    'others.sources.listItems.journalClinicalAppliedResearchEducation'
                                )}
                            </li>
                            <ol type="i">
                                <li>
                                    &nbsp;
                                    <a
                                        rel="noreferrer"
                                        href={t(
                                            'others.sources.listItems.standardsOfMedicalCareinDiabetesLink'
                                        )}
                                        target="_blank"
                                    >
                                        {t(
                                            'others.sources.listItems.standardsOfMedicalCareinDiabetes'
                                        )}
                                    </a>
                                </li>
                            </ol>
                            <li>
                                {t(
                                    'others.sources.listItems.americanDiabetesAssociationS25S32'
                                )}
                            </li>
                        </ol>
                    </div>
                </div>
            );
        };

        const ImmunizationsSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.immunizations')}</h4>

                    <div className={css['sub-section2']}>
                        <h5>{t('others.2017StandardsMedicalCareDiabetes')}</h5>
                        <ul>
                            <li>{t('others.screening.immunizations.one')}</li>
                            <li>{t('others.screening.immunizations.two')}</li>
                            <li>{t('others.screening.immunizations.three')}</li>
                            <li>{t('others.screening.immunizations.four')}</li>
                            <li>{t('others.screening.immunizations.five')}</li>
                        </ul>
                    </div>

                    <SourcesSubContent />
                </div>
            );
        };

        const NutritionProgramContentSection = () => {
            return (
                <div className={css['sub-section1']}>
                    <h4>{t('others.sectionNames.nutritionProgramContent')}</h4>

                    <div className={css['sub-section2']}>
                        <div className={css['list-title']}>
                            {t(
                                'others.subsectionNames.knowledgeAndSkillCurriculum.effectOfFoodOnBloodSugar'
                            )}
                        </div>

                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.educationContent.name'
                                )}
                            </h5>

                            <p>
                                {t(
                                    'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.educationContent.desc'
                                )}
                            </p>
                        </div>

                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.name'
                                )}
                            </h5>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.what.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.what.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.examples.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.examples.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.review.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.review.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.impact.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.impact.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.other.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.protein.other.desc'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.name'
                                )}
                            </h5>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.what.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.what.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.examples.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.examples.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.review.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.review.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.impact.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.impact.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.tip.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.tip.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.other.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.fat.other.desc'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.name'
                                )}
                            </h5>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.what.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.what.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.examples.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.examples.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.review.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.review.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.impact.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.impact.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.other.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.carbohydrates.other.desc'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.name'
                                )}
                            </h5>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.what.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.what.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.recommendations.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.recommendations.desc'
                                    )}
                                </p>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.applyingToIndividual.name'
                                    )}
                                </h6>
                                <p>
                                    {t(
                                        'others.knowledgeSkillCurriculum.effectOfFoodOnBloodSugar.nutrients.applyingToIndividual.desc'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={css['sub-section2']}>
                        <div className={css['list-title']}>
                            {t(
                                'others.subsectionNames.knowledgeAndSkillCurriculum.mealPlanning'
                            )}
                        </div>
                        <br />
                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.mealPlanning.educationContent.name'
                                )}
                            </h5>
                            <p>
                                {t(
                                    'others.knowledgeSkillCurriculum.mealPlanning.educationContent.desc'
                                )}
                            </p>
                        </div>

                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.name'
                                )}
                            </h5>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealPlan.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealPlan.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealPlan.listItems.two'
                                        )}
                                    </li>
                                </ul>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.five'
                                        )}
                                        &nbsp;
                                        <a
                                            rel="noreferrer"
                                            href={t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.source'
                                            )}
                                            target="_blank"
                                        >
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.source'
                                            )}
                                        </a>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.healthyEatingPatterns.listItems.six'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.four'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.five'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.six'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.generalOverview.mealSnackTiming.listItems.seven'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.name'
                                )}
                            </h5>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.five'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.seven'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.eight'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.nine'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.starch.listItems.ten'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.fruit.listItems.seven'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.five'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.six'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.seven'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.milk.listItems.eight'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweets.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweets.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweets.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweets.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweets.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweets.listItems.five'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweetenedBeverages.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweetenedBeverages.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweetenedBeverages.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweetenedBeverages.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.sweetenedBeverages.listItems.four'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.fiber.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.fiber.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.fiber.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.fiber.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.other.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.other.listItems.one'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.other.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.other.listItems.three'
                                                        )}
                                                    </li>
                                                </ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.other.listItems.four'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                <h6>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.carbSummary.name'
                                                    )}
                                                </h6>
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.carbSummary.listItems.one'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.carbSummary.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.carbSummary.listItems.three'
                                                        )}
                                                    </li>
                                                </ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.carbSummary.listItems.four'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.carbohydrates.more.carbSummary.listItems.five'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                                <div className={css['sub-section4']}>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.five'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.six'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.seven'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.eight'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.nine'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.nonStarchyVegetables.listItems.ten'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                                <div className={css['sub-section4']}>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.two'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.seven'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.eight'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.nine'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.ten'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.protein.listItems.eleven'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                                <div className={css['sub-section4']}>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.two'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.three'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.four'
                                                        )}
                                                    </li>
                                                    <ul>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.five'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.six'
                                                            )}
                                                        </li>
                                                        <ul>
                                                            <li>
                                                                {t(
                                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.seven'
                                                                )}
                                                            </li>
                                                            <ul>
                                                                <li>
                                                                    {t(
                                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.eight'
                                                                    )}
                                                                </li>
                                                                <li>
                                                                    {t(
                                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.nine'
                                                                    )}
                                                                </li>
                                                                <ul>
                                                                    <li>
                                                                        {t(
                                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.ten'
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.unsaturatedFat.listItems.eleven'
                                                                        )}
                                                                    </li>
                                                                </ul>
                                                            </ul>
                                                        </ul>
                                                    </ul>
                                                </ul>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.saturatedFat.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.saturatedFat.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.saturatedFat.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.saturatedFat.listItems.three'
                                                        )}
                                                    </li>
                                                    <ul>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.saturatedFat.listItems.four'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.saturatedFat.listItems.five'
                                                            )}
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.transFat.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.transFat.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.fatTypes.transFat.listItems.two'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.tipsForCuttingFatAndCholesterol.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.tipsForCuttingFatAndCholesterol.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.tipsForCuttingFatAndCholesterol.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.tipsForCuttingFatAndCholesterol.listItems.three'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.tipsForCuttingFatAndCholesterol.listItems.four'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.tipsForCuttingFatAndCholesterol.listItems.five'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.additionalTip.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.additionalTip.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.additionalTip.listItems.two'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.fats.four'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                </div>
                                <div className={css['sub-section4']}>
                                    <ul>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.water.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.water.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.water.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.water.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.one'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.three'
                                                        )}
                                                    </li>
                                                </ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.four'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.five'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.six'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.seven'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.mealPlanning.mealPlanSpecifics.buildingMealPlan.additionalBeverages.alcohol.listItems.eight'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={css['sub-section2']}>
                        <div className={css['list-title']}>
                            {t(
                                'others.subsectionNames.knowledgeAndSkillCurriculum.weighingMeasuringFoodPortions'
                            )}
                        </div>
                        <br />
                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.educationContent.name'
                                )}
                            </h5>
                            <p>
                                {t(
                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.educationContent.desc'
                                )}
                            </p>
                        </div>
                        <div className={css['sub-section3']}>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whyIsPortionControlImportant.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whyIsPortionControlImportant.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whyIsPortionControlImportant.listItems.two'
                                        )}
                                    </li>
                                </ul>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsAPortion.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsAPortion.one'
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsAServingSize.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsAServingSize.one'
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsTheDifference.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsTheDifference.listItems.one'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsTheDifference.listItems.two'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsTheDifference.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whatIsTheDifference.listItems.four'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whyDoesItMatter.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whyDoesItMatter.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.whyDoesItMatter.listItems.two'
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.name'
                                        )}
                                    </li>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.measuringTools.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.measuringTools.listItems.one'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.measuringTools.listItems.two'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.measuringTools.listItems.three'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.one'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.six'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.everydayItems.listItems.seven'
                                        )}
                                    </li>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.one'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.seven'
                                                )}
                                            </li>
                                        </ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.eight'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.nine'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.ten'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.eleven'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.twelve'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.thirteen'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.weighingMeasuringFoodPortions.measuring.outerFrame.plateMethod.listItems.fourteen'
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={css['sub-section2']}>
                        <div className={css['list-title']}>
                            {t(
                                'others.subsectionNames.knowledgeAndSkillCurriculum.carbohydrateCounting'
                            )}
                        </div>
                        <br />
                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.educationContent.name'
                                )}
                            </h5>
                            <p>
                                {t(
                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.educationContent.desc'
                                )}
                            </p>
                        </div>
                        <div className={css['sub-section3']}>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.carbohydrateBasics.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.carbohydrateBasics.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.carbohydrateBasics.listItems.two'
                                        )}
                                    </li>
                                </ul>
                            </div>

                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.whatIsCarbohydrateCounting.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.whatIsCarbohydrateCounting.one'
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.valueOfCarbCounting.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.valueOfCarbCounting.one'
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.listItems.three'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.listItems.four'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.howToCountCarbs.listItems.six'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodLabel.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodLabel.listItems.one'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodLabel.listItems.two'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodLabel.listItems.three'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodLabel.listItems.four'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodLabel.listItems.five'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.one'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.two'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.five'
                                                )}
                                            </li>
                                        </ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.six'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.seven'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.foodList.listItems.eight'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.usingCarbohydrateCounting.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.usingCarbohydrateCounting.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.usingCarbohydrateCounting.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.usingCarbohydrateCounting.listItems.three'
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className={css['sub-section4']}>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.name'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.five'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.six'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.seven'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.eight'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.nine'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.ten'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.eleven'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.carbohydrateCounting.waysToCountCarbs.generalRecommendations.listItems.twelve'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={css['sub-section2']}>
                        <div className={css['list-title']}>
                            {t(
                                'others.subsectionNames.knowledgeAndSkillCurriculum.labelReading'
                            )}
                        </div>
                        <br />
                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.educationContent.name'
                                )}
                            </h5>
                            <p>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.educationContent.desc'
                                )}
                            </p>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.importanceOfUnderstanding.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.importanceOfUnderstanding.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.importanceOfUnderstanding.listItems.two'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.importanceOfUnderstanding.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.importanceOfUnderstanding.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.importanceOfUnderstanding.listItems.five'
                                        )}
                                    </li>
                                </ul>
                            </ul>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.reviewingTheLabel.name'
                                )}
                            </h5>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.servingSizes.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.three'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.five'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.six'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.seven'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.eight'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.nine'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.ten'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.servingSizes.listItems.eleven'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.calories.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.calories.one'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.totalFat.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.three'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.four'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.five'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.six'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.seven'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.eight'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.nine'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.ten'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.eleven'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.labelReading.totalFat.listItems.twelve'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.cholesterol.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.cholesterol.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.cholesterol.listItems.two'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.sodium.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.sodium.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.sodium.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.sodium.listItems.three'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.sodium.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.sodium.listItems.five'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.sodium.listItems.six'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.three'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.four'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.five'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.six'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.seven'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.nine'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.ten'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.listItems.eleven'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.fiber.name'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.fiber.listItems.one'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.fiber.listItems.two'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.fiber.listItems.three'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.sugarAlcohols.name'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.sugarAlcohols.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.sugarAlcohols.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.sugarAlcohols.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.sugarAlcohols.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.sugarAlcohols.listItems.five'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.letsPractice.name'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.letsPractice.listItems.one'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.letsPractice.listItems.two'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.letsPractice.listItems.three'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.letsPractice.listItems.four'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.labelReading.totalCarbohydrate.letsPractice.listItems.five'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.protein.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.protein.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.protein.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.protein.listItems.three'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.protein.listItems.four'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.protein.listItems.five'
                                        )}
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.percentDailyValue.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.percentDailyValue.listItems.one'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.percentDailyValue.listItems.two'
                                        )}
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.additionalNutrients.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.additionalNutrients.one'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.labelReading.ingredientList.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.ingredientList.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.ingredientList.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.ingredientList.listItems.three'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.labelReading.ingredientList.listItems.four'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.labelReading.ingredientList.listItems.five'
                                        )}
                                    </li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    <div className={css['sub-section2']}>
                        <div className={css['list-title']}>
                            {t(
                                'others.subsectionNames.knowledgeAndSkillCurriculum.diningOutMenuReading'
                            )}
                        </div>
                        <br />
                        <div className={css['sub-section3']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.educationContent.name'
                                )}
                            </h5>
                            <p>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.educationContent.desc'
                                )}
                            </p>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.one'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.two'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.three'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.four'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.five'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.six'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.checkInFirst.listItems.seven'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.diningOut'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.before.name'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.before.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.before.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.before.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.before.listItems.four'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.name'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.six'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.seven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.during.listItems.nine'
                                        )}
                                    </li>
                                </ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.after.name'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.settingYourself.after.one'
                                        )}
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.readingBetweenLines.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.readingBetweenLines.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.readingBetweenLines.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.readingBetweenLines.listItems.three'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.readingBetweenLines.listItems.four'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.connectBack.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.connectBack.listItems.one'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.connectBack.listItems.two'
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.connectBack.listItems.three'
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.one'
                                    )}
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.two'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.three'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.four'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.five'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.six'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.travelingOnRoad.listItems.seven'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                        </div>
                        <div className={css['sub-section4']}>
                            <h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.name'
                                )}
                            </h6>
                            <ul>
                                <li>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.one'
                                    )}
                                </li>
                                <li>
                                    <h6>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.name'
                                        )}
                                    </h6>
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.what.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.what.one'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.one'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.two'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.three'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.four'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussWays.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussWays.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussWays.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussWays.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussKitchen.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussKitchen.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussKitchen.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussKitchen.listItems.three'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussKitchen.listItems.four'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussHealthy.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussHealthy.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussHealthy.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussHealthy.listItems.three'
                                                        )}
                                                    </li>
                                                </ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussTips.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussTips.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.discussTips.listItems.two'
                                                        )}
                                                    </li>
                                                </ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.checkIn.name'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.checkIn.listItems.one'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.checkIn.listItems.two'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.financial.steps.listItems.checkIn.listItems.three'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                            </ul>
                            <ul>
                                <li>
                                    <h6>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.name'
                                        )}
                                    </h6>
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.what.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.what.listItems.one'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.what.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.what.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.what.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.what.listItems.five'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyFeelings.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyFeelings.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyFeelings.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyFeelings.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyFeelings.listItems.four'
                                                )}
                                            </li>
                                        </ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyPotential.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyPotential.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyPotential.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.identifyPotential.listItems.three'
                                                )}
                                            </li>
                                        </ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.one'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.five'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.six'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.seven'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.eight'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.nine'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.ten'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.eleven'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.twelve'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.thirteen'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.fourteen'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.provideWithConversation.listItems.fifteen'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.seven'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.social.steps.encourageAdditionalForms.listItems.eight'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                </ul>
                            </ul>
                            <ul>
                                <li>
                                    <h6>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.name'
                                        )}
                                    </h6>
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.what.name'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.build'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                <h6>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.one.name'
                                                    )}
                                                </h6>
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.one.one'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                <h6>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.two.name'
                                                    )}
                                                </h6>
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.two.one'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                <h6>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.three.name'
                                                    )}
                                                </h6>
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.three.one'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                <h6>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.four.name'
                                                    )}
                                                </h6>
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.four.one'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                <h6>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.five.name'
                                                    )}
                                                </h6>
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.five.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.selfEfficacy.steps.five.two'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                                <li>
                                    <h6>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.name'
                                        )}
                                    </h6>
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.what.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.what.listItems.one'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.what.listItems.two'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.what.listItems.three'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.what.listItems.four'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.what.listItems.five'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.steps.name'
                                            )}
                                        </h6>
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.steps.listItems.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.steps.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.steps.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.one'
                                            )}
                                        </li>
                                        <li>
                                            <h6>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.name'
                                                )}
                                            </h6>
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.three'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.four'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.five'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.six'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.seven'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.eight'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.nine'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.ten'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.selfCompassion.selfKindness.listItems.eleven'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.listItems.one'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.listItems.two'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.listItems.five'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.commonHumanity.listItems.six'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.one'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.two'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.three'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.four'
                                                        )}
                                                    </li>
                                                    <ul>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.five'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.six'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.psychologicalFactors.mindfulness.listItems.seven'
                                                            )}
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                                <li>
                                    <h6>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.name'
                                        )}
                                    </h6>
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.intro.one'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.intro.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.intro.three'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.intro.four'
                                            )}
                                        </li>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.intro.five'
                                        )}
                                    </li>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.what'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.connection'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.five'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.six'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.seven'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.eight'
                                                        )}
                                                    </li>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.nine'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.ten'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.eleven'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.twelve'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.awareness.steps.listItems.thirteen'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.what'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.connection'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.two'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.five'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.distraction.steps.listItems.seven'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.what'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.connection'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.starter.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.starter.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.starter.three'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.starter.four'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.starter.five'
                                                        )}
                                                    </li>
                                                </ul>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.starter.six'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atWork.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atWork.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atWork.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atWork.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.listItems.three'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.listItems.four'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.listItems.five'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.atHome.listItems.six'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.name'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.one'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.two'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.three'
                                                    )}
                                                </li>
                                                <ul>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.four'
                                                    )}
                                                </ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.five'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.six'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.seven'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.eight'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.external.steps.whenOut.listItems.nine'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.what'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.connection'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.four'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.five'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.six'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.seven'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.eight'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.nine'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.ten'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.eleven'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.twelve'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.thirteen'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.fourteen'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.fifteen'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.sixteen'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.seventeen'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.eighteen'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.nineteen'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.twenty'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.inhibition.steps.listItems.twentyone'
                                                    )}
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                    <li>
                                        <h6>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.name'
                                            )}
                                        </h6>
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.what'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.connection'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.two'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.three'
                                                    )}
                                                </li>
                                            </ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.five'
                                                )}
                                            </li>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.six'
                                                    )}
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {t(
                                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.seven'
                                                    )}
                                                </li>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.eight'
                                                        )}
                                                    </li>
                                                    <ul>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.nine'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.ten'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.eleven'
                                                            )}
                                                        </li>
                                                        <li>
                                                            {t(
                                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.mindlessEatingBarriers.emotional.steps.listItems.twelve'
                                                            )}
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                                <li>
                                    <h6>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.name'
                                        )}
                                    </h6>
                                </li>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.identifying'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.depression.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.depression.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.depression.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.depression.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.depression.listItems.four'
                                                )}
                                            </li>
                                        </ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.name'
                                            )}
                                        </li>
                                        <ul>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.listItems.one'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.listItems.two'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.listItems.three'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.listItems.four'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.listItems.five'
                                                )}
                                            </li>
                                            <li>
                                                {t(
                                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.anxiety.listItems.six'
                                                )}
                                            </li>
                                        </ul>
                                    </ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.reasons.name'
                                        )}
                                    </li>
                                    <ul>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.reasons.listItems.one'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.reasons.listItems.two'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.reasons.listItems.three'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.reasons.listItems.four'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'others.knowledgeSkillCurriculum.diningOutMenuReading.barrierResolution.recommendingAdditionalSupport.reasons.listItems.five'
                                            )}
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                        </div>

                        <br />
                        <br />

                        <div className={css['sub-section4']}>
                            <h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.name'
                                )}
                            </h5>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.name'
                                    )}
                                </h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.insulinSensitizer'
                                )}
                                <br />
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.three'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.four'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.six'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.seven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.eight'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.nine'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.ten'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.eleven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.twelve'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.thirteen'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.fourteen'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.fifteen'
                                    )}
                                </h6>
                                <ul>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.sixteen'
                                    )}
                                    <br />
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.seventeen'
                                    )}
                                    <br />
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBiguanides.listItems.eighteen'
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.name'
                                    )}
                                </h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.insulinSensitizer'
                                )}
                                <br />
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.five'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.six'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.seven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.eight'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.nine'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.ten'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.eleven'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.twelve'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classDPP.listItems.thirteen'
                                )}
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.name'
                                    )}
                                </h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.insulinSensitizer'
                                )}
                                <br />
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.two'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.three'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.six'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.seven'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.ten'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.eleven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.twelve'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.thirteen'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.fourteen'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.fifteen'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classTZD.listItems.sixteen'
                                )}
                            </div>
                        </div>
                        <br />
                        <div className={css['sub-section4']}>
                            <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.name'
                                    )}
                                </h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.insulinSecretagogues'
                                )}
                                <br />
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.three'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.four'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.six'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.seven'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.ten'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.eleven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.twelve'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.thirteen'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.fourteen'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSulfonylureas.listItems.fifteen'
                                )}
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.name'
                                    )}
                                </h5>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.insulinSecretagogues'
                                )}
                                <br />
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.three'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.four'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.six'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.seven'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.ten'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.eleven'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classMeglitinides.listItems.twelve'
                                )}
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.name'
                                    )}
                                </h5>
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.two'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.three'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.six'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.seven'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.eight'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.ten'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.eleven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.twelve'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.thirteen'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classSodium.listItems.fourteen'
                                )}
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.name'
                                    )}
                                </h5>
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.two'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.three'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.five'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.six'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.seven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.ten'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.eleven'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classAlpha.listItems.twelve'
                                )}
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.name'
                                    )}
                                </h5>
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.three'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.four'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.five'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.six'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.seven'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.nine'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.ten'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.insulin.classBile.listItems.eleven'
                                )}
                            </div>
                        </div>
                        <br />
                        <h5>
                            {t(
                                'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.name'
                            )}
                        </h5>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.name'
                                    )}
                                </h5>
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.four'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.five'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.six'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.seven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.ten'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.eleven'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.twelve'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.thirteen'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.fourteen'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.fifteen'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.listItems.sixteen'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.name'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.listItems.one'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.listItems.two'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.listItems.three'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.listItems.four'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.listItems.five'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classGLP1.use.listItems.six'
                                )}
                            </div>
                        </div>
                        <div className={css['sub-section4']}>
                            <br /> <br />
                            <div className={css['sub-section4']}>
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.name'
                                    )}
                                </h5>
                                <br />
                                <h5>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.medications'
                                    )}
                                </h5>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.one'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.two'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.three'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.four'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.five'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.six'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.seven'
                                    )}
                                </h6>
                                <ul>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.eight'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.nine'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.ten'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.eleven'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.twelve'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.thirteen'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.listItems.fourteen'
                                        )}
                                    </li>
                                </ul>
                                <h6>
                                    {t(
                                        'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.name'
                                    )}
                                </h6>
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.one'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.two'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.three'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.four'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.five'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.six'
                                )}
                                <br />
                                {t(
                                    'others.knowledgeSkillCurriculum.diningOutMenuReading.injectableMedications.classAmylin.use.listItems.seven'
                                )}
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className={css['sub-section2']}>
                            <h4>{t('tableTitles.insulin')}</h4>
                            <InsulinTable />
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div className={css['searh-content']}>
                <div className={css['reference-content']}>
                    <h2>{t('others.reference')}</h2>
                    <HypertensionSection />
                    <LipidsSection />
                    <KidneyHealthSection />
                    <EyeHealthSection />
                    <NerveHealthSection />
                    <PsychosocialHealthSection />
                    <ImmunizationsSection />
                    <NutritionProgramContentSection />
                </div>
            </div>
        );
    };

    return (
        <div id="medicationList" className={css['medication-list']}>
            <ReferenceContent />
        </div>
    );
};

export default Medication;
