export default function logBookAuth(state = {}, {type, payload}) {
    switch (type) {
        case 'AUTH': {
            return {
                token: payload,
            };
        }

        case 'LOGBOOK_USER': {
            return {
                ...state,
                user: payload,
            };
        }

        default:
            return state;
    }
}
