import {Int32Value} from 'google-protobuf/google/protobuf/wrappers_pb';

import {
    FoodLogServicePromiseClient,
    GetFoodLogHistoryRequest,
} from '@livongo/protobuf-grpc-external-food/lib/livongo/protobuf/grpc/external/food/food_grpc_web_pb';

import {Date} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/date_pb';
import {
    FoodFocusServicePromiseClient,
    GetNutritionSummariesRequest,
} from '@livongo/protobuf-grpc-external-food/lib/livongo/protobuf/grpc/external/food/focus_grpc_web_pb';

const SERVICE_URL = process.env.gRPC_URL;
const FoodLogClient = new FoodLogServicePromiseClient(SERVICE_URL);
const FoodFocusClient = new FoodFocusServicePromiseClient(process.env.gRPC_URL);
// eslint-disable-next-line
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

// enable debugging grpc calls
enableDevTools([FoodLogClient, FoodFocusClient]);

const ProtoUtils = {
    Food: {
        Date,
        FoodLogClient,
        FoodFocusClient,
        GetFoodLogHistoryRequest,
        GetNutritionSummariesRequest,
        FoodLogServicePromiseClient,
    },
    Google: {
        Int32Value,
    },
};

export default ProtoUtils;
