import css from './medication-reconciliation.scss';
import {useSelector} from 'react-redux';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import result from 'lodash/result';
import find from 'lodash/find';
import format from 'date-fns/format';
import {toUTC} from 'common/dates';
import {inactiveReasonOptions} from './helper';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const InactiveMedications = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const medications = useSelector(state => state.medicationsLists);
    const medicationOptions = useSelector(state => state.medicationOptions);
    const medicationClasses = {};

    if (medicationOptions.medClassOptions) {
        medicationOptions.medClassOptions.forEach(option => {
            medicationClasses[option.medClassId] = option.medClassCode;
        });
    }

    const formatMedicationList = list => {
        if (!list) return [];

        return list.map(item => {
            const medClassInfo = filter(
                medicationOptions.medClassOptions,
                medClassOption => {
                    return medClassOption.medClassId === item.medClassId;
                }
            );

            const inactiveReasonList = [];

            if (item.inactiveReasonIds) {
                forEach(inactiveReasonOptions, inactiveReason => {
                    if (
                        item.inactiveReasonIds.indexOf(
                            parseInt(inactiveReason.inactiveReasonId, 10)
                        ) !== -1
                    ) {
                        inactiveReasonList.push(
                            t(
                                `reconciliation.inactiveReasons.${inactiveReason.inactiveReasonName}`
                            )
                        );
                    }
                });
            }

            item.medClass = result(
                find(medicationOptions.medClassOptions, opt => {
                    return opt.medClassId === item.medClassId;
                }),
                'medClassCode'
            );
            item.medRoute = medicationOptions.medRoutes[item.medRouteId];
            item.medClassInfo = medClassInfo.length > 0 ? medClassInfo[0] : {};
            item.startDate = item.startDate
                ? new Date(item.startDate).getTime()
                : null;
            item.inactiveReasonList = inactiveReasonList.join(', ');

            return item;
        });
    };

    const renderInactiveMedications = () => {
        const inactiveMedicationList =
            formatMedicationList(medications.inactiveMedications) || [];

        return inactiveMedicationList.map((medication, index) => {
            return (
                <div
                    className={css['inactive-row']}
                    key={`${index}-${medication.drugName}`}
                >
                    <div className={css['row-class']}>
                        {medication.medClassId
                            .map(id => medicationClasses[id])
                            .join(',')}
                    </div>
                    <div className={css['row-long']}>{medication.drugName}</div>
                    <div className={css['row-long']}>{medication.gpi6Name}</div>
                    <div className={css['row-short']}>{medication.dose}</div>
                    <div className={css['row-short']}>
                        {medication.medRoute}
                    </div>
                    <div className={css['row-med']}>
                        {medication.directions}
                    </div>
                    <div className={css['row-med']}>
                        {format(toUTC(medication.startDate), 'MM/dd/yyyy')}
                    </div>
                    <div className={css['row-med']}>
                        {format(toUTC(medication.inactiveDate), 'MM/dd/yyyy')}
                    </div>
                    <div className={css['row-med']}>
                        {medication.inactiveReasonList}
                        {medication.inactiveReasonOther ? (
                            <div>
                                Other&apos;s Reason:
                                {medication.inactiveReasonOther}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <div
            id="medication-imported-panel"
            className="panel panel-default"
            style={{fontSize: '12px'}}
        >
            <div className="panel-heading">
                <div className="row">
                    <div className="col-xs-6">
                        <h3 className="panel-title center-vertical">
                            {t('reconciliation.inactiveMedications.title')}
                        </h3>
                    </div>
                </div>
            </div>
            <div className="panel-body overlay">
                <div className={css['med-header']}>
                    <div className={css['row-class']} />
                    <div className={css['row-long']}>{t('headers.name')}</div>
                    <div className={css['row-long']}>
                        {t('headers.category')}
                    </div>
                    <div className={css['row-short']}>{t('headers.dose')}</div>
                    <div className={css['row-short']}>
                        {t('headers.routes')}
                    </div>
                    <div className={css['row-med']}>
                        {t('headers.directions')}
                    </div>
                    <div className={css['row-med']}>
                        {t('headers.startDate')}
                    </div>
                    <div className={css['row-med']}>
                        {t('headers.inactiveDate')}
                    </div>
                    <div className={css['row-med']}>
                        {t('headers.howOften')}
                    </div>
                </div>
            </div>
            {renderInactiveMedications()}
        </div>
    );
};

export default InactiveMedications;
