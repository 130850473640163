import Arg from '@livongo/arg';

const MentalHealthDashboard = props => {
    const authToken = Arg.all();
    const dataObject = {clinicalPortalToken: authToken.authToken};

    const openFrame = () => {
        const iframe = document.createElement('iframe');

        iframe.src = process.env.MYSTRENGTH_URL;
        iframe.id = 'iframe_open';
        iframe.style.position = 'absolute';
        iframe.style.zIndex = '999';
        iframe.style.height = '100%';
        iframe.style.width = '100%';
        iframe.style.top = '0';

        document.body.prepend(iframe);

        iframe.onload = () => {
            iframe.contentWindow.postMessage(
                dataObject,
                process.env.MYSTRENGTH_URL
            );
        };
    };

    return <div onLoad={openFrame()} />;
};

export default MentalHealthDashboard;
