import {mealCodes, getValue} from './BgConstants';
import css from './Logbook.scss';
import cn from 'classnames';
import {useSelector} from 'react-redux';
import {bgRangeClass} from './RangeClass';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import {intlFormat} from 'date-fns';
import Arg from '@livongo/arg';
import {useState, useEffect} from 'react';

const TagView = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const [code, setCode] = useState(Arg('memberlocale'));
    const filter = useSelector(state => state.logBookFilters);
    const tagData = useSelector(state => state.bgsReducer.tagView);
    const tagSummary = useSelector(state => state.bgsReducer.tagSummary);
    const getTableHeader = () => {
        return mealCodes.map(eatCode => {
            return (
                <th key={eatCode.name} className={css['data-table']}>
                    {t(`mealCodes.${eatCode.dataKey}`)}
                    <br />
                    (mg/dL)
                </th>
            );
        });
    };

    useEffect(() => {
        if (code === 'en-CA' || code === 'fr-CA' || code === 'es-US') {
            setCode('en-US');
        } else if (code === 'en-ES') {
            setCode('es-ES');
        }
    }, [code]);

    const getTagRows = () => {
        if (!tagData || tagData.length === 0) {
            return (
                <tr className={css['logbook-table-row']}>
                    <td colSpan="11" className={css['no-data-row']}>
                        {t('noData')}
                    </td>
                </tr>
            );
        }

        return tagData.map((bg, i) => {
            return (
                <tr key={i} className={css['logbook-table-row']}>
                    <td className={css['date-table']}>
                        {intlFormat(
                            new Date(bg.bgDate),
                            {
                                weekday: 'short',
                                month: 'numeric',
                                day: 'numeric',
                            },
                            {
                                locale: code,
                            }
                        )}
                    </td>
                    {mealCodes.map((eatCode, j) => {
                        return (
                            <td key={j} className={css['data-table']}>
                                <div>
                                    {bg[eatCode.value]
                                        ? bg[eatCode.value].map((bgVal, k) => {
                                              return (
                                                  <span
                                                      key={k}
                                                      className={
                                                          css['bg-value']
                                                      }
                                                  >
                                                      <span
                                                          className={cn(
                                                              css[
                                                                  'bg-range-icon'
                                                              ],
                                                              css[
                                                                  bgRangeClass(
                                                                      bgVal
                                                                  )
                                                              ]
                                                          )}
                                                      />
                                                      <span
                                                          className={
                                                              css[
                                                                  'bg-value-number'
                                                              ]
                                                          }
                                                      >
                                                          {getValue(bgVal)}{' '}
                                                          {t('common.at')}{' '}
                                                          {intlFormat(
                                                              new Date(
                                                                  bgVal.timestamp
                                                              ),
                                                              {
                                                                  hour:
                                                                      'numeric',
                                                                  minute:
                                                                      'numeric',
                                                              },
                                                              {
                                                                  locale: code,
                                                              }
                                                          )}
                                                      </span>
                                                  </span>
                                              );
                                          })
                                        : ''}
                                </div>
                            </td>
                        );
                    })}
                    <td
                        className={cn(
                            css['stats-table'],
                            css['logbook-result']
                        )}
                    >
                        <div>
                            <span>
                                Avg:{' '}
                                <span className={css['bg-value-number']}>
                                    {bg.avg}
                                </span>{' '}
                                <span>mg/dL</span>
                                <br />
                                {t('common.checks')}: {bg.count}
                            </span>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const getOORText = () => {
        if (!filter.inludeOOR) {
            return (
                <div className="row">
                    <div className={cn('col-xs-12', css['inform-text'])}>
                        {t('common.OOR')}
                    </div>
                </div>
            );
        }
    };

    return (
        <div
            className={cn(
                css['horizontal-scroll'],
                'panel',
                css['meal-tag-view']
            )}
        >
            <div className={css['panel-heading']}>
                <h3 className={css['panel-title']}>{t('tagView.header')}</h3>
            </div>

            <div className={css['panel-body']}>
                <div
                    className={css['logbook-table-body']}
                    id="table-meal-tag-view"
                >
                    <table className={cn(css.table, css['table-striped'])}>
                        <thead className={css['logbook-table-header']}>
                            <tr>
                                <th className={css['date-table']}>Date</th>
                                {getTableHeader()}
                                <th className={css['stats-table']} />
                            </tr>
                        </thead>

                        <tbody>{getTagRows()}</tbody>

                        <tfoot className={css['logbook-result']}>
                            <tr>
                                <td className={css['date-table']}>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </td>
                                {mealCodes.map(eatCode => {
                                    return (
                                        <td
                                            key={eatCode.value}
                                            className={css['data-table']}
                                        >
                                            <span>
                                                Avg:{' '}
                                                <span
                                                    className={
                                                        css['bg-value-number']
                                                    }
                                                >
                                                    {
                                                        tagSummary[
                                                            eatCode.value
                                                        ].avg
                                                    }
                                                </span>
                                                <br />
                                                {t('common.checks')}:
                                                {
                                                    tagSummary[eatCode.value]
                                                        .count
                                                }
                                            </span>
                                        </td>
                                    );
                                })}
                                <td className={css['stats-table']}>
                                    <span>
                                        {t('common.summary')} Avg:{' '}
                                        <span
                                            className={css['bg-value-number']}
                                        >
                                            {tagSummary[-2].avg}
                                        </span>{' '}
                                        <span>mg/dL</span>
                                        <br />
                                        {t('common.summary')}{' '}
                                        {t('common.checks')}:
                                        {tagSummary[-2].count}
                                    </span>
                                    <br />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                {getOORText()}
            </div>
        </div>
    );
};

export default TagView;
