import {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import FoodlogCarousel from './FoodlogCarousel';
import css from './FoodlogSkeleton.scss';
import DateLocaleContext from '@livongo/ui/Context/DateLocale';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import FoodlogMealCard from './FoodlogMealCard';
import FoodlogUnavailLog from './FoodlogUnavailLog';
import DailySummary from './DailySummary';

const FoodlogSkeleton = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {formatLocale} = useContext(DateLocaleContext);
    const firstDay = useSelector(
        state => state.foodlogReducer.initialFirstDayOfWeek
    );
    const logs = useSelector(state => state.foodlogReducer.logs);
    const [days, setDays] = useState([]);

    function getMealDate(startDate, diffDate, type) {
        return parseInt(format(addDays(startDate, diffDate % 7), type), 10);
    }
    const getSummaryDate = daysDiff => {
        const summaryDate = {};

        summaryDate.year = getMealDate(firstDay, daysDiff, 'yyyy');
        summaryDate.month = getMealDate(firstDay, daysDiff, 'M');
        summaryDate.day = getMealDate(firstDay, daysDiff, 'dd');

        return summaryDate;
    };

    function filterMeals() {
        const tempDays = [];

        for (let index = 0; index < 7; index++) {
            const meals = {};

            meals.breakfast = [];
            meals.lunch = [];
            meals.dinner = [];
            meals.snack = [];
            meals.hasMeal = false;
            meals.summaryDate = {};
            tempDays.push(meals);
        }
        forEach(logs, mealLog => {
            const daysDiffFromInitDay = differenceInDays(
                new Date(
                    Number(get(mealLog, 'createTime.timeStampNoTimeZone'))
                ),
                firstDay
            );

            if (
                get(
                    tempDays,
                    `${daysDiffFromInitDay}.${mealLog.mealTag.toLowerCase()}`
                )
            ) {
                tempDays[daysDiffFromInitDay][
                    mealLog.mealTag.toLowerCase()
                ].push(mealLog);
                tempDays[daysDiffFromInitDay].summaryDate = getSummaryDate(
                    daysDiffFromInitDay
                );
                tempDays[daysDiffFromInitDay].hasMeal = true;
            }
        });

        setDays(tempDays);
    }

    const getDay = daysDiff => {
        return formatLocale(
            addDays(firstDay, daysDiff % 7),
            'eeee'
        ).toUpperCase();
    };

    const getSummaryDay = daysDiff => {
        return format(addDays(firstDay, daysDiff % 7), 'M/d');
    };

    const getMealsNumber = meal => {
        return reduce(
            days,
            (sum, n) => {
                return sum + (get(n, meal) || []).length;
            },
            0
        );
    };

    useEffect(() => {
        filterMeals();
    }, [logs]); // eslint-disable-line react-hooks/exhaustive-deps

    const getTableRows = () => {
        return days.map((day, index) => {
            return (
                <div key={index} className={css['days-table-row']}>
                    <div className={css['days-table-pivot']}>
                        <span className={css['table-date']}>
                            {getSummaryDay(index)}
                        </span>
                        <br />
                        <span className={css['table-day']}>
                            {getDay(index)}
                        </span>
                        {day.hasMeal && (
                            <DailySummary summaryDate={day.summaryDate} />
                        )}
                    </div>
                    <div className={css['days-table-cell']}>
                        {day.breakfast.length > 0 ? (
                            <div className="FoodlogCarousel-carousel">
                                <div className="FoodlogCarousel-display-content">
                                    <FoodlogCarousel slides={day.breakfast} />
                                    <FoodlogMealCard meals={day.breakfast} />
                                </div>
                            </div>
                        ) : (
                            <FoodlogUnavailLog />
                        )}
                    </div>
                    <div className={css['days-table-cell']}>
                        {day.lunch.length > 0 ? (
                            <div className="FoodlogCarousel-carousel">
                                <div className="FoodlogCarousel-display-content">
                                    <FoodlogCarousel slides={day.lunch} />
                                    <FoodlogMealCard meals={day.lunch} />
                                </div>
                            </div>
                        ) : (
                            <FoodlogUnavailLog />
                        )}
                    </div>
                    <div className={css['days-table-cell']}>
                        {day.dinner.length > 0 ? (
                            <div className="FoodlogCarousel-carousel">
                                <div className="FoodlogCarousel-display-content">
                                    <FoodlogCarousel slides={day.dinner} />
                                    <FoodlogMealCard meals={day.dinner} />
                                </div>
                            </div>
                        ) : (
                            <FoodlogUnavailLog />
                        )}
                    </div>
                    <div className={css['days-table-cell']}>
                        {day.snack.length > 0 ? (
                            <div className="FoodlogCarousel-carousel">
                                <div className="FoodlogCarousel-display-content">
                                    <FoodlogCarousel slides={day.snack} />
                                    <FoodlogMealCard meals={day.snack} />
                                </div>
                            </div>
                        ) : (
                            <FoodlogUnavailLog />
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={css['days-table']}>
            <div
                className={`${css['days-table-row']} ${css['days-table-header']}`}
            >
                <div className={css['days-table-pivot']} />
                <div className={css['days-table-cell']}>
                    {t('foodTypes.breakfast')} ({getMealsNumber('breakfast')})
                </div>
                <div className={css['days-table-cell']}>
                    {t('foodTypes.lunch')} ({getMealsNumber('lunch')})
                </div>
                <div className={css['days-table-cell']}>
                    {t('foodTypes.dinner')} ({getMealsNumber('dinner')})
                </div>
                <div className={css['days-table-cell']}>
                    {t('foodTypes.snack')} ({getMealsNumber('snack')})
                </div>
            </div>
            {getTableRows()}
        </div>
    );
};

export default FoodlogSkeleton;
