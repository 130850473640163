import APIUtils from '@livongo/utils/api';

const query = (params, authToken) => {
    return new Promise((resolve, reject) => {
        APIUtils.get(
            '/v1/users/me/readings/bgs/data',
            {
                start: params.start,
                tagsIsControl: params.tagsIsControl,
                tagsOOR: params.tagsOOR,
                tagsSource: params.tagsSource,
            },
            {
                headers: {
                    Authorization: `Bearer  ${authToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(data => resolve(data.data))
            .catch(console.log); // eslint-disable-line no-console
    });
};

const getBgStatistics = (params, authToken) => {
    return new Promise((resolve, reject) => {
        APIUtils.get(
            'v1/users/me/readings/bgs/stats/extended',
            {
                start: params.start,
                tagsIsControl: false,
            },
            {
                headers: {
                    Authorization: `Bearer  ${authToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(data => resolve(data.data))
            .catch(console.log); // eslint-disable-line no-console
    });
};

export const BGsResource = {
    query,
    getBgStatistics,
};
