import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Modal from '@livongo/ui/Modal';
import DatePicker from '@livongo/ui/DatePicker';
import cn from 'classnames';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import format from 'date-fns/format';
import Arg from '@livongo/arg';
import MedicationResource from '../../resources/res_medication';
import modalcss from './modal.scss';
import css from '../medication-reconciliation.scss';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const InactiveModal = ({show, setShow, selectedMed, getMedication}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    // const dispatch = useDispatch();
    const inactiveReasons = useSelector(
        state => state.medicationsLists.inactiveReasons
    );
    const [selectedReasons, setSelectedReasons] = useState({});
    // If user selects Other, then they'll have to type into input
    const [otherReason, setOtherReason] = useState('');
    const [inactiveDate, setInactiveDate] = useState();
    const [inactiveError, setInactiveError] = useState(false);
    const startdate = new Date(get(selectedMed, 'startDate'));
    const inactivedate = new Date(inactiveDate);

    useEffect(() => {
        return () => {
            setOtherReason('');
            setInactiveDate('');
            setInactiveError('');
            setSelectedReasons({});
        };
    }, [show]);

    // Handles Reason change, setting it's value to true or false
    const handleReasonChange = reason => {
        let reasonResult = true;
        const reasonName = reason.inactiveReasonName;

        if (selectedReasons[reasonName]) {
            reasonResult = false;
        }
        setSelectedReasons(prevState => {
            return {...prevState, [reasonName]: reasonResult};
        });
    };

    const handleDateChange = date => {
        setInactiveDate(new Date(date));
    };

    const saveInactiveReasons = () => {
        const inactiveReasonIds = [];
        let makeInactive = false;

        forEach(inactiveReasons, inactiveReasonOption => {
            const reasonName = inactiveReasonOption.inactiveReasonName;

            if (selectedReasons[reasonName]) {
                inactiveReasonIds.push(inactiveReasonOption.inactiveReasonId);
            }
        });

        // Check that user gave a reason in the input if they checked on other checkbox or
        // that reasonIds array is empty (meaning nothing has been checked by user)
        let noneSelected = true;

        Object.values(selectedReasons).forEach(reason => {
            if (reason) noneSelected = false;
        });
        if (
            (selectedReasons.Other && !otherReason) ||
            noneSelected ||
            !inactiveDate ||
            inactivedate < startdate
        ) {
            setInactiveError(true);
            makeInactive = true;

            return;
        }

        const inactiveMed = {
            id: get(selectedMed, 'id'),
            dose: get(selectedMed, 'dose'),
            directions: get(selectedMed, 'directions'),
            startDate: get(selectedMed, 'startDate'),
            inactiveDate: format(inactiveDate, 'yyyy-MM-dd'),
            inactiveReasonOther: otherReason || '',
            inactiveReasonIds,
            reportedAdherenceId: get(selectedMed, 'reportedAdherenceId'),
        };

        const {authToken} = Arg.all();

        if (!makeInactive) {
            MedicationResource.makeMedicationInactive(
                selectedMed.reviewId,
                authToken,
                inactiveMed
            )
                .then(() => {
                    MedicationResource.getMedication(
                        authToken,
                        get(selectedMed, 'reviewId')
                    ).then(() =>
                        getMedication('', get(selectedMed, 'reviewId'))
                    );
                    setShow(false);
                })
                .catch(err => {
                    setShow(false);
                });
        }
    };

    const getInactiveReasonsInputs = () => {
        return inactiveReasons.map((reason, i) => {
            return (
                <div className="checkbox" key={i}>
                    <label className={cn(modalcss['check-item'])}>
                        <input
                            id="option_{{inactiveReasonOption.inactiveReasonName}}"
                            type="checkbox"
                            onChange={() => handleReasonChange(reason)}
                        />
                        <span className={cn(modalcss['check-text'])}>
                            {t(
                                `reconciliation.inactiveReasons.${reason.inactiveReasonName}`
                            )}
                        </span>
                    </label>
                    <div className="form-group">
                        {selectedReasons.Other &&
                        reason.inactiveReasonName === 'Other' ? (
                            <input
                                type="text"
                                name="otherReasonText"
                                className="form-control"
                                aria-label="Enter another reason, in case you have one"
                                value={otherReason}
                                onChange={e => setOtherReason(e.target.value)}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        });
    };

    const getErrorMessage = () => {
        const errors = [];
        const startDate = get(selectedMed, 'startDate');
        const startDtStr = format(new Date(startDate), 'MM/dd/yyyy');

        if (inactiveError) {
            if (!inactiveDate) errors.push('Inactive Date');
            if (selectedReasons.Other && !otherReason)
                errors.push("Empty text in 'Other' text field");
            const selectedReasonValues = Object.values(selectedReasons);
            let noneSelected = true;

            selectedReasonValues.forEach(reason => {
                if (reason) noneSelected = false;
            });
            if (noneSelected) errors.push('No Reason Selected');
            if (inactivedate < startdate)
                errors.push(
                    `Select an accurate Inactive Date. The Inactive Date should be after the Medication Start Date which is ${startDtStr}`
                );

            if (errors.length === 0) return;

            return (
                <div className={cn(modalcss.error)}>
                    <p>Please fix the following:</p>
                    <ul>
                        {errors.map(err => (
                            <li key={err}>{err}</li>
                        ))}
                    </ul>
                </div>
            );
        }
    };

    if (!show) return '';

    return (
        <Modal
            title={t('reconciliation.inactiveModal.title')}
            onHidden={() => setShow(false)}
        >
            <form>
                <div className="modal-header" style={{padding: 0}} />
                <div className="modal-body form-group">
                    <label>
                        {t('reconciliation.inactiveModal.inactiveReasons')}
                    </label>

                    {getInactiveReasonsInputs()}
                    <DatePicker
                        id="datePickerId"
                        label={<label>{t('headers.inactiveDate')}</label>}
                        className={css.datePicker}
                        inputItemClassName={css.datePadding}
                        placeholder={t('headers.inactiveDate')}
                        ariaLabel="The date"
                        navigationType="dropdown"
                        disablePast={false}
                        onChange={date => handleDateChange(date)}
                    />
                    {getErrorMessage()}
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => setShow(false)}
                    >
                        {t('reconciliation.inactiveModal.close')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={{background: 'rgb(32, 158, 145)'}}
                        onClick={() => saveInactiveReasons()}
                    >
                        {t('reconciliation.inactiveModal.inactive')}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

InactiveModal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    selectedMed: PropTypes.object,
    getMedication: PropTypes.func,
};

export default InactiveModal;
