import {useSelector} from 'react-redux';
import format from 'date-fns/format';
import setHours from 'date-fns/setHours';
import css from './Logbook.scss';
import cn from 'classnames';
import {bgRangeClass} from './RangeClass';
import {getValue} from './BgConstants';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import {intlFormat} from 'date-fns';
import Arg from '@livongo/arg';
import {useState, useEffect} from 'react';

const TimeView = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const [code, setCode] = useState(Arg('memberlocale'));
    const filter = useSelector(state => state.logBookFilters);
    const timeData = useSelector(state => state.bgsReducer.timeView);
    const timeSummary = useSelector(state => state.bgsReducer.timeSummary);
    const timeFooterSummary = useSelector(state => state.bgsReducer.tagSummary);
    const timeIntervals = [];
    const localeCode = Arg('memberlocale');
    const [header, setHeader] = useState('');

    for (let i = 0; i < 24; i += 3) {
        timeIntervals.push({
            start: setHours(new Date(), i),
            end: setHours(new Date(), i + 3),
            dataKey: `${i}To${i + 3}`,
        });
    }

    useEffect(() => {
        if (code === 'en-CA' || code === 'fr-CA' || code === 'es-US') {
            setCode('en-US');
        } else if (code === 'en-ES') {
            setCode('es-ES');
        }
    }, [code]);

    const getOORText = () => {
        if (!filter.inludeOOR) {
            return (
                <div className="row">
                    <div className={cn('col-xs-12', css['inform-text'])}>
                        {t('common.OOR')}
                    </div>
                </div>
            );
        }
    };

    const getTimeRows = () => {
        if (!timeData || timeData.length === 0) {
            return (
                <tr className={css['logbook-table-row']}>
                    <td className={css['no-data-row']} colSpan="10">
                        {t('noData')}
                    </td>
                </tr>
            );
        }

        return timeData.map((bg, i) => {
            return (
                <tr key={i} className={css['logbook-table-row']}>
                    <td className={css['date-table']}>
                        {intlFormat(
                            new Date(bg.bgDate),
                            {
                                weekday: 'short',
                                month: 'numeric',
                                day: 'numeric',
                            },
                            {
                                locale: code,
                            }
                        )}
                    </td>
                    {timeIntervals.map((timeInterval, j) => {
                        return (
                            <td key={j} className={css['data-table']}>
                                {bg[j]
                                    ? bg[j].map((bgTime, k) => {
                                          return (
                                              <span
                                                  key={k}
                                                  className={css['bg-value']}
                                              >
                                                  <span
                                                      className={cn(
                                                          css['bg-range-icon'],
                                                          css[
                                                              bgRangeClass(
                                                                  bgTime
                                                              )
                                                          ]
                                                      )}
                                                  />
                                                  <span
                                                      className={
                                                          css['bg-value-number']
                                                      }
                                                  >
                                                      {getValue(bgTime)}{' '}
                                                      {t('common.at')}{' '}
                                                      {intlFormat(
                                                          new Date(
                                                              bgTime.timestamp
                                                          ),
                                                          {
                                                              hour: 'numeric',
                                                              minute: 'numeric',
                                                          },
                                                          {
                                                              locale: code,
                                                          }
                                                      )}
                                                  </span>
                                              </span>
                                          );
                                      })
                                    : ''}
                            </td>
                        );
                    })}
                    <td
                        className={cn(
                            css['stats-table'],
                            css['logbook-result']
                        )}
                    >
                        <span>
                            Avg:{' '}
                            <span className={css['bg-value-number']}>
                                {bg.avg}
                            </span>{' '}
                            <span>mg/dL</span>
                            <br />
                            {t('common.checks')}: {bg.count}
                        </span>
                    </td>
                </tr>
            );
        });
    };

    const headerRender = checkLocale => {
        if (checkLocale === 'es-ES') {
            return timeIntervals.map((timeInterval, i) => {
                return (
                    <th key={i} className={css['data-table']}>
                        {format(timeInterval.start, 'HH')} -{' '}
                        {format(timeInterval.end, 'HH')}
                        <br />
                        (mg/dL)
                    </th>
                );
            });
        } else
            return timeIntervals.map((timeInterval, i) => {
                return (
                    <th key={i} className={css['data-table']}>
                        {format(timeInterval.start, 'h aaa')} -{' '}
                        {format(timeInterval.end, 'h aaa')}
                        <br />
                        (mg/dL)
                    </th>
                );
            });
    };

    useEffect(() => {
        setHeader(headerRender(localeCode));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localeCode]);

    return (
        <div
            className={cn(
                css['horizontal-scroll'],
                'panel',
                css['meal-time-view']
            )}
        >
            <div className={css['panel-heading']}>
                <h3 className={css['panel-title']}>{t('timeView.header')}</h3>
            </div>

            <div className={css['panel-body']}>
                <div className={css['logbook-table-body']} id="table-time-view">
                    <table className={cn(css.table, css['table-striped'])}>
                        <thead className={css['logbook-table-header']}>
                            <tr>
                                <th className={css['date-table']}>Date</th>
                                {header}
                                <th className={css['stats-table']} />
                            </tr>
                        </thead>

                        <tbody>{getTimeRows()}</tbody>

                        <tfoot className={css['logbook-result']}>
                            <tr>
                                <td className={css['date-table']} />
                                {timeIntervals.map((timeInterval, i) => {
                                    return (
                                        <td
                                            key={i}
                                            className={css['data-table']}
                                        >
                                            <span>
                                                Avg:{' '}
                                                <span
                                                    className={
                                                        css['bg-value-number']
                                                    }
                                                >
                                                    {timeSummary[i].avg}
                                                </span>
                                                <br />
                                                {t('common.checks')}:
                                                {timeSummary[i].count}
                                            </span>
                                        </td>
                                    );
                                })}
                                <td className={css['stats-table']}>
                                    <br />
                                    <span>
                                        {/* <!-- use the values from meal tag because the data are the same and there is no need to recalculate them--> */}
                                        {t('common.summary')} Avg:{' '}
                                        <span
                                            className={css['bg-value-number']}
                                        >
                                            {timeFooterSummary[-2].avg}
                                        </span>{' '}
                                        <span>mg/dL</span>
                                        <br />
                                        {t('common.summary')}{' '}
                                        {t('common.checks')}:
                                        {timeFooterSummary[-2].count}
                                        <br />
                                    </span>
                                    <br />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                {getOORText()}
            </div>
        </div>
    );
};

export default TimeView;
