const FoodUtils = {
    getLogTime(data) {
        const metadata = data.hasImageMeta() ? data.getImageMeta() : null;

        if (data.hasCreateTime()) {
            return data.getCreateTime();
        }

        if (metadata && metadata.hasCaptureTime()) {
            return metadata.getCaptureTime();
        }

        return data.getUploadTime();
    },

    // https://github.com/grpc/grpc/issues/2227
    formatQuantity(qty, decimal = 3) {
        // if a qty doesn't have a decimal
        // return the whole number
        if (qty % 1 === 0) {
            return parseInt(qty, 10);
        }

        // parseFloat removes trailing zeroes
        // if toFixed returns 2.300
        return parseFloat(qty.toFixed(decimal));
    },
    /*
        calculate different unit types.
        convert macronutrients from g to kcal
     */
    convertMicroNutrient(data, target) {
        if (!data) return;

        return {
            nutrientCode: data?.getNutrientCode(),
            code: data?.getNutrientCode().replace('_target', ''),
            value: Math.round(data?.getValue()),
            unit: data?.getUnit(),
            min: data?.getMin?.()?.getValue?.(),
            max: data?.getMax?.()?.getValue?.(),
            status: data?.getStatusCode?.(),
            targetValue: target,
        };
    },
    // get percentage for macro nutrients
    getPercentage(macro, cals) {
        return Math.round((macro / cals || 0) * 100);
    },
    // sum calories of macros
    getTotalCalories(macroArray) {
        return macroArray.reduce(
            (total, item) => total + (item.weight || 0),
            0
        );
    },
    getMacroNutrients(data) {
        const breakDownList = [];

        data.forEach(item => {
            const detailsList = item?.getDetailsList?.() || [];
            const details = [];

            // micro nutrients like fat, trans_fat...
            detailsList.forEach(micro => {
                details.push({
                    nutrientCode: micro?.getNutrientCode?.(),
                    calorie: this.formatQuantity(micro?.getCalorie?.(), 1),
                    calorieUnit: micro?.getCalorieUnit?.(),
                    weight: this.formatQuantity(micro?.getWeight?.(), 1),
                    weightUnit: micro?.getWeightUnit?.(),
                    percent: Math.round(micro?.getPercentage?.()),
                });
            });

            // get calorie total
            const total = {
                totalCalorie: this.formatQuantity(
                    item?.getTotalCalorie?.() || 0,
                    1
                ),
                calorieUnit: item?.getCalorieUnit?.(),
                totalWeight: this.formatQuantity(
                    item?.getTotalWeight?.() || 0,
                    1
                ),
                weightUnit: item?.getWeightUnit?.(),
            };

            // get macro nutrient info
            breakDownList.push({
                nutrientCode: item?.getCalorieBreakdown?.().getNutrientCode?.(),
                percent: Math.round(
                    item?.getCalorieBreakdown?.().getValue?.() || 0
                ),
                min: item?.getCalorieBreakdown?.().getMin?.().getValue?.(),
                max: item?.getCalorieBreakdown?.().getMax?.().getValue?.(),
                status: item?.getCalorieBreakdown?.().getStatusCode?.(),
                total,
                weight: this.formatQuantity(item?.getTotalWeight?.() || 0, 1),
                details,
            });
        });

        return breakDownList;
    },
    getFocuses(optionList) {
        const options = [];

        optionList.forEach(item => {
            options.push({
                optionId: item?.getOptionId?.(),
                optionCode: item?.getOptionCode?.(),
                defaultSelected: item?.getDefaultSelected?.()?.getValue?.(),
                selected: item?.getSelected?.()?.getValue?.(),
                isSelected: item?.getSelected?.(),
                targetValue: item
                    ?.getTarget?.()
                    ?.getTargetValue?.()
                    ?.getValue?.(),
                targetMin: item?.getTarget?.()?.getMin?.()?.getValue?.(),
                targetMax: item?.getTarget?.()?.getMax?.()?.getValue?.(),
                targetSelected: item
                    ?.getTarget?.()
                    ?.getSelected?.()
                    ?.getValue?.(),
            });
        });

        return options;
    },
};

export default FoodUtils;
