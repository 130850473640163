const inactiveReasons = [
    {inactiveReasonId: 1, inactiveReasonName: 'Cost'},
    {inactiveReasonId: 2, inactiveReasonName: 'Access'},
    {inactiveReasonId: 3, inactiveReasonName: 'Side effects'},
    {inactiveReasonId: 4, inactiveReasonName: 'Felt better'},
    {inactiveReasonId: 5, inactiveReasonName: 'Did not work'},
    {inactiveReasonId: 6, inactiveReasonName: 'Forgot to take'},
    {inactiveReasonId: 7, inactiveReasonName: 'Against beliefs'},
    {
        inactiveReasonId: 8,
        inactiveReasonName: 'No longer need according to doctor',
    },
    {inactiveReasonId: 9, inactiveReasonName: 'Switched to generic drug'},
    {
        inactiveReasonId: 10,
        inactiveReasonName: 'Switched to combination drug',
    },
    {inactiveReasonId: 11, inactiveReasonName: 'Other'},
    {inactiveReasonId: 12, inactiveReasonName: 'DUPLICATE'},
];

export default function medicationsLists(
    state = {
        inactiveReasons,
        inactiveMedications: [],
        activeMedications: [],
        importedMedications: [],
    },
    {type, payload}
) {
    switch (type) {
        case 'INACTIVE_MEDICATIONS': {
            return {
                ...state,
                inactiveMedications: [...payload],
            };
        }

        case 'ACTIVE_MEDICATIONS': {
            return {
                ...state,
                activeMedications: [...payload],
            };
        }

        case 'IMPORTED_MEDICATIONS': {
            return {
                ...state,
                importedMedications: [...payload],
            };
        }

        default:
            return state;
    }
}
