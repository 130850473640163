const filter = {
    days: 30,
    weekDay: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
    },
    inludeOOR: true,
    includeManual: true,
};

export default function logBookFilters(state = {...filter}, {type, payload}) {
    switch (type) {
        case 'FILTER_CHANGE': {
            return {
                ...payload,
            };
        }

        default:
            return state;
    }
}
