import format from 'date-fns/format';
import get from 'lodash/get';
import FoodUtils from './utils/food-utils';
import css from './FoodlogCard.scss';

const FoodlogMealCard = ({meals}) => {
    const FoodlogMealCardContent = () => {
        return (
            <>
                {meals.map((meal, index) => {
                    return (
                        <div key={index}>
                            <div>
                                <p className={css['food-time']}>
                                    {get(meal.createTime, 'timeStampNoTimeZone')
                                        ? format(
                                              meal.createTime
                                                  .timeStampNoTimeZone,
                                              'hh:mm aaa'
                                          )
                                        : ''}
                                </p>
                                {meal.nixFood &&
                                    meal.nixFood.array &&
                                    meal.nixFood.array.length > 0 && (
                                        <span>
                                            {meal.nixFood.array[1]},{' '}
                                            {meal.nixFood.array[3].length ===
                                            0 ? (
                                                <span> 0 </span>
                                            ) : (
                                                <span>
                                                    {FoodUtils.formatQuantity(
                                                        meal.nixFood
                                                            .getPortionQuantity?.()
                                                            ?.getValue?.()
                                                    )}
                                                </span>
                                            )}{' '}
                                            {meal.nixFood.array[4]}
                                        </span>
                                    )}
                                {meal.nixFood &&
                                    meal.nixFood.array &&
                                    meal.nixFood.array.length > 0 &&
                                    meal.note &&
                                    meal.note.length > 0 &&
                                    meal.note.length > 0 && <span>, </span>}
                                {meal.note && meal.note.length > 0 && (
                                    <span className={css['food-note']}>
                                        {meal.note}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    return FoodlogMealCardContent();
};

export default FoodlogMealCard;
