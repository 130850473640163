import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import APIUtils from '@livongo/utils/api';
import {config} from '../common/headers';

function getHtnReadings(daysNumber, token) {
    const params = {
        start: format(subDays(new Date(), daysNumber), 'yyyy-MM-dd'),
    };

    return new Promise((resolve, reject) => {
        APIUtils.get('/v1/users/me/reading/bp/stats', params, config(token))
            .then(data => resolve(data.data))
            .catch(err => reject(err));
    });
}

function getHtnStats(days, mock, token) {
    const params = {
        extended_stats: true, // eslint-disable-line camelcase
        durations: days,
    };

    return new Promise((resolve, reject) => {
        APIUtils.get('/v1/users/me/reading/weight/stats', params, config(token))
            .then(data => resolve(data.data))
            .catch(err => reject(err));
    });
}

export const HtnResource = {
    getHtnReadings,
    getHtnStats,
};
