/* eslint-disable camelcase */
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import invert from 'lodash/invert';
import med from '@livongo/med/lib/livongo/protobuf/grpc/external/med/med_grpc_web_pb';
import format from 'date-fns/format';
import {toUTC} from 'common/dates';

const GRPC_URL = process.env.gRPC_URL;
const MedPromiseClient = new med.MedAdherencePromiseClient(GRPC_URL);
const MedAdjustmentClient = new med.MedAdjustmentPromiseClient(GRPC_URL);
// eslint-disable-next-line
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

// enable debugging grpc calls
enableDevTools([MedPromiseClient, MedAdjustmentClient]);

const getCoachingSignals = userToken => {
    return new Promise((resolve, reject) => {
        const request = new med.GetCoachingSignalsRequest();

        request.setName('user/liv/me/coachingSignals');
        const metadata = {
            Authorization: `Bearer ${userToken}`,
        };

        MedPromiseClient.getCoachingSignals(request, metadata)
            .then(resp => {
                const entries = map(resp, () => {
                    if (isEmpty(resp.getCoachingSignalsList())) {
                        return null;
                    } else {
                        return {
                            flagCoachingType: resp
                                .getCoachingSignalsList()[0]
                                .getFlagType(),
                        };
                    }
                });

                resolve(entries);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const getStatinSignal = userToken => {
    return new Promise((resolve, reject) => {
        const request = new med.ListAdjustmentSignalsRequest();

        request.setParent('user/liv/me/coachingSignals');
        const metadata = {
            Authorization: `Bearer ${userToken}`,
        };

        MedAdjustmentClient.listAdjustmentSignals(request, metadata).then(
            resp => {
                const entries = map(resp, () => {
                    if (isEmpty(resp.getCoachingSignalsList())) {
                        return null;
                    } else {
                        return {
                            flagType: invert(med.FlagType.Values)[
                                resp.getCoachingSignalsList()[0].getFlagType()
                            ],
                            createdUtc: isEmpty(resp.getCoachingSignalsList())
                                ? ''
                                : format(
                                      toUTC(
                                          parseInt(
                                              resp
                                                  .getCoachingSignalsList()[0]
                                                  .getCreatedUtc()
                                                  .getSeconds(),
                                              10
                                          ) * 1000
                                      ),
                                      'MM/dd/yy'
                                  ),
                            medCategory: invert(med.MedCategory.Values)[
                                resp
                                    .getCoachingSignalsList()[0]
                                    .getMedCategory()
                            ],
                            signalReason: invert(
                                med.AdjustmentSignalReason.Values
                            )[
                                resp
                                    .getCoachingSignalsList()[0]
                                    .getSignalReason()
                            ],
                        };
                    }
                });

                resolve(entries);
            },
            err => {
                reject(err);
            }
        );
    });
};

const getAdherenceSummary = proxyToken => {
    return new Promise((resolve, reject) => {
        const request = new med.GetAdherenceSummaryRequest();

        request.setName('user/liv/me/medAdherenceSummary');
        const metadata = {
            Authorization: `Bearer ${proxyToken}`,
        };

        MedPromiseClient.getAdherenceSummary(request, metadata).then(
            resp => {
                const entries = map(resp, () => {
                    return {
                        pastReviewsList: setPastReviewList(
                            resp.getPastReviewsList()
                        ),
                        nonAdherenceFlag: resp.getNonAdherenceFlag(),
                        barriers: resp.getUseCoachValues()
                            ? parseBarriers(resp.getCoachBarriersList())
                            : resp.getDsBarriers(),
                        dsContext: resp.getDsContext(),
                        isLastReviewEditable: resp.getIsLastReviewEditable(),
                        availableBarriersList: resp.getAvailableBarriersList(),
                    };
                });

                resolve(entries);
            },
            err => {
                console.log(err); // eslint-disable-line no-console
                reject(err);
            }
        );
    });
};

function parseBarriers(barriersList) {
    const barriersMapping = [
        {id: 1, name: 'Access'},
        {id: 2, name: 'Complexity'},
        {id: 3, name: 'Costs'},
        {id: 4, name: 'Education gaps/beliefs'},
        {id: 5, name: 'Hypo potential'},
        {id: 6, name: 'Side effects'},
        {id: 7, name: 'Time'},
        {id: 8, name: 'Member not interested in taking statin'},
        {id: 9, name: 'Member discontinued statin'},
        {id: 20, name: 'Other'},
    ];
    let barriers = '';

    if (barriersList.length > 0) {
        const list = [];

        barriersList.forEach(item => {
            barriersMapping.forEach(barrier => {
                if (barrier.id === item) {
                    list.push(barrier.name);
                }
            });
        });
        barriers = list.join(', ');
    }

    return barriers;
}

function setPastReviewList(reviewList) {
    const list = [];

    if (reviewList) {
        for (let i = 0; i < reviewList.length; i++) {
            const obj = {
                review_id: reviewList[i].getReviewId(),
                created_time: format(
                    reviewList[i].getCreatedTime() * 1000,
                    'MM/dd/yy hh:mm a'
                ),
                coach_login: reviewList[i].getCoachLogin(),
                nonAdherenceFlag: reviewList[i].getNonAdherenceFlag(),
                barriers_list: reviewList[i].getBarriersList(),
                coach_notes: reviewList[i].getCoachNote(),
            };

            list.push(obj);
        }
    }

    return list;
}

const createAdherenceReview = (proxyToken, newAdherence) => {
    return new Promise((resolve, reject) => {
        const request = new med.CreateAdherenceReviewRequest();
        const ar = new proto.com.livongo.protobuf.grpc.external.med.AdherenceReview(); // eslint-disable-line no-undef

        ar.setCoachLogin(newAdherence.coach_login);
        ar.setNonAdherenceFlag(newAdherence.nonAdherenceFlag);
        ar.setBarriersList(newAdherence.barriers_list);
        ar.setCoachNote(newAdherence.coach_notes);
        request.setParent('user/liv/me/medAdherenceReviews');
        request.setAdherenceReview(ar);

        const metadata = {
            Authorization: `Bearer ${proxyToken}`,
        };

        if (proxyToken) {
            MedPromiseClient.createAdherenceReview(request, metadata).then(
                resp => {
                    const entries = map(resp, () => {
                        return {
                            reviewId: resp.getReviewId().getValue(),
                            createdTime: format(
                                resp.getCreatedTime() * 1000,
                                'MM/dd/yy hh:mm a'
                            ),
                            coachLogin: resp.getCoachLogin(),
                            barriers: resp.getBarriersList(),
                            coachNotes: resp.getCoachNote(),
                        };
                    });

                    resolve(entries);
                },
                err => {
                    console.log(err); // eslint-disable-line no-console
                    reject(err);
                }
            );
        }
    });
};

const updateAdherenceReview = (proxyToken, data) => {
    return new Promise((resolve, reject) => {
        const request = new med.UpdateAdherenceReviewRequest();
        const up = new proto.com.livongo.protobuf.grpc.external.med.AdherenceReview(); // eslint-disable-line no-undef

        up.setReviewId(data.review_id);
        up.setCoachLogin(data.coach_login);
        up.setNonAdherenceFlag(data.nonAdherenceFlag);
        up.setBarriersList(data.barriers_list);
        up.setCoachNote(data.coach_notes);
        request.setName('user/liv/me/medAdherenceReviews/44');
        request.setAdherenceReview(up);

        const metadata = {
            Authorization: `Bearer ${proxyToken}`,
        };

        MedPromiseClient.updateAdherenceReview(request, metadata).then(
            resp => {
                const entries = map(resp, () => {
                    return {
                        reviewId: resp.getReviewId().getValue(),
                        createdTime: format(
                            resp.getCreatedTime() * 1000,
                            'MM/dd/yy hh:mm a'
                        ),
                        coachLogin: resp.getCoachLogin(),
                        barriers: resp.getBarriersList(),
                        coachNotes: resp.getCoachNote(),
                    };
                });

                resolve(entries);
            },
            err => {
                console.log(err); // eslint-disable-line no-console
                reject(err);
            }
        );
    });
};

export const MedService = {
    getStatinSignal,
    getCoachingSignals,
    getAdherenceSummary,
    createAdherenceReview,
    updateAdherenceReview,
};
