import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const FoodlogUnavailLog = ({meals}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const FoodlogUnavailLogContent = () => {
        return (
            <div className="FoodlogCarousel-carousel FoodlogCarousel-no-logs">
                <div className="FoodlogCarousel-display-content">
                    <div>
                        <span>{t('unavailable.logs')}</span>
                    </div>
                </div>
            </div>
        );
    };

    return FoodlogUnavailLogContent();
};

export default FoodlogUnavailLog;
