import {useState, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import result from 'lodash/result';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import {UserResource} from '../res_user';
import {getWeightInfo} from './WeightInfo';
import {WeightService} from './WeightService';
import css from '../Logbook.scss';
import Select from '@livongo/ui/Select';
import Chart from '@livongo/ui/Chart';
import DateLocaleContext from '@livongo/ui/Context/DateLocale';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const Weight = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {formatLocale} = useContext(DateLocaleContext);
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.logBookAuth.user);
    // const [userInfo, setUserInfo] = useState();
    const [healthProfile, setHealthProfile] = useState({});
    const userToken = useSelector(state => state.logBookAuth);
    const weightData = useSelector(state => state.weightDataReducer);
    let {interval} = useSelector(state => state.logBookControls);

    interval -= 1;
    const {noWeightData, sections} = useSelector(
        state => state.logBookControls
    );
    const emptyValue = <p className={css['stats-value']}>-</p>;

    function extractFeetFromHeight(height) {
        if (height) {
            const feet = height / 12;

            return parseInt(feet, 10);
        }
    }

    function extractInchesFromHeight(height) {
        if (height) {
            const inches = height % 12;

            return parseInt(inches, 10);
        }
    }

    useEffect(() => {
        if (userToken.token) {
            UserResource.getHealthProfileSchema(userToken.token)
                .then(data => {
                    const profile = {};
                    const diabetes = result(
                        find(data, {id: 'diabetesType'}),
                        'value'
                    );

                    switch (diabetes) {
                        case 1:
                            profile.diabetesType = 'Type 1';
                            break;
                        case 2:
                            profile.diabetesType = 'Type 2';
                            break;
                    }
                    // set gender
                    const gender = result(find(data, {id: 'gender'}), 'value');

                    switch (gender) {
                        case 3:
                            profile.gender = 'Other';
                            break;
                        case 2:
                            profile.gender = 'Female';
                            break;
                        case 1:
                            profile.gender = 'Male';
                            break;
                    }
                    // set weight
                    profile.weight = result(
                        find(data, {id: 'weight'}),
                        'value'
                    );
                    const height = result(find(data, {id: 'height'}), 'value');

                    profile.height = {
                        feet: extractFeetFromHeight(height),
                        inch: extractInchesFromHeight(height),
                    };
                    profile.heightInInches = height;
                    setHealthProfile(profile);
                })
                .catch(
                    err => console.log("can't get health profile schema", err) // eslint-disable-line no-console
                );
        }
    }, [userToken.token]);

    const getTargetPercentage = () => {
        const start =
            get(weightData, 'overall.startWeightReading.normalizedWeightLb') ||
            '-';
        const goal =
            get(weightData, 'target.weight') ||
            get(weightData, 'target.target.weight') ||
            '-';

        if (!isUndefined(start) && !isUndefined(goal)) {
            return (Math.floor(((start - goal) / start) * 10000) / 100) * -1;
        }
    };

    const getIntervalDate = daysAgo => {
        return formatLocale(subDays(new Date(), daysAgo), 'MMM dd, yyyy');
    };

    const calculatedBMR = () => {
        let bmrResult;
        const bmr = userInfo
            ? 4.536 * healthProfile.weight +
              15.88 * healthProfile.heightInInches -
              5 * userInfo.age.years
            : 0;

        if (healthProfile.gender === 'Male') {
            bmrResult = (bmr + 5).toFixed(2);
        } else if (healthProfile.gender === 'Female') {
            bmrResult = (bmr - 161).toFixed(2);
        }

        return bmrResult;
    };

    const calculateBMI = weight => {
        if (isNaN(weight) || !healthProfile.heightInInches) {
            return;
        }

        // https://www.cdc.gov/nccdphp/dnpao/growthcharts/training/bmiage/page5_2.html
        return (
            703 *
            (weight /
                healthProfile.heightInInches /
                healthProfile.heightInInches)
        ).toFixed(2);
    };

    const changeInterval = val => {
        dispatch({
            type: 'INTERVALS',
            payload: val,
        });
        getWeightInfo(val, dispatch, userToken.token);
    };

    // Checks if exists, because lodash's get will mark as false if value is zero, and we'll use the values even if they're zero
    const exists = (obj, property) => {
        return !isUndefined(get(obj, property));
    };

    const getValue = property => {
        return !isUndefined(get(weightData, property))
            ? get(weightData, property)
            : '-';
    };

    const needsLb = property => {
        return !isUndefined(get(weightData, property)) ? 'lb' : '';
    };

    return (
        <div
            className="row"
            style={{
                display: sections.weight ? '' : 'none',
            }}
        >
            <div className="col-xs-12">
                <div className={css['section-content']}>
                    <p>
                        <b>{t('weight.headers.summary')}</b>
                    </p>
                    <div className={css['weight-stats']}>
                        <div className={css['weight-stats-row']}>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.current')}
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.overall')}
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.last30')}
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.starting')} BMI
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.current')} BMI
                                </h3>
                            </div>
                        </div>

                        <div className={css['weight-stats-row']}>
                            <div className={css['weight-stats-item']}>
                                <p className={css['stats-value']}>
                                    {getValue(
                                        'currentWeight.normalizedWeightLb'
                                    )}{' '}
                                    <span className={css['stats-unit']}>
                                        {needsLb(
                                            'currentWeight.normalizedWeightLb'
                                        )}
                                    </span>
                                </p>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <p className={css['stats-value']}>
                                    {getValue('overall.weightDiff')}{' '}
                                    <span className={css['stats-unit']}>
                                        {needsLb('overall.weightDiff')}{' '}
                                    </span>
                                    {getValue('overall.weightPercent') === '-'
                                        ? ''
                                        : getValue('overall.weightPercent')}
                                    {weightData?.overall?.weightPercent ? (
                                        <span className={css['stats-unit']}>
                                            %
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <p className={css['stats-value']}>
                                    {weightData.last30 > 0 ? '+' : ''}
                                    {weightData.last30?.toFixed(0) || '-'}{' '}
                                    <span className={css['stats-unit']}>
                                        {needsLb('last30')}
                                    </span>
                                </p>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <p className={css['stats-value']}>
                                    {calculateBMI(
                                        get(
                                            weightData,
                                            'overall.startWeightReading.normalizedWeightLb'
                                        )
                                    ) || '-'}
                                </p>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <p className={css['stats-value']}>
                                    {calculateBMI(
                                        getValue(
                                            'currentWeight.normalizedWeightLb'
                                        )
                                    ) || '-'}
                                </p>
                            </div>
                        </div>

                        <div className={css['weight-stats-row']}>
                            <div className={css['weight-stats-item']}>
                                {exists(
                                    weightData,
                                    'currentWeight.measurementTime'
                                ) ? (
                                    <p className={css['stats-info']}>
                                        {t('weight.subText.bodyTrace')}&nbsp;
                                        {format(
                                            new Date(
                                                get(
                                                    weightData,
                                                    'currentWeight.measurementTime'
                                                )
                                            ),
                                            'MM/dd/yyyy'
                                        )}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className={css['weight-stats-item']}>
                                {exists(
                                    weightData,
                                    'overall.noOfDaysPassed'
                                ) ? (
                                    <p className={css['stats-info']}>
                                        {get(
                                            weightData,
                                            'overall.noOfDaysPassed'
                                        )}{' '}
                                        {t('weight.subText.days')}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${css['weight-stats']} ${css['weight-stats-small']}`}
                    >
                        <div className={css['weight-stats-row']}>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.start')}
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.goal')}
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>
                                    {t('weight.headers.avgWeighIns')}
                                </h3>
                            </div>
                            <div className={css['weight-stats-item']}>
                                <h3 className={css['stats-title']}>BMR</h3>
                            </div>
                        </div>

                        <div className={css['weight-stats-row']}>
                            <div className={css['weight-stats-item']}>
                                {exists(
                                    weightData,
                                    'overall.startWeightReading.normalizedWeightLb'
                                ) ? (
                                    <p className={css['stats-value']}>
                                        {get(
                                            weightData,
                                            'overall.startWeightReading.normalizedWeightLb'
                                        )}{' '}
                                        <span className={css['stats-unit']}>
                                            lb
                                        </span>
                                    </p>
                                ) : (
                                    emptyValue
                                )}
                            </div>
                            <div className={css['weight-stats-item']}>
                                {exists(weightData, 'target.weight') ||
                                exists(weightData, 'target.target.weight') ? (
                                    <p className={css['stats-value']}>
                                        {get(weightData, 'target.weight') ||
                                            get(
                                                weightData,
                                                'target.target.weight'
                                            )}{' '}
                                        <span className={css['stats-unit']}>
                                            lb
                                        </span>{' '}
                                        {getTargetPercentage()}{' '}
                                        {getTargetPercentage() ? (
                                            <span className={css['stats-unit']}>
                                                %
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                ) : (
                                    emptyValue
                                )}
                            </div>
                            <div className={css['weight-stats-item']}>
                                {exists(
                                    weightData,
                                    'overall.averageWeightInsPerWeek'
                                ) ? (
                                    <p className={css['stats-value']}>
                                        {get(
                                            weightData,
                                            'overall.averageWeightInsPerWeek'
                                        )}
                                    </p>
                                ) : (
                                    emptyValue
                                )}
                            </div>
                            <div className={css['weight-stats-item']}>
                                <p className={css['stats-value']}>
                                    {calculatedBMR() || '-'}
                                </p>
                            </div>
                        </div>
                        {!noWeightData ? (
                            <div className={css['weight-stats-row']}>
                                <div className={css['weight-stats-item']}>
                                    <p className={css['stats-info']}>
                                        {t('weight.subText.on')}{' '}
                                        {format(
                                            new Date(
                                                get(
                                                    weightData,
                                                    'overall.startWeightReading.measurementTime'
                                                )
                                            ),
                                            'MM/dd/yyyy'
                                        )}
                                    </p>
                                </div>
                                <div className={css['weight-stats-item']}>
                                    {exists(weightData, 'created') ? (
                                        <p className={css['stats-info']}>
                                            {t('weight.subText.set')}{' '}
                                            {format(
                                                new Date(
                                                    get(weightStats, 'created') // eslint-disable-line no-undef
                                                ),
                                                'MM/dd/yyyy'
                                            )}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className={css['weight-stats-item']}>
                                    <p className={css['stats-info']}>
                                        {t('weight.subText.perWeek')}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className={css.separator} />

                    <div className="panel">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-xs-6">
                                    <p className={css['section-title']}>
                                        {t('weight.headers.weightReadings')}
                                    </p>
                                    <h3 className={css['stats-title']}>
                                        {getIntervalDate(interval)} -
                                        {getIntervalDate(0)}
                                    </h3>
                                </div>
                                <div
                                    className={`col-xs-3 ${css['float-right']}`}
                                >
                                    <Select
                                        label={
                                            <label>
                                                {t('weight.subText.days')}
                                            </label>
                                        }
                                        itemClassName={css.select_input}
                                        defaultValue="90"
                                        items={[7, 14, 30, 90].map(day => {
                                            const val = day.toString();

                                            return {label: val, value: val};
                                        })}
                                        onChange={e =>
                                            changeInterval(
                                                parseInt(e.value, 10)
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {get(weightData, 'weightReadings.last90.length') ? (
                        <Chart
                            settings={{
                                type: 'area',
                                scrollablePlotArea: {
                                    minWidth: 680,
                                },
                                spacingRight: 55,
                                events: {
                                    render() {
                                        const goal = Number(
                                            get(weightData, 'target.weight')
                                        );

                                        WeightService.drawGoalLine(
                                            {
                                                chart: this,
                                                goal,
                                            },
                                            get(
                                                weightData,
                                                'weightReadings.chartData'
                                            )
                                        );
                                    },
                                },
                            }}
                            tooltip={{
                                outside: false,
                                shared: true,
                                xDateFormat: '%m/%d/%y',
                                pointFormat:
                                    '<div style="font-size:10px">{point.normalizedTime} <br/><div style="font-size:12px"><span style="color:#0060fc">● </span> Weight: <b>{point.y}</b></div></div>',
                            }}
                            plotOptions={{
                                area: {
                                    fillColor: {
                                        linearGradient: {
                                            x1: 0,
                                            y1: 0,
                                            x2: 0,
                                            y2: 1,
                                        },
                                        // prettier-ignore
                                        stops: [[0, '#e0ebfd'], [1, '#ffffff']],
                                    },
                                },
                                series: {
                                    color: '#0060fc',
                                    lineWidth: 2,
                                    marker: {radius: 6},
                                },
                            }}
                            series={[
                                {
                                    data: weightData.weightReadings.chartData,
                                    name: 'Weight',
                                    marker: {
                                        enabled: true,
                                    },
                                },
                            ]}
                            title={{
                                text: 'Weights',
                            }}
                            xAxisSettings={{
                                title: {
                                    enabled: false,
                                },
                                type: 'datetime',
                                labels: {
                                    style: {
                                        color: '#666f7f',
                                        fontSize: '12px',
                                    },
                                },
                                min: subDays(new Date(), interval).getTime(),
                                max: new Date().getTime(),
                                tickInterval: 24 * 3600 * 1000, // 1 day in MS
                                startOnTick: true,
                            }}
                            legend={{
                                enabled: false,
                            }}
                            yAxisSettings={{
                                title: {
                                    enabled: false,
                                },
                                gridLineColor: 'transparent',
                                min: 0,
                            }}
                        />
                    ) : (
                        <p style={{marginTop: '20px'}}>
                            {t('weight.subText.noMeasurements')}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Weight;
