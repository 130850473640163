import Arg from '@livongo/arg';
import ProtoUtils from '../../common/proto-utils';
import getUnixTime from 'date-fns';

const {
    Food: {
        Date: ApiDate,
        AbsoluteTimestamp,
        FoodFocusClient,
        GetNutritionSummariesRequest,
        ListFoodLogFocusesRequest,
        GetUserHeightWeightInfoRequest,
    },
    Google: {Int32Value, BoolValue, Timestamp},
} = ProtoUtils;

const metadata = {
    Authorization: `Bearer ${Arg('authToken')}`,
};
const TZ_OFFSET = new Date().getTimezoneOffset() * -1;

const getUserHeightWeightInfo = userToken => {
    return new Promise((resolve, reject) => {
        const request = new GetUserHeightWeightInfoRequest();

        request.setName('user/liv/me/focus');
        FoodFocusClient.getUserHeightWeightInfo(request, metadata).then(
            resp => {
                const entries = resp.getFoodLogList().map(item => {
                    return {
                        name: '',
                        note: '',
                    };
                });

                resolve(entries);
            },
            err => {
                reject(err);
            }
        );
    });
};

const getFoodNutritionSummaries = ({day, month, year}) => {
    return new Promise((resolve, reject) => {
        const request = new GetNutritionSummariesRequest();
        const date = new ApiDate();
        const tzOffset = new Int32Value();

        date.setDay(day);
        date.setMonth(month);
        date.setYear(year);
        tzOffset.setValue(TZ_OFFSET);
        request.setName('user/liv/me/nutrition/daily');
        request.setSummaryDate(date);
        request.setTzOffset(tzOffset);
        FoodFocusClient.getNutritionSummaries(request, metadata).then(
            response => {
                const entries = response?.getSummary();

                resolve(entries);
            },
            err => {
                reject(err);
            }
        );
    });
};

const getListFoodLogFocuses = (selectedDate = null) => {
    const request = new ListFoodLogFocusesRequest();
    const startTs = new Timestamp();
    const startAbs = new AbsoluteTimestamp();
    const tzOffset = new Int32Value();

    request.setName('user/liv/me/focus');
    request.setSelectedOnly(new BoolValue().setValue(false));

    if (selectedDate) {
        tzOffset.setValue(TZ_OFFSET);
        startTs.setSeconds(Math.round(getUnixTime(selectedDate)));
        startAbs.setTimeStamp(startTs);
        startAbs.setTzOffset(tzOffset);
        request.setFocusActiveTime(startAbs);
    }

    return FoodFocusClient.listFoodLogFocuses(request, metadata).then(
        response => {
            const list = response?.getFocusesList?.();

            if (list && list.length > 0) return list[0];
            else return list;
        }
    );
};

const FoodService = {
    getUserHeightWeightInfo,
    getFoodNutritionSummaries,
    getListFoodLogFocuses,
};

export default FoodService;
