const Rebranding = () => {
    const sharCaseDiv = {
        backgroundColor: 'white',
        margin: 'auto auto',
        display: 'flex',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        color: 'black',
    };
    const livongoLogo = {
        width: '350px',
        height: '140px',
    };

    return (
        <div style={sharCaseDiv}>
            <div style={{margin: 'auto', textAlign: 'center'}}>
                <img
                    style={livongoLogo}
                    src="https://cdn8.livongo.com/img/retina/logo_color.png"
                />

                <h2 style={{color: 'black'}}>Having trouble logging in?</h2>

                <a
                    rel="noreferrer"
                    href="https://clinical-livongo.force.com/SupportCaseRequestPage"
                    target="_blank"
                >
                    Create a Support Case.
                </a>
            </div>
        </div>
    );
};

export default Rebranding;
