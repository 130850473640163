import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import APIUtils from '@livongo/utils/api';
import subYears from 'date-fns/subYears';
import {config} from '../common/headers';

const medStatusList = {
    MEDSTATUS_ACTIVE: 1,
    MEDSTATUS_INACTIVE: 2,
    MEDSTATUS_REMOVED: 3,
};

function isMedActive(medInfo) {
    return get(medInfo, 'medStatusId') === medStatusList.MEDSTATUS_ACTIVE;
}

function isMedInactive(medInfo) {
    const pastYear = subYears(new Date(), 1).getTime();
    const inactiveDate = new Date(get(medInfo, 'inactiveDate')).getTime();

    // inactive medication is shown only if the "Date Inactive" date is within the past year
    return (
        get(medInfo, 'medStatusId') === medStatusList.MEDSTATUS_INACTIVE &&
        inactiveDate >= pastYear
    );
}

function isMedImported(medInfo) {
    return (
        isUndefined(get(medInfo, 'medStatusId')) &&
        !isEmpty(get(medInfo, 'drugName'))
    );
}

function getMedStatusList() {
    return medStatusList;
}

function getImportedMedication(token) {
    return APIUtils.get('/v1/users/me/meds/imported');
}

function getMedication(token, reviewId) {
    return APIUtils.get(
        `/v1/users/me/meds/reviewed/v2?currentReviewId=${reviewId}`
    ).catch(err => console.log(err)); // eslint-disable-line no-console
}

function makeMedicationActive(reviewId, token, medicationInfo) {
    return APIUtils.post(
        `/v1/users/me/coachMedReviews/${reviewId}/meds/v2`,
        medicationInfo
    ).catch(err => console.log(err)); // eslint-disable-line no-console
}

function makeMedicationInactive(reviewId, token, medicationInfo) {
    return APIUtils.put(
        `/v1/users/me/coachMedReviews/${reviewId}/meds`,
        medicationInfo
    );
}

function getIdMappings() {
    return APIUtils.get('/v1/med/idMappings').catch(err => console.log(err)); // eslint-disable-line no-console
}

function getDrugNames(prefix) {
    return APIUtils.get(
        `/v1/drugs/drugsByHint/${prefix}?includeStrength=false`
    ).catch(
        err => console.log(err) // eslint-disable-line no-console
    );
}

function getDrugInfo(nameId) {
    return APIUtils.get(`/v1/drugs/drugInfo/${nameId}/v2`).catch(
        err => console.log(err) // eslint-disable-line no-console
    );
}

/**
 * the call returns the reviewId used for each POST, PUT or DELETE
 * @param token
 * @returns {*}
 */
function coachMedReviews(token) {
    return APIUtils.post('/v1/users/me/coachMedReviews').catch(
        err => console.log(err) // eslint-disable-line no-console
    );
}

function lastReview(token) {
    return APIUtils.get('/v1/users/me/meds/lastReview').catch(
        err => console.log(err) // eslint-disable-line no-console
    );
}

function getMedOptStatus(token) {
    return APIUtils.get(
        `${process.env.gRPC_URL}/service-med-opt/users/me/history`
    ).catch(
        err => console.log(err) // eslint-disable-line no-console
    );
}

function reviewedToday(token, reviewId, data) {
    return APIUtils.put(
        `/v1/users/me/coachMedReviews/${reviewId}/v2`,
        data
    ).catch(err => console.log(err)); // eslint-disable-line no-console
}

function medHsrFlag(token) {
    return APIUtils.get(
        '/service-med/v1/users/me/hsrInfo',
        null,
        config('authToken'),
        {
            useGRPC: true,
        }
    ).catch(err => console.log(err)); // eslint-disable-line no-console
}

const MedicationResource = {
    isMedActive,
    isMedInactive,
    isMedImported,
    getMedStatusList,
    getImportedMedication,
    getMedication,
    makeMedicationActive,
    makeMedicationInactive,
    getIdMappings,
    getDrugNames,
    getDrugInfo,
    coachMedReviews,
    lastReview,
    reviewedToday,
    medHsrFlag,
    getMedOptStatus,
};

export default MedicationResource;
