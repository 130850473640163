import {useState, useEffect, useContext} from 'react';
import css from './FoodlogHeader.scss';
import {useSelector, useDispatch} from 'react-redux';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isSameYear from 'date-fns/isSameYear';
import isSameMonth from 'date-fns/isSameMonth';
import addDays from 'date-fns/addDays';
import endOfWeek from 'date-fns/endOfWeek';
import differenceInDays from 'date-fns/differenceInDays';
import FoodService from './utils/FoodService';
import RightArrow from '@livongo/icons/AngleRight';
import LeftArrow from '@livongo/icons/AngleLeft';
import DateLocaleContext from '@livongo/ui/Context/DateLocale';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import Arg from '@livongo/arg';
import APIUtils from '@livongo/utils/api';

const FoodlogHeader = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const {formatLocale} = useContext(DateLocaleContext);
    const firstDay = useSelector(
        state => state.foodlogReducer.initialFirstDayOfWeek
    );
    const dispatch = useDispatch();
    const [logs, setLogs] = useState({});
    const [templateAvailable, setTemplateAvailable] = useState(false);
    // is for modal to toggle
    const [templateModal, setTemplateModal] = useState(false);
    const [text, setText] = useState('');

    const getFeedbackTemplate = () => {
        return new Promise(resolve => {
            const date = new Date();

            date.setDate(date.getDate() - 7);
            APIUtils.get(
                `${
                    process.env.gRPC_URL
                }/service-food/v1/users/me/food/feedback/template/${date
                    .toISOString()
                    .slice(0, 10)}`,
                {
                    headers: {
                        Authorization: `Bearer ${Arg('authToken')}`,
                        'Content-Type': 'application/json',
                        'content-length': 152,
                        'access-control-expose-headers': 'X-REQUEST-UUID',
                        'x-envoy-upstream-service-time': 8,
                        server: 'envoy',
                    },
                }
            )
                .then(data => resolve(data.data))
                .catch(err =>
                    // eslint-disable-next-line no-console
                    console.error(
                        'error while fetching food-log feedback template API',
                        err
                    )
                );
        });
    };

    useEffect(() => {
        getFeedbackTemplate().then(data => {
            if (
                data &&
                data.feedbackTemplate &&
                data.feedbackTemplate.length > 1
            ) {
                setTemplateAvailable(true);
                setText(data.feedbackTemplate);
            }
        });
    }, []);

    const openFeedbackTemplate = () => {
        setTemplateModal(true);
    };

    const submit = () => {
        setTemplateModal(false);
    };

    const copy = () => {
        const tempInput = document.createElement('textarea');

        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setTemplateModal(false);
    };

    const inputHandler = event => {
        setText(event.target.value);
    };

    const getHistory = () => {
        FoodService.getFoodLogHistory(firstDay).then(meals => {
            const filteredLogs = filterLogs(meals);
            const logsAvg = (filteredLogs.length / 7).toFixed(1);

            setLogs({
                length: filteredLogs.length,
                average: logsAvg,
            });

            dispatch({
                type: 'LOGS',
                payload: filteredLogs,
            });
        });
    };

    useEffect(() => {
        getHistory();
    }, [firstDay]); // eslint-disable-line react-hooks/exhaustive-deps

    function filterLogs(logsToFilter) {
        return filter(logsToFilter, log => {
            const logDate = get(log, 'createTime.timeStampNoTimeZone');

            return (
                logDate >= String(Math.floor(firstDay)) &&
                logDate < String(Math.floor(addDays(firstDay, 7)))
            );
        });
    }

    const changeDay = direction => {
        dispatch({
            type: direction,
        });
    };

    const getWeekText = () => {
        const endOfThisWeek = endOfWeek(firstDay, {weekStartsOn: 0});
        const sameYear = isSameYear(firstDay, endOfThisWeek);
        const sameMonth = isSameMonth(firstDay, endOfThisWeek);

        return `${formatLocale(
            firstDay,
            sameYear ? 'MMM d' : 'MMM d, yyyy'
        )} - ${formatLocale(
            endOfThisWeek,
            sameYear && sameMonth ? 'd, yyyy' : 'MMM d, yyyy'
        )}`;
    };

    const isTheLastWeek = () => {
        return differenceInDays(new Date(), firstDay) <= 6;
    };

    const openChat = () => {
        const msg = {};

        msg.type = 'initiateFoodLogChat';
        window.parent.postMessage(msg, '*');
    };

    return (
        <>
            {/* // <!-- Add Medication Modal --> */}
            <div className={css['page-header']}>
                <div className={css['page-header-days']}>
                    <div className={css['page-header-item']}>
                        <div
                            className={css['previous-days']}
                            onClick={() => changeDay('PREV')}
                        >
                            <div className={css.arrow}>
                                <LeftArrow />
                            </div>
                            <div className={css['fake-link']}>
                                {t('header.prev')}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${css['page-header-item']} center`}
                        style={{maxWidth: '421px'}}
                    >
                        <span className={css['week-text']}>
                            {t('header.week')} {getWeekText()}
                        </span>
                    </div>

                    <div className={css['page-header-item']}>
                        {!isTheLastWeek() ? (
                            <div
                                className={css['next-days']}
                                onClick={() => changeDay('NEXT')}
                            >
                                <div className={css['fake-link']}>
                                    {t('header.next')}
                                </div>

                                <div className={css.arrow}>
                                    <RightArrow />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {templateAvailable ? (
                            <button
                                className={`${css['btn-turquoise']} ${css['mar-right']}`}
                                onClick={() => openFeedbackTemplate()}
                            >
                                Template Available
                            </button>
                        ) : (
                            ''
                        )}
                        <button
                            className={css['btn-turquoise']}
                            onClick={() => openChat()}
                        >
                            {t('header.feedback')}
                        </button>
                    </div>
                </div>

                <div className={css['page-header-data']}>
                    <p>
                        {logs.length} {t('header.mealsLogged')} - {logs.average}{' '}
                        {t('header.logs')}/{t('header.days')}
                    </p>
                </div>
            </div>

            {templateModal ? (
                <div className={css['fl-modal-container']}>
                    <div className={css['modal-title']}>
                        <h3> Food log Feedback Template </h3>
                    </div>
                    <div className={css['fl-input-container']}>
                        <textarea
                            // eslint-disable-next-line react/no-unknown-property
                            contenteditable="true"
                            // eslint-disable-next-line react/no-unknown-property
                            spellcheck="true"
                            name="fl-feedback-tmpl"
                            rows="10"
                            cols="50"
                            className="input-style"
                            onChange={inputHandler}
                            type="text"
                            value={text}
                        />
                    </div>
                    <div>
                        <button
                            onClick={() => copy()}
                            className={`${css['btn-turquoise']} ${css['mar-right']}`}
                        >
                            Copy & Close
                        </button>
                        <button
                            className={css['btn-turquoise']}
                            onClick={() => submit()}
                        >
                            Close
                        </button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default FoodlogHeader;
