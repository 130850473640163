import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import result from 'lodash/result';
import filter from 'lodash/filter';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import format from 'date-fns/format';
import MedicationResource from '../resources/res_medication';
import SFResource from '../medicationAdherence/SFResource';
import {inactiveReasonOptions} from './helper';
import MedicalReconciliation from './MedicationReconciliation';
import MedicationAdherence from '../medicationAdherence/MedicationAdherence';
import Arg from '@livongo/arg';
import {toUTC} from 'common/dates';

const MedicationReconciliationController = props => {
    const dispatch = useDispatch();
    const medicationReview = useSelector(state => state.medicationReview);
    const medOptions = useSelector(state => state.medicationOptions);
    const {authToken, sessionId} = Arg.all();
    const importedMedicationToShow = 5;
    const [medOptData, setMedOptData] = useState([]);

    const getInitailIdMappings = () => {
        MedicationResource.getIdMappings().then(response => {
            const reportedAdherence = [];
            const medClasses = [];
            const medStatus = {};

            forEach(get(response, 'data.reportedAdherences'), (value, key) => {
                reportedAdherence.push({
                    reportedAdherenceId: parseInt(key, 10),
                    reportedAdherenceName: value,
                });
            });

            forEach(get(response, 'data.medClasses'), (value, key) => {
                medClasses.push({
                    medClassId: key,
                    medClassCode: value,
                    medClassName: '',
                });
            });

            forEach(get(response, 'data.medStatuses'), (value, key) => {
                medStatus[value] = key;
            });

            dispatch({
                type: 'MEDICATION_OPTIONS',
                payload: {
                    reportedAdherenceOptions: reportedAdherence,
                    medClassOptions: medClasses,
                    medSources: get(response, 'data.medSources'),
                    medRoutes: get(response, 'data.medRoutes'),
                },
            });
        });
    };

    const getImportedMedications = () => {
        return MedicationResource.getImportedMedication(authToken).then(
            data => {
                const importedMedicationList = filter(
                    get(data, 'data'),
                    item => {
                        return MedicationResource.isMedImported(item);
                    }
                );

                const limitedImportedMedicationList = formatImportedMedicationList(
                    importedMedicationList
                ).slice(0, importedMedicationToShow);

                dispatch({
                    type: 'IMPORTED_MEDICATIONS',
                    payload: limitedImportedMedicationList,
                });
            }
        );
    };

    useEffect(() => {
        getInitailIdMappings();
        getImportedMedications();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(medOptions) && !isUndefined(medOptions)) getCoachLogin();
        // On medOptions change from redux store
    }, [medOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    const formatMedicationList = list => {
        return list.map(item => {
            const medClassInfo = filter(
                medOptions.medClassOptions,
                medClassOption => {
                    return medClassOption.medClassId === item.medClassId;
                }
            );

            const inactiveReasonList = [];

            if (item.inactiveReasonIds) {
                forEach(inactiveReasonOptions, inactiveReason => {
                    if (
                        item.inactiveReasonIds.indexOf(
                            parseInt(inactiveReason.inactiveReasonId, 10)
                        ) !== -1
                    ) {
                        inactiveReasonList.push(
                            inactiveReason.inactiveReasonName
                        );
                    }
                });
            }

            item.medClass = result(
                find(medOptions.medClassOptions, opt => {
                    return opt.medClassId === item.medClassId;
                }),
                'medClassCode'
            );
            item.medRoute = medOptions.medRoutes[item.medRouteId];
            item.medClassInfo = medClassInfo.length > 0 ? medClassInfo[0] : {};
            item.startDate = item.startDate ? new Date(item.startDate) : null;
            item.inactiveReasonList = inactiveReasonList.join(', ');

            return item;
        });
    };

    function formatImportedMedicationList(list) {
        return list.map(item => {
            item.gpiPrefix = get(item, 'gpi');
            const start = item.startDate;

            item.startDate = item.startDate
                ? new Date(item.startDate, 'YYYY-MM-DDTHH:mm:ssZ')
                : new Date();

            if (isNaN(item.startDate)) {
                item.startDate = new Date(toUTC(start));
            }

            return item;
        });
    }

    const getMedication = (medReviewed, reviewId) => {
        // call API to get the list of meds
        return MedicationResource.getMedication(authToken, reviewId).then(
            response => {
                const data = get(response, 'data');
                // build active medication list
                let tempActiveMedicationList = filter(data, item => {
                    return MedicationResource.isMedActive(item);
                });

                tempActiveMedicationList = sortBy(
                    formatMedicationList(tempActiveMedicationList),
                    'medClassId'
                );
                dispatch({
                    type: 'ACTIVE_MEDICATIONS',
                    payload: tempActiveMedicationList,
                });

                // build inactive medication list
                let tempInactiveMedicationList = filter(data, item => {
                    return MedicationResource.isMedInactive(item);
                });

                tempInactiveMedicationList = reverse(
                    sortBy(
                        formatMedicationList(tempInactiveMedicationList),
                        item => new Date(item.inactiveDate).getTime()
                    )
                );
                dispatch({
                    type: 'INACTIVE_MEDICATIONS',
                    payload: tempInactiveMedicationList,
                });

                // if initial Review or med reviewed then disable fields
                setTimeout(() => {
                    if (medReviewed && medReviewed === 'medReviewed') {
                        dispatch({
                            type: 'REVIEWED_TODAY_DISABLE',
                            payload: true,
                        });
                    }
                }, 100);
            }
        );
    };

    function getCoachLogin() {
        MedicationResource.lastReview(authToken).then(response => {
            const coachLogin = get(response, 'data.coachLogin');

            if (coachLogin) {
                const lastReccDate = format(
                    new Date(get(response, 'data.reviewDateTime')),
                    'MM/dd/yy hh:mm a'
                );

                getCoachInfo(coachLogin, sessionId);
                const reviewId = get(response, 'data.reviewId');
                const medReviewed = 'initialReview';

                dispatch({
                    type: 'MEDICATION_REVIEW',
                    payload: {
                        reviewId,
                        lastReccDate,
                        medReviewed,
                        initialReviewId: clone(reviewId),
                    },
                });
                getMedication(medReviewed, reviewId);
            }
        });
    }

    function getCoachInfo(coachLogin) {
        SFResource.getCoachInfo(coachLogin, sessionId).then(response => {
            const name = find(get(response, 'data.records'), chr => {
                return !isUndefined(chr.FirstName);
            });

            dispatch({
                type: 'MEDICATION_REVIEW',
                payload: {
                    lastReccBy: `${get(name, 'FirstName') || ''} ${
                        get(name, 'LastName') || ''
                    }`,
                },
            });
        });
    }

    function getReviewId() {
        return MedicationResource.coachMedReviews(authToken).then(response => {
            let reviewId = get(response, 'data');

            reviewId = reviewId.replace(/['"]+/g, '');

            dispatch({
                type: 'MEDICATION_REVIEW',
                payload: {reviewId},
            });
            getMedication('', reviewId);
        });
    }

    function getCoachName(sessionNumber) {
        SFResource.getCoachId(sessionNumber)
            .then(response => {
                dispatch({
                    type: 'MEDICATION_REVIEW',
                    payload: {
                        lastReccDate: format(new Date(), 'MM/dd/yy hh:mm a'),
                        lastReccBy: `${get(response.data, 'FirstName') || ''} ${
                            get(response.data, 'LastName') || ''
                        }`,
                    },
                });
            })
            .catch(error => {
                dispatch({
                    type: 'ERROR',
                    payload:
                        'Coach login is not available. Please sign in again.',
                });
            });
    }

    const reviewedToday = medicationsList => {
        return MedicationResource.reviewedToday(
            authToken,
            medicationReview.reviewId,
            medicationsList
        ).then(() => {
            dispatch({
                type: 'MEDICATION_REVIEW',
                payload: {
                    medReviewed: 'initialReview', // allowing people to start a review again immediately
                    initialReviewId: medicationReview.reviewId,
                    lastReccDate: format(new Date(), 'MM/dd/yy hh:mm a'),
                },
            });

            getMedication('initialReview', medicationReview.reviewId);
            dispatch({
                type: 'TABLE_DISABLE',
                payload: false,
            });
        });
    };

    const newMedicationReview = () => {
        getReviewId()
            .then(() => {
                MedicationResource.lastReview(authToken).then(response => {
                    getCoachName(sessionId);
                });
            })
            .then(() => {
                MedicationResource.reviewedToday(
                    authToken,
                    medicationReview.reviewId,
                    ''
                ).then(() => {
                    dispatch({
                        type: 'MEDICATION_REVIEW',
                        payload: {
                            medReviewed: 'initialReview', // allowing people to start a review again immediately
                            initialReviewId:
                                medicationReview.reviewreviewedTodayId,
                            lastReccDate: format(
                                new Date(),
                                'MM/dd/yy hh:mm a'
                            ),
                        },
                    });
                });
            });
    };

    const formatMedOptData = arr => {
        const newReverseArr = [...arr].reverse();

        const formatArr = newReverseArr.map(obj => {
            if (obj.event === 'GRADUATED') {
                return {...obj, event: 'Graduated'};
            }
            if (obj.event === 'GENERAL_FOLLOWUP') {
                return {...obj, event: 'General Followup Consult'};
            }
            if (obj.event === 'NEW_PATIENT') {
                return {...obj, event: 'New Patient Consult'};
            }
            if (obj.event === 'COMPLETED_ENROLLMENT') {
                return {...obj, event: 'Member Completed Enrollment'};
            }
            if (obj.event === 'INITIALIZED_ENROLLMENT') {
                return {...obj, event: 'Member Initialized Enrollment'};
            }

            return obj;
        });

        return formatArr;
    };

    const medOptStatus = () => {
        MedicationResource.getMedOptStatus(authToken)
            .then(response => {
                setMedOptData(formatMedOptData(response.data));
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error('Med Opt data history api error', error);
                dispatch({
                    type: 'ERROR',
                    payload:
                        'Med Opt data is not available. Please sign in again.',
                });
            });
    };

    useEffect(() => {
        medOptStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{padding: '0 25px'}}>
            <MedicationAdherence />
            <MedicalReconciliation
                medOptData={medOptData}
                newMedicationReview={newMedicationReview}
                reviewedToday={reviewedToday}
                getMedication={getMedication}
            />
        </div>
    );
};

export default MedicationReconciliationController;
