/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import css from './medication-adherence.scss';
import Modal from '@livongo/ui/Modal';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const MedicationAdherenceModal = ({
    setAdherenceModal,
    updateAdherenceReview,
    createAdherenceReview,
}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const medAdherenceData = useSelector(
        state => state.medicationAdherenceData
    );
    const [CoachNote, setCoachNote] = useState('');
    const [modalNewAdherence, setNewAdherence] = useState(
        medAdherenceData.newAdherence
    );
    const [modalNonadherenceFlags, setNonAdherenceFlags] = useState([]);
    const [checkedMap, setCheckedMap] = useState({});

    useEffect(() => {
        const {newAdherence, nonadherenceFlags} = medAdherenceData;

        setCoachNote(newAdherence.coach_notes);
        const map = {};

        newAdherence.barriers_list.forEach(bar => (map[bar] = true));
        setCheckedMap(map);
        setNonAdherenceFlags(
            nonadherenceFlags.map(flag => {
                return {
                    txt: t(`adherence.modal.${flag.txt}`),
                    checked:
                        flag.checked ===
                        medAdherenceData.newAdherence.nonAdherenceFlag,
                };
            })
        );
        setNewAdherence(newAdherence);
    }, [medAdherenceData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleNonAdherenceFlagChange = flag => {
        const nonAdherenceFlagsCopy = [...modalNonadherenceFlags];

        if (flag.txt === 'Yes') {
            nonAdherenceFlagsCopy[0].checked = true;
            nonAdherenceFlagsCopy[1].checked = false;
        } else {
            nonAdherenceFlagsCopy[0].checked = false;
            nonAdherenceFlagsCopy[1].checked = true;
        }
        setNonAdherenceFlags(nonAdherenceFlagsCopy);
    };

    const toggleCheck = brr => {
        const currentlyChecked = {...checkedMap};

        if (currentlyChecked[brr]) currentlyChecked[brr] = false;
        else currentlyChecked[brr] = true;
        console.log(brr, currentlyChecked[brr]); // eslint-disable-line no-console
        setCheckedMap(currentlyChecked);
    };

    const getBarrierInput = (barrier, reviewInput) => {
        let returnStatus = false;

        if (reviewInput === 'barrier') {
            if (barrier.id !== 8 && barrier.id !== 9) returnStatus = true;
        }
        if (reviewInput === 'statin') {
            if (barrier.id === 8 || barrier.id === 9) returnStatus = true;
        }

        if (returnStatus) {
            return (
                <div
                    key={barrier.id}
                    id={`${barrier.id}-${barrier.name}`}
                    className={cn('radio', css.adherence_review_modal)}
                >
                    <label>
                        <input
                            type="checkbox"
                            value={barrier.id}
                            onChange={() => toggleCheck(barrier.id)}
                            checked={Boolean(checkedMap[barrier.id])}
                        />
                        <span className={css.spacing}>
                            {t(`adherence.barriersMapping.${barrier.id}`)}
                        </span>
                    </label>
                </div>
            );
        }
    };

    const reset = () => {
        setCoachNote('');
        setNewAdherence({});
        setNonAdherenceFlags([]);
        setCheckedMap({});
    };

    const submit = () => {
        const newAdherenceCopy = {...modalNewAdherence};
        const flaggedNonAdherence = modalNonadherenceFlags.filter(
            flag => flag.checked === true
        );
        const barriers_list = [];

        for (const key in checkedMap) {
            if (checkedMap[key]) barriers_list.push(parseInt(key, 10));
        }
        newAdherenceCopy.barriers_list = barriers_list;

        if (medAdherenceData.isLastReviewEditable) {
            updateAdherenceReview({
                ...newAdherenceCopy,
                nonAdherenceFlag: flaggedNonAdherence[0].txt === 'Yes',
                coach_notes: CoachNote,
            });
            reset();
        } else {
            createAdherenceReview({
                ...newAdherenceCopy,
                nonAdherenceFlag: flaggedNonAdherence[0].txt === 'Yes',
                coach_notes: CoachNote,
            });
            reset();
        }
        setAdherenceModal(false);
    };

    return (
        <Modal
            onHidden={() => setAdherenceModal(false)}
            title={t('adherence.modal.title')}
        >
            <div
                id="addAdherenceModal"
                className={cn(css.modal, css['medication-adherence'])}
                style={{fontSize: '12px'}}
            >
                <div
                    className={cn(
                        css['modal-dialog'],
                        css['modal-dialog-centered']
                    )}
                    role="document"
                >
                    <div className={css['modal-content']}>
                        <div className="modal-header" style={{padding: 0}} />
                        <div className="modal-body">
                            {medAdherenceData.errorMessage ? (
                                <div
                                    className={cn(
                                        'errorMessage',
                                        'text-center',
                                        css['error-message']
                                    )}
                                >
                                    {medAdherenceData.errorMessage}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="row">
                                <form
                                    name="addAdberenceReview"
                                    id="addAdberenceReview"
                                    noValidate
                                >
                                    <div
                                        className={cn('row', css.adherence_row)}
                                    >
                                        <div className="col-md-12">
                                            <div
                                                className={cn(
                                                    'col-md-4',
                                                    css.adherence_add_review
                                                )}
                                            >
                                                <label>
                                                    {t('adherence.flagged')}
                                                </label>
                                            </div>
                                            <div
                                                className={cn(
                                                    'col-md-8',
                                                    css.adherence_radio
                                                )}
                                            >
                                                {modalNonadherenceFlags.map(
                                                    nonadherenceFlag => {
                                                        return (
                                                            <div
                                                                key={
                                                                    nonadherenceFlag.txt
                                                                }
                                                                className={cn(
                                                                    'radio',
                                                                    css.adherence_review_modal
                                                                )}
                                                            >
                                                                <input
                                                                    id={`nonadherenceFlag_${nonadherenceFlag.txt.toLowerCase()}`}
                                                                    type="radio"
                                                                    name={
                                                                        nonadherenceFlag.txt
                                                                    }
                                                                    checked={
                                                                        nonadherenceFlag.checked
                                                                    }
                                                                    onChange={() =>
                                                                        handleNonAdherenceFlagChange(
                                                                            nonadherenceFlag
                                                                        )
                                                                    }
                                                                    required
                                                                />
                                                                {
                                                                    nonadherenceFlag.txt
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={cn('row', css.adherence_row)}
                                    >
                                        <div className="col-md-12">
                                            <div
                                                className={cn(
                                                    'col-md-4',
                                                    css.adherence_add_review
                                                )}
                                            >
                                                <label>
                                                    {t('adherence.barriers')}
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                {medAdherenceData.barriersMapping.map(
                                                    barrier =>
                                                        getBarrierInput(
                                                            barrier,
                                                            'barrier'
                                                        )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={cn('row', css.adherence_row)}
                                    >
                                        <div className="col-md-12">
                                            <div
                                                className={cn(
                                                    'col-md-4',
                                                    css.adherence_add_review
                                                )}
                                            >
                                                <label>
                                                    {t('adherence.coachNote')}
                                                </label>
                                            </div>
                                            <div className="col-md-8 ">
                                                <div className="form-question-input">
                                                    <textarea
                                                        className={
                                                            css[
                                                                'adherence-input-text'
                                                            ]
                                                        }
                                                        id="new-coach-note"
                                                        maxLength="2000"
                                                        cols="4"
                                                        rows="7"
                                                        style={{
                                                            height: 100,
                                                            width: 250,
                                                        }}
                                                        value={CoachNote}
                                                        onChange={e =>
                                                            setCoachNote(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {CoachNote}
                                                    </textarea>
                                                </div>
                                                <div
                                                    className={
                                                        css['text-count']
                                                    }
                                                >
                                                    <span id="textCount">
                                                        {CoachNote.length}
                                                    </span>
                                                    /2000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={cn('row', css.adherence_row)}
                                    >
                                        <div className="col-md-12">
                                            <div
                                                className={cn(
                                                    'col-md-4',
                                                    css.adherence_add_review
                                                )}
                                            >
                                                <label>
                                                    {t('adherence.statin')}
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                {medAdherenceData.barriersMapping.map(
                                                    barrier =>
                                                        getBarrierInput(
                                                            barrier,
                                                            'statin'
                                                        )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => submit()}
                                style={{background: '#209e91'}}
                            >
                                {t('adherence.save')} & {t('adherence.update')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

MedicationAdherenceModal.propTypes = {
    setAdherenceModal: PropTypes.func,
    updateAdherenceReview: PropTypes.func,
    createAdherenceReview: PropTypes.func,
};

export default MedicationAdherenceModal;
