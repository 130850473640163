import cn from 'classnames';
import Form from '@livongo/ui/Form';
import DatePicker from '@livongo/ui/DatePicker';
import TextInput from '@livongo/ui/TextInput';
import PropTypes from 'prop-types';
import Select from '@livongo/ui/Select';
import format from 'date-fns/format';
import {toUTC} from 'common/dates';
import {useSelector, useDispatch} from 'react-redux';
import css from './medication-reconciliation.scss';
import MedicationResource from '../resources/res_medication';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';

const ActiveMedication = ({
    med,
    index,
    medId,
    getClassDisplay,
    medicationOptions,
    medicationList,
    handleChange,
    btnFunc,
}) => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const dispatch = useDispatch();
    const disableFields = useSelector(
        state => state.medicationReview.disabledFields
    );

    const change = input => {
        dispatch({
            type: 'REVIEWED_TODAY_DISABLE',
            payload: false,
        });

        const listCopy = [...medicationList];
        const changedMedication = listCopy[index];

        changedMedication[input.name] = input.value;

        listCopy[index] = changedMedication;
        handleChange(listCopy);
    };

    const changeDate = date => {
        const pickedDate = new Date(date);
        const dateTarget = {
            name: 'startDate',
            value: pickedDate.toISOString(),
        };

        change(dateTarget, index);
    };

    const getReportedAdherenceOptions = (id, i) => {
        const defaultVal = id
            ? medicationOptions.reportedAdherenceOptions[id - 1]
            : null;

        return (
            <Select
                placeholder={defaultVal?.reportedAdherenceName || 'How often'}
                defaultValue={defaultVal?.reportedAdherenceId}
                ariaLabel="Often"
                className={css.noMargin}
                itemClassName={css.select_input}
                required
                items={medicationOptions.reportedAdherenceOptions.map(
                    option => {
                        return {
                            label: option.reportedAdherenceName,
                            value: option.reportedAdherenceId.toString(),
                        };
                    }
                )}
                onChange={e =>
                    change(
                        {
                            name: 'reportedAdherenceId',
                            value: parseInt(e.value, 10),
                        },
                        i
                    )
                }
            />
        );
    };

    const getStatusButton = () => {
        let buttonText = '';

        if (MedicationResource.isMedActive(med))
            buttonText = t('reconciliation.buttonText.inactive');
        else if (MedicationResource.isMedInactive(med))
            buttonText = t('reconciliation.buttonText.remove');
        else if (MedicationResource.isMedImported(med))
            buttonText = t('reconciliation.buttonText.active');

        return (
            <button
                type="select"
                className={cn('btn', css.medication_button)}
                onClick={() => btnFunc(med)}
            >
                {buttonText}
            </button>
        );
    };

    return (
        <Form
            id={`${index}-${med.drugName}-${med.dose}-${med.id}`}
            name="medicationForm"
            className="row"
            noValidate
        >
            <fieldset disabled={disableFields.tableField}>
                <div className={css.medicationForm}>
                    <span className={css.medicationClass}>
                        {getClassDisplay && getClassDisplay(medId)}
                    </span>
                    <div className={css.medName}>{med.drugName}</div>
                    <div className={css.text}>{med.gpi6Name}</div>
                    <div className={css.input}>
                        <TextInput
                            id={`${index}-dose`}
                            ariaLabel="dose"
                            className={css.noMargin}
                            itemClassName={css.select_input}
                            defaultValue={med.dose}
                            onChange={e =>
                                change(
                                    {name: 'dose', value: e.target.value},
                                    index
                                )
                            }
                            required
                        />
                    </div>
                    <div className={css.text}>
                        {med?.medRoute ||
                            medicationOptions.medRoutes[med.medRouteId]}{' '}
                    </div>
                    <div className={css.input}>
                        <TextInput
                            id={`${index}-directions`}
                            ariaLabel="directions"
                            className={css.noMargin}
                            itemClassName={css.select_input}
                            defaultValue={med.directions}
                            onChange={e =>
                                change(
                                    {
                                        name: 'directions',
                                        value: e.target.value,
                                    },
                                    index
                                )
                            }
                            required
                        />
                    </div>
                    <div
                        className={cn(css['datepicker-container'], css.select)}
                    >
                        <DatePicker
                            id={`datePickerId-${index}-${med.medRouteId}-${index}`}
                            className={css.datePicker}
                            inputItemClassName={css.datePadding}
                            label={<label />}
                            ariaLabel="The date"
                            navigationType="dropdown"
                            disablePast={false}
                            onChange={date => changeDate(date, index)}
                            required
                            selectedDate={format(
                                toUTC(med.startDate),
                                'MM/dd/yyyy'
                            )}
                        />
                    </div>
                    <div className={cn(css.select)}>
                        {getReportedAdherenceOptions(
                            med.reportedAdherenceId,
                            index
                        )}
                    </div>
                    <div>{getStatusButton(med)}</div>
                </div>
            </fieldset>
        </Form>
    );
};

ActiveMedication.propTypes = {
    med: PropTypes.object,
    index: PropTypes.number,
    medId: PropTypes.number,
    getClassDisplay: PropTypes.func,
    medicationList: PropTypes.array,
    medicationOptions: PropTypes.object,
    handleChange: PropTypes.func,
    btnFunc: PropTypes.func,
};

export default ActiveMedication;
