export const bgFilterOptions = [
    {
        label: '< 50 mg/dL',
        operation(value) {
            return value < 50;
        },
    },
    {
        label: '< 80 mg/dL',
        operation(value) {
            return value < 80;
        },
    },
    {
        label: '80 - 130 mg/dL',
        operation(value) {
            return value >= 80 && value <= 130;
        },
    },
    {
        label: '80 - 180 mg/dL',
        operation(value) {
            return value >= 80 && value <= 180;
        },
    },
    {
        label: '> 130 mg/dL',
        operation(value) {
            return value > 130;
        },
    },
    {
        label: '> 180 mg/dL',
        operation(value) {
            return value > 180;
        },
    },
    {
        label: '> 250 mg/dL',
        operation(value) {
            return value > 250;
        },
    },
    {
        label: '> 400 mg/dL',
        operation(value) {
            return value > 400;
        },
    },
];
