import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {mealCodes, feelingCodes, getValue, sourceOptions} from './BgConstants';
import css from './Logbook.scss';
import cn from 'classnames';
import {bgRangeClass} from './RangeClass';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import {intlFormat} from 'date-fns';
import Arg from '@livongo/arg';
import {useState, useEffect} from 'react';

const ListView = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const listData = useSelector(state => state.bgsReducer.listView);
    const [localeCode, setLocaleCode] = useState(Arg('memberlocale'));
    const convertCode = (type, code, arr) => {
        const text = get(arr.filter(item => code === item.value)[0], 'dataKey');

        if (!text) return '';

        return t(`${type}.${text}`);
    };

    useEffect(() => {
        if (
            localeCode === 'en-CA' ||
            localeCode === 'fr-CA' ||
            localeCode === 'es-US'
        ) {
            setLocaleCode('en-US');
        } else if (localeCode === 'en-ES') {
            setLocaleCode('es-ES');
        }
    }, [localeCode]);

    const getListViewRows = () => {
        if (listData.length === 0) {
            return (
                <tr className={css['logbook-table-row']}>
                    <td colSpan="9" className={css['no-data-row']}>
                        {t('noData')}
                    </td>
                </tr>
            );
        }

        return listData.map((bg, index) => {
            return (
                <tr
                    key={`${index}-${bg.value}`}
                    className={css['logbook-table-row']}
                >
                    <td className={css['date-table']}>
                        {intlFormat(
                            new Date(bg.timestamp),
                            {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            },
                            {
                                locale: localeCode,
                            }
                        )}
                    </td>
                    <td className={css['data-table']}>
                        <div>
                            <span
                                className={cn(
                                    css['bg-range-icon'],
                                    css[bgRangeClass(bg)]
                                )}
                            />
                            <span className={css['bg-value-number']}>
                                {getValue(bg)}
                            </span>
                        </div>
                    </td>
                    <td className={css['data-table']}>
                        {convertCode(
                            'mealCodes',
                            get(bg, 'tags.eat_code'),
                            mealCodes
                        )}
                    </td>
                    <td className={css['data-table']}>
                        {convertCode(
                            'feelingCodes',
                            get(bg, 'tags.feeling_code'),
                            feelingCodes
                        )}
                    </td>
                    <td className={css['data-table']}>
                        {get(bg, 'tags.carb') !== 0 ? get(bg, 'tags.carb') : ''}
                    </td>
                    <td className={css['data-table']}>
                        {get(bg, 'tags.insulin.long_float')
                            ? `${get(bg, 'tags.insulin.long_float')} IU`
                            : ''}
                    </td>
                    <td className={css['data-table']}>
                        {get(bg, 'tags.insulin.short_float')
                            ? `${get(bg, 'tags.insulin.short_float')} IU`
                            : ''}
                    </td>
                    <td className={css['data-table']}>
                        {get(bg, 'tags.insulin.premix_float')
                            ? `${get(bg, 'tags.insulin.premix_float')} IU`
                            : ''}
                    </td>
                    <td className={cn(css['data-table'], css['nodes-data'])}>
                        <span className={css['notes-content']}>
                            {get(bg, 'tags.source')
                                ? get(
                                      sourceOptions.filter(
                                          item =>
                                              get(bg, 'tags.source') ===
                                              item.value[0]
                                      )[0],
                                      'name'
                                  )
                                : ''}
                        </span>
                    </td>
                    <td className={cn(css['data-table'], css['nodes-data'])}>
                        <span className={css['notes-content']}>
                            {get(bg, 'tags.partner_device')
                                ? `${get(bg, 'tags.partner_device.model')} - 
                                        ${get(bg, 'tags.partner_device.brand')}`
                                : ''}
                        </span>
                    </td>
                    <td className={cn(css['data-table'], css['nodes-data'])}>
                        <span className={css['notes-content']}>
                            {get(bg, 'tags.note')}
                        </span>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div
            className={cn(
                css['horizontal-scroll'],
                'panel',
                css['meal-list-view']
            )}
        >
            <div className={css['panel-heading']}>
                <h3 className={css['panel-title']}>{t('listView.header')}</h3>
            </div>
            <div
                className={cn(css['panel-body'], css.mealListViewPrint)}
                id="nonPrintView"
            >
                <div className={css['logbook-table-body']} id="table-list-view">
                    <table
                        className={cn(
                            css.table,
                            css['table-striped'],
                            css['table-list-view']
                        )}
                    >
                        <thead className={css['logbook-table-header']}>
                            <tr>
                                <th className={css['date-table']}>
                                    {t('listView.date')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.value')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.meal')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.feel')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.carbs')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.insulinLong')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.insulinShort')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.insulinPremix')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.source')}
                                </th>
                                <th className={css['date-table']}>
                                    {t('listView.deviceName')}
                                </th>
                                <th
                                    className={cn(
                                        css['date-table'],
                                        css['nodes-data']
                                    )}
                                >
                                    {t('listView.notes')}
                                </th>
                            </tr>
                        </thead>

                        <tbody>{getListViewRows()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ListView;
